import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/follow-you-on-sand.jpg"
import Img2 from "../assets/men-hiking.jpg"
import BlackText from '../components/Typography/BlackText'

export default function Day99() {
    const render = (classes) => {
        return (
            <React.Fragment>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em', fontSize:'2em'}}>
                                <b>Imagine this... <br/> 
                                knowing God, the creator of everything, and knowing that he has plans and purposes for
                                  your life...<br/>
                                and then ignoring Him...</b>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                Are we praying to know <b>His</b> will and purpose?<br/><br/>
                                Or praying that he carry out <b>our</b> will and purpose?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                “Sought through prayer and meditation to improve our conscious contact with God...
                                  praying only for the knowledge of His will for us and the power to carry that out.”
                                  <br/><br/>
                                – Step 11 of the 12 steps of A.A
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                <b>Prayer:</b> God, I’m sorry for the times I’m always putting my plans ahead of your
                                  plans. <br/><br/>
                                 Help me surrender to you today.<br/><br/>
                                 May YOUR Kingdom come and YOUR will be done, on earth as it is in heaven.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard} >
                        <Box className={classes.verticalCenteredBox} style={{width: '100%'}}>
                            <Box mt={2} style={{width: '100%', position: 'relative'}}>
                                <img src={Img1} className={classes.responsiveImage}
                                alt="God will guide you as you take the right step"/>
                            </Box>
                          <Box style={{position: 'absolute', top: '3em'}}>
                            <BlackText variant='h2' style={{lineHeight: '1.2em'}}>I WILL FOLLOW <u>YOU</u></BlackText>
                          </Box>
                           
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '100%'}}>
                            <Box mt={2} style={{width: '100%'}}>
                                <img src={Img2} className={classes.responsiveImage}
                                alt="Men running on a mountain"/>
                            </Box>
                           
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em', fontSize:'2.2em'}}>
                                Come reset your compass to the true North.  <br/> <br/>
                                 He shall make your paths straight...
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
