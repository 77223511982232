import React from 'react'
import { CourseProvider } from '../providers/CourseProvider'
import { LectureProvider } from '../providers/LectureProvider'
import CourseUser from '../components/CourseUser'
import CourseNavigator from '../components/CourseNavigator'
import CourseCache from '../components/CourseCache/CourseCache'

export default function CoursePage () {
  return (
    <CourseProvider>
      <CourseCache>
        <LectureProvider>
          <CourseUser>
            <CourseNavigator/>
          </CourseUser>
        </LectureProvider>
      </CourseCache>
    </CourseProvider>
  )
}
