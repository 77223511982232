import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { has, isNull } from 'lodash-es'
import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'
import styles from '../styles'
import Cover from './Cover'
import Back from './Back'
import BackVault from './BackVault'
import useReels from '../hooks/useReels'
import CardStack from '../components/CardStack'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import { useParams } from 'react-router'

const useStyles = makeStyles((theme) => (styles(theme)))

export default function Reels ({ method = 'random', currentDay = new Date() }) {
  const classes = useStyles()
  const routeParams = useParams()
  const { id } = routeParams
  const { startDate, catalogue, fixDays, currentIndex, randomIndex } = useReels(id ? 'admin' : method, currentDay)

  const renderDay = useCallback(
    index => {
      if (isBefore(new Date(), startDate)) {
        console.debug('[REELS]: Not yet available')
        return <></>
      } else {
        if (has(fixDays, format(new Date(), 'dd/MM/yyyy'))) {
          console.debug(`[REELS]: Render specific day for ${format(new Date(), 'dd/MM/yyyy')}`)
          return fixDays[format(new Date(), 'dd/MM/yyyy')].render(classes)
        } else if (has(fixDays, format(new Date(), 'dd/MM'))) {
          console.debug(`[REELS]: Render day for ${format(new Date(), 'dd/MM')}`)
          return fixDays[format(new Date(), 'dd/MM')].render(classes)
        } else {
          console.debug(`[REELS]: Render day ${index + 1}`) // Indexes are 0-indexed
          return catalogue[index].render(classes)
        }
      }
    },
    [startDate, catalogue, fixDays, classes]
  )

  /**
   * Used in REELS VAULT
   *
   * @type {function(): *}
   */
  const renderCurrentDay = useCallback(() => {
      console.debug(`[REELS VAULT]: Render day ${currentIndex + 1}`) // Indexes are 0-indexed
      return catalogue[currentIndex].render(classes)
    }, [catalogue, currentIndex, classes]
  )

  const renderRandomDay = useCallback(
    () => renderDay(randomIndex),
    [renderDay, randomIndex]
  )

  if (method === 'random' && isNull(randomIndex) && !id) {
    return (
      <Backdrop open={true} transitionDuration={0} invisible={true} style={{ zIndex: 1201 }}>
        <CircularProgress color="secondary"/>
      </Backdrop>
    )
  }

  if (id && id > 0 && id < catalogue.length) {
    return (
      <CardStack id={id}>
        {catalogue[id - 1].render(classes)}
      </CardStack>
    )
  } else if (method === 'sequence') {
    return (
      <CardStack id={currentIndex}>
        {renderCurrentDay()}
        <BackVault classes={classes}/>
      </CardStack>
    )
  } else if (method === 'random') {
    return (
      <CardStack id={randomIndex}>
        <Cover classes={classes}/>
        {renderRandomDay()}
        <Back classes={classes}/>
      </CardStack>
    )
  }
  return null
}
