import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CourseContext } from '../providers/CourseProvider'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: '2em',
    height: '2em',
    fontSize: '6em',
  },
}))

export default function CourseDone () {
  const classes = useStyles()
  const { course } = useContext(CourseContext)

  if (!course) {
    return <React.Fragment>Loading course content...</React.Fragment>
  }

  return (
    <Grid container>
      <Grid xs={12} item>
        <Box className={classes.avatarContainer}>
          <Avatar className={classes.avatar}
                  src={`https://xcc.tyny.app${course.master ? course.coverImage.url : course.courseType.coverImage.url}`}/>
        </Box>
        <Box mt={9}>
          <Typography variant="h5" align="center">
            CONGRATULATIONS YOU HAVE COMPLETED AN {course.courseType.type}!
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
