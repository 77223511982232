import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MenSittingInGrassResting from '../assets/men-sitting-in-grass-resting.jpg'

export default function Day36 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  WHAT HAPPENS<br/>
                  WHEN YOU MEET<br/>
                  NEW PEOPLE?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  DO YOU<br/>
                  USUALLY<br/>
                  KNOW WHAT<br/>
                  TO SAY?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MenSittingInGrassResting} className={classes.responsiveImage}
                   alt="Men sitting in grass resting"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  IT'S MORE<br/>
                  INTERESTING<br/>
                  TO TALK ABOUT<br/>
                  THE "WHY"<br/>
                  RATHER THAN<br/>
                  "WHAT" ....
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  "WHY DO YOU<br/>
                  DO WHAT YOU<br/>
                  DO?"
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  START A CONVERSATION WITH SOMEONE NEW.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  ASK THEM ABOUT THEM
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
