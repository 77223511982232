import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import PauseIcon from '@material-ui/icons/Pause'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: '1.5em',
    height: '1.5em',
    fontSize: '4em',
  },
}))

export default function LectureNotYetPublished ({ publishAt }) {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid xs={12} item>
        <Box className={classes.avatarContainer}>
          <Avatar className={classes.avatar}>
            <PauseIcon fontSize="inherit"/>
          </Avatar>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" align="center">
            You will see!
          </Typography>
        </Box>
        <Box mt={6}>
          <Typography align="center">
            The next part of your adventure becomes available on {format(publishAt, 'EEEE HH\'h\'mm')}. Please
            check back in.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
