import React, { useEffect, useState } from 'react'
import { logEvent } from '../analytics'

export const InstallPromptContext = React.createContext()

export const InstallPromptProvider = ({ children }) => {
  const [installPromptEvent, setInstallPromptEvent] = useState(null)

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', event => {
      // Prevent Chrome <= 67 from automatically showing the prompt
      event.preventDefault()
      // Stash the event so it can be triggered later
      setInstallPromptEvent(event)
    })
    // https://developer.mozilla.org/en-US/docs/Web/API/InstallEvent
    // 2020-09-24 Don't really know if supported on iOS Safari?!
    window.addEventListener('install', event => {
      event.waitUntil(
        logEvent('select_content', {
          content_type: 'installation',
          content_id: 'service_worker'
        })
        // window.caches.open('videos')
        //   .then(cache => cache.addAll([
        //     // Add videos from landing page/news/teasers etc here
        //     //'https://firebasestorage.googleapis.com/v0/b/xtremecharacterchallenge.appspot.com/o/videos%2FPaul.mp4?alt=media&token=031579a2-11cf-487f-bef9-0e5c0183baaf'
        //   ]))
      )
    })
  }, [])

  return (
    <InstallPromptContext.Provider
      value={{
        installPromptEvent
      }}
    >
      {children}
    </InstallPromptContext.Provider>
  )
}
