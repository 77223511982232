import { get } from 'lodash-es'
import React from 'react'
import ChallengeBox from '../ChallengeBox'

export default (courseUser, course, changeChallenges) => {
  return {
    shouldProcessNode: function (node) {
      return get(node, 'name') === 'parser' && get(node, 'attribs.type') === 'challenges'
    },
    processNode: function (node, children) {
      const { title, scope } = node.attribs
      const challenges = get(courseUser, ['courses', course.id, 'challenges'], []).filter(c => {
        if (scope === 'all') {
          return true
        } else {
          return c.publish === scope
        }
      })

      if (challenges) {
        return (
          <ChallengeBox title={title} challenges={challenges} onChange={changeChallenges}/>
        )
      } else {
        return (
          <span/>
        )
      }
    }
  }
}
