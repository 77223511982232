import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/XCC-C1-Gamer-SMedia-2-IN.jpg"
import Img2 from "../assets/102.jpg"

export default function Day89() {
    const render = (classes) => {
        return (
            <React.Fragment>



                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em'}}>
                                    What have you been plugged into over lockdown?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img1} className={classes.responsiveImage}
                                 alt="Wired? - Rewire"/>
                        </Box>
                    </Card>
                </div>




                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    Has it served you well?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.4em', fontWeight: 700}}>
                                    TIME<br/>
                                    TO <br/>
                                    REWIRE
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                    A new day is dawning..
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img2} className={classes.responsiveImage}
                                 alt="Sunset"/>
                        </Box>
                    </Card>
                </div>

              <div>
                <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
              </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                    Prayer: LORD, lift my eyes to what is new and what you can make new. Help me reach out beyond myself to see what is beyond myself.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

            </React.Fragment>
        )
    }
    return {
        render
    }
}
