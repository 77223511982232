import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'

export default function Back ({ classes }) {
  return (
    <React.Fragment>
      <div>
        <Card isWithinCardStack={true}>
          <Box className={classes.textInTheMiddle}>
            <WhiteText align="center" variant="h6">
              CHECK IN TOMORROW FOR MORE
            </WhiteText>
          </Box>
        </Card>
      </div>
    </React.Fragment>
  )
}
