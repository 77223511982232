import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ScotlandFlag from '../assets/scotland-flag.jpg'
import MenStandingInLine from '../assets/men-standing-in-line.jpg'
import MenCarryingWheel from '../assets/men-carrying-wheel.jpg'
import EventDatesScotland from './event-dates/EventDatesScotland'

export default function Day59 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  I'VE GOT<br/>
                  YOUR BACK
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MenStandingInLine} className={classes.responsiveImage}
                   alt="Men standing in line"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  WHO ARE YOUR MATES?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  HOW ARE YOU LOOKING OUT FOR THEM?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MenCarryingWheel} className={classes.responsiveImage}
                   alt="Men carrying wheel"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  ARE YOU CARRYING A LOAD FOR THEM?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  BUY A MATE A PRESENT - SOMETHING GOOD!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={ScotlandFlag} className={classes.responsiveImage} alt="Scotland flag"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  TAKE SOME TIME<br/>
                  OUT TO STOP:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  <EventDatesScotland onlyUpcoming={true}/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropRed}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
