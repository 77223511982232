import { cloneDeep, isNull } from 'lodash-es'
import React, { useState, useEffect, useCallback } from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const useStyles = makeStyles((theme) => ({
  percentage: {
    float: 'right',
  },
  container: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  middle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '5em',
    maxHeight: '5em',
  },
  typo: {
    textAlign: 'center',
  },
  icon: {
    fontSize: 80,
  }
}))

export default function ChallengeBox ({ challenges, title, onChange }) {
  const classes = useStyles()
  const [index, setIndex] = useState(null)
  const [challenge, setChallenge] = useState(null)
  const [percentage, setPercentage] = useState('0.00')

  useEffect(() => {
    const openChallenges = challenges.filter(c => !c.done)
    setPercentage(_calcPercentage(challenges))
    // Point to next open challenge
    if (index === null && openChallenges.length) {
      let skip = false
      for (const [i, c] of challenges.entries()) {
        if (!c.done && !skip) {
          setIndex(i)
          setChallenge(challenges[i])
          skip = true
        }
      }
    } else if (index === null) {
      setIndex(0)
    } else {
      setChallenge(challenges[index])
    }
  }, [challenges, index])

  const _calcPercentage = (challenges) => {
    return parseFloat(String((Object.keys(challenges.filter(c => c.done)).length / challenges.length) * 100)).toFixed(2)
  }

  const previous = useCallback(() => {
    if (index === 0) {
      setIndex(challenges.length - 1)
    } else {
      setIndex(index - 1)
    }
  }, [challenges, index])

  const next = useCallback(() => {
    if (index === challenges.length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }, [challenges, index])

  const click = useCallback((e) => {
    const newChallenges = cloneDeep(challenges).map((c, i) => {
      if (i === index) {
        c.done = e.target.checked
      }
      return c
    })
    onChange(index, e.target.checked, _calcPercentage(newChallenges))
  }, [challenges, index, onChange])

  return (
    <>
      {
        challenge &&
        <div className="panel">
          <div className="panel-heading">
            <h4 className="panel-title">{title}</h4>
          </div>
          <div className="panel-body">
            <Grid container className={classes.container}>
              <Grid item className={classes.navigation} onClick={previous}>
                <ChevronLeftIcon className={classes.icon}/>
              </Grid>
              <Grid item className={classes.middle}>
                <Box>
                  <img
                    className={classes.image}
                    src={`https://xcc.tyny.app${challenge.icon.url}`}
                    alt={challenge.label_en}
                  />
                </Box>
                <Box mt={2}>
                  <Typography className={classes.typo}>{challenge.headline_en}</Typography>
                </Box>
                <Box mt={1}>
                  <Typography className={classes.typo}>
                    <b>{!isNull(challenge.amount) ? `${challenge.amount} ` : ''}{challenge.label_en}</b>
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Checkbox
                    checked={challenge.done}
                    onChange={click}
                    icon={<CheckBoxOutlineBlankIcon className={classes.icon}/>}
                    checkedIcon={<CheckBoxIcon className={classes.icon}/>}
                  />
                </Box>
              </Grid>
              <Grid item className={classes.navigation} onClick={next}>
                <ChevronRightIcon className={classes.icon}/>
              </Grid>
            </Grid>
          </div>
          <div className="panel-footer">
            <Typography className={classes.typo} variant="h6">
              <b>{percentage} %</b>
            </Typography>
          </div>
        </div>
      }
    </>
  )
}
