import ApolloClient, { InMemoryCache } from 'apollo-boost'

//const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

export const cache = new InMemoryCache()

export const client = new ApolloClient({
  uri: 'https://xcc.tyny.app/graphql',
  //cache: !isLocalhost ? cache : undefined
  cache
})
