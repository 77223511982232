import React, { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Card from '../../components/Card'
import WhiteText from '../../components/Typography/WhiteText'
import FeedbackIcon from '@material-ui/icons/Feedback'
import Button from '@material-ui/core/Button'
// import { useIntercom } from 'react-use-intercom'
import { logEvent } from '../../analytics'

export default function FeedbackCard ({ classes }) {
  // const { show } = useIntercom()

  const contact = useCallback(() => {
    // show()
    window.open('mailto:info@4muk.com?subject=App%20question', '_blank')
    // Send event to GA
    logEvent('select_content', {
      content_type: 'get_in_touch',
      content_id: 'reels_feedback'
    })
  }, [/*show*/])

  return (
    <React.Fragment>
      <div>
        <Card isWithinCardStack={true} className={classes.redCard}>
          <Box className={classes.centeredBox} mt={4}>
            <FeedbackIcon style={{ fontSize: 160 }}/>
          </Box>
          <Box className={classes.textInTheMiddle}>
            <Box>
              <WhiteText align="center" variant="h6">
                How's it going with this REELS thing?
              </WhiteText>
            </Box>
            <Box mt={4}>
              <WhiteText align="center">
                Please leave us some feedback.
              </WhiteText>
            </Box>
          </Box>
          <Box className={classes.callToActionButtonContainer}>
            <Button onClick={contact} variant="outlined" size="large" fullWidth={true}>
              Send email
            </Button>
          </Box>
        </Card>
      </div>
    </React.Fragment>
  )
}
