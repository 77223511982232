import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#e3e2e2',
      contrastText: '#333335',
    },
    secondary: {
      main: '#c7161e',
      contrastText: '#efefef',
    },
    text: {
      primary: '#e3e2e2',
      secondary: '#969696',
    },
    background: {
      default: '#e3e2e2',
      paper: '#333335',
    },
  },
})
