import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import RushToReturn from '../assets/rush-to-return.jpg'
import TeamByFire from '../assets/team-by-fire.jpg'
import BestTimeForNewBeginnings from '../assets/best-time-for-new-beginnings.jpg'
import Recharge from '../assets/XCC-C1-Sofa-SMedia-2-IN.jpg'
import EventDatesFirstOfYear from './event-dates/EventDatesFirstOfYear'
import EventDatesAll from './event-dates/EventDatesAll'

export default function December27 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={RushToReturn} className={classes.responsiveImage}
                   alt="Rush to return"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  5 days left of 2021<br/>
                  (if you count today)
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  ... take time today to consider what you have learnt from <u>your</u> 2021, and how that can guide you
                  into 2022
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={TeamByFire} className={classes.responsiveImage}
                   alt="Team sitting by fire"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  27 December 1835, Charles Darwin set sail on the Beagle; a journey that would forever change science
                  ...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  <i>what changes could you make that could leave a positive impact?</i>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={BestTimeForNewBeginnings} className={classes.responsiveImage}
                   alt="The best time for new beginnings, is now"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <EventDatesFirstOfYear classes={classes}/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>XCC WILDERNESS:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <EventDatesAll/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={Recharge} className={classes.responsiveImage}
                   alt="IT'S TIME TO RECHARGE YOURSELF"/>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
