import React from 'react'

export default function EventDatesEngland ({ onlyUpcoming = false }) {
  if (onlyUpcoming) {
    return (
      <React.Fragment>
        Join us:<br/>
        XTREME CHARACTER CHALLENGE Dartmoor<br/>
        10-13th November 2022
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      Join us:<br/>
      XTREME CHARACTER CHALLENGE<br/>
      WILDERNESS<br/>
      Dartmoor 10-13th Nov 2022<br/>
      Northumberland 23-26th Mar 2023
    </React.Fragment>
  )
}
