import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/perfectionism-book.jpg"

export default function Day95() {
    const render = (classes) => {
        return (
            <React.Fragment>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    “Why won’t they just do <br/>
                                    what I say?”<br/><br/>
                                    “Why do they keep leaving <br/>
                                    that there?” <br/> <br/>
                                    “If only they did this then <br/>
                                    they would be alright”  <br/> <br/>
                                    “Why can’t people learn to <br/>
                                    drive properly?!”
                                </WhiteText>

                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                    Do you believe you <br/>
                                    have more control <br/>
                                    than you do?
                                </WhiteText>

                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.3em'}}>
                                    “...you do not even know what will happen tomorrow. What is your life? You are a
                                  mist that appears for a little while and then vanishes. <br/>
                                    Instead, you ought to say, “If it is the Lord’s will, we will live and do this or
                                  that.”  <br/>
                                    As it is, you boast in your arrogant schemes.<br/>
                                  All such boasting is evil.” <br/> <br/>

                                    - James 4:14–16 (NIV)
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.3em', fontSize: '1.9em'}}>
                                <b>Thought:</b> Imagine trying <br/>
                                to control a car when <br/>
                                 you’re in the passenger  <br/>
                                  seat...
                                </WhiteText>

                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                    “God is looking for imperfect men and women who have learned to walk in a
                                  moment-by-moment dependence on the Holy Spirit. Christians who have come to terms
                                  with their inadequacies, fears, and failures” <br/><br/>

                                    - Charles Stanley
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <Box>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                    <b><u>Extra Mile:</u></b> Read “The Perfectionism Book” – by Will van der Hart &
                                  Rob Waller <br/> <br/>
                                </WhiteText>

                            </Box>
                            <Box mt={2}>
                                <img src={Img1} className={classes.responsiveImage}
                                alt="The Perfectionism Book"/>
                            </Box>

                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em', fontSize: '1.9em'}}>
                                We can help you. <br/> <br/>
                                Handover the reins to your life for a weekend and see what happens...
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
