import React, { useContext, useEffect } from 'react'
import { CourseContext } from '../providers/CourseProvider'
import { AuthContext } from '../providers/AuthProvider'
import { ensureCourseUserDocument } from '../base'
import { useDispatch } from 'react-redux'
import { setCourseUser } from '../state/actions/courseUser'

const CourseUser = ({ children }) => {
  const { course } = useContext(CourseContext)
  const { user } = useContext(AuthContext)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && course) {
      console.debug('ensure course user document')
      ensureCourseUserDocument(user, course)
        .then(courseUser => {
          console.debug('course user document fetched', courseUser)
          dispatch(setCourseUser(courseUser))
        })
    } else {
      dispatch(setCourseUser(null))
    }
  }, [user, course, dispatch])

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default CourseUser
