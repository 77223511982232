import clsx from 'clsx'
import React from 'react'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CallIcon from '@material-ui/icons/Call'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MenInWater from '../assets/river-group.jpg'

export default function Day1 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  After an enforced isolation period, then thrown back into everyday life, it is hard to re-connect with
                  people.
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                  Do you still need to re-connect?
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                  How are you planning to stay connected?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundHardestClimb}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <img src={MenInWater} className={clsx(classes.responsiveImage)} alt="Men in water"/>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  Remember you have 2 hands:
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  one for helping yourself,<br/>
                  and one for helping others
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box>
                <List component="nav" aria-label="main challenges">
                  <ListItem button>
                    <ListItemIcon>
                      <CallIcon/>
                    </ListItemIcon>
                    <ListItemText>
                      <WhiteText variant="h6">
                        Call a mate for a catch up - be open and upfront with him on how you are doing. Ask him too.
                      </WhiteText>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundManMud}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
