import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  typo: {
    lineHeight: '2em',
    color: '#fff',
  },
}))

export default function WhiteText (props) {
  const classes = useStyles()

  return (
    <Typography className={classes.typo} {...props}>
      {props.children}
    </Typography>
  )
}
