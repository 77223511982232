import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TentsOnFoggyMountainTop from '../assets/Tents-on-foggy-mountain-top.jpg'

export default function Day22 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  There's LOADS of FEAR & PANIC going on!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  Where is your confidence?<br/>
                  What are you HOPING for?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={TentsOnFoggyMountainTop} className={classes.responsiveImage} alt="Tents on foggy mountain top"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  "May your choices reflect your HOPES, not your fears"
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="right" variant="h6">
                  Nelson Mandela
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h5">
                  Those who hope in the LORD will renew their strength. They will soar on wings like eagles; they will
                  run and not grow weary, they will walk and not be faint.
                </WhiteText>
              </Box>
              <Box mb={4}>
                <WhiteText align="right" variant="h6">
                  Isaiah 40:31
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  Let HOPE rise up in you.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Do lots of push ups & each time you push up; do it with a
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  HOPE-FULL EXPLOSION of SPEED & POWER!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
