import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles'
import useReels from '../hooks/useReels'
import CardLayout from '../layouts/CardLayout/CardLayout'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => (styles(theme)))
const cardStyles = {
  width: '100%', minWidth: '100%', height: '90vh', maxHeight: 'calc(90vh)',
}
const useLocalStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
}))

export default function ReelsAdminPage () {
  const classes = useStyles()
  const localClasses = useLocalStyles()

  const { catalogue } = useReels('admin')

  return (
    <CardLayout fullWidth={true}>
      <Grid spacing={2} container>
        {catalogue.map((value, id) => {
          const d = id + 1
          const stack = catalogue[id].render(classes).props.children
          const firstCard = stack[0]
          return (
            <Grid key={d} item xs={12} sm={6} lg={3}>
              <Typography variant={'h4'} align={'center'}
                          color={'secondary'}>{`Day ${d} (${stack.length})`}</Typography>
              <Link to={`/reels/${d}`} key={id} className={localClasses.link}>
                {React.cloneElement(firstCard, { style: (cardStyles) })}
              </Link>
            </Grid>
          )
        })}
      </Grid>
    </CardLayout>
  )
}
