import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ScotlandFlag from '../assets/scotland-flag.jpg'
import ManCarryingSnow from '../assets/man-carrying-snow-2.jpg'
import FeedbackCard from './cards/FeedbackCard'
import EventDatesScotland from './event-dates/EventDatesScotland'

export default function Day56 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  PROMISES
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  PROMISES
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullMenWithGoodIntentionsBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h5">
                  WHAT PROMISES HAVE<br/>
                  YOU MADE IN YOUR<br/>
                  LIFE?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  ARE YOU KEEPING<br/>
                  THEM?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManCarryingSnow} className={classes.responsiveImage}
                   alt="Man carrying snow up the mountain"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <mark>
                    This is what God commands: When a man makes a vow to God or binds himself by an oath to do
                    something, he must not break his word; he must do exactly what he has said.
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="right" variant="h5">
                  <mark>Numbers 30:1</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  MAKE A LIST OF THE VOWS AND PROMISES YOU HAVE MADE.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  CHECK HOW YOU'RE KEEPING THEM.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  DON'T BREAK YOUR WORD.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={ScotlandFlag} className={classes.responsiveImage} alt="Scotland flag"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  TAKE SOME TIME<br/>
                  OUT TO STOP:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  <EventDatesScotland onlyUpcoming={true}/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropRed}/>
        </div>
        <FeedbackCard classes={classes}/>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
