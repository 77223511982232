import './initialize'
import firebase from 'firebase/app'
import 'firebase/analytics'

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
let analytics

if (!isLocalhost) {
  analytics = firebase.analytics()
  console.debug('[ANALYTICS]: Activated')
} else {
  console.debug('[ANALYTICS]: Deactivated because of localhost')
}

export function logEvent (eventName, eventParams) {
  if (analytics) {
    analytics.logEvent(eventName, eventParams)
  }
  console.log(eventName, eventParams)
}
