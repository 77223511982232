import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import HowToSurviveAprilFoolsDay from '../assets/how-to-survive-april-fools-day.jpg'

export default function AprilFoolsDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.fullAprilFirstBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>APRIL FOOLS´ DAY</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Some Historians link April Fools´ Day to the Roman Festival Hilaria (Latin for <b>joyful</b>).<br/>
                  It involved people dressing up in disguises and mocking fellow citizens.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={HowToSurviveAprilFoolsDay} className={classes.responsiveImage}
                   alt="How to survive April Fools´ Day"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Don't trust,<br/>
                  in case you are<br/>
                  mocked?
                </WhiteText>
              </Box>
              <Box mt={8} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Is this the way to<br/>
                  survive today?<br/>
                  Is this the way to<br/>
                  survive every day?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Blokes are well<br/>
                  known for their banter
                </WhiteText>
              </Box>
              <Box mt={8} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  But when does the<br/>
                  mocking stop?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="right" variant="h4" style={{ lineHeight: '1.2em' }}>
                  <b>
                    "I know it's ok<br/>
                    to ask for help,<br/>
                    to stand together;<br/>
                    real manliness<br/>
                    is not defined by<br/>
                    what we do<br/>
                    but how we live."<br/>
                  </b>
                  R.L.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h3">
                  Trust more
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="center" variant="h3">
                  Mock less
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Make a commitment<br/>
                  to laugh more<br/>
                  <u>at yourself</u><br/>
                  & trust others.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
