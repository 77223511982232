import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import EasterSundayImage from '../assets/easter-sunday.png'
import BeamInCave from '../assets/Beam-of-Light-in-a-Cave.jpg'

export default function EasterSunday () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.realBlackCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={EasterSundayImage} className={classes.responsiveImage}
                   alt="Easter Sunday"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h1">
                  <b>HE</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h1">
                  <b>IS</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h1">
                  <b>RISEN</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.realBlackCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <Box>
                <WhiteText align="center" variant="h5">
                  THE TOMB IS EMPTY
                </WhiteText>
              </Box>
              <Box mt={4}>
                <img src={BeamInCave} className={classes.responsiveImage}
                     alt="Beam of light in a cave"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  DARKNESS LIES!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.4em' }}>
                  Either this is the most<br/>
                  REMARKABLE<br/>
                  life ALTERING,<br/>
                  PERSPECTIVE<br/>
                  transforming TRUTH ever,<br/>
                  or it's a story told by<br/>
                  madmen......<br/>
                  what it CAN NOT be is just a<br/>
                  nice, tame, safe analogy of<br/>
                  "<i>everything works out okay in<br/>
                  the circle of life</i>".
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h3" style={{ lineHeight: '1.4em' }}>
                  EVERYTHING<br/>
                  HAS<br/>
                  CHANGED
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Do you see it?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
