import { get } from 'lodash-es'

export default (completeChallenges) => {
  return {
    shouldProcessNode: function (node) {
      return get(node, 'name') === 'parser' && get(node, 'attribs.type') === 'complete-challenges'
    },
    processNode: function (node, children) {
      completeChallenges()
    }
  }
}
