import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ReactMarkdown from 'react-markdown/with-html'
import Box from '@material-ui/core/Box'
import { useParams } from 'react-router'
import 'isomorphic-fetch'
import StaticContentLayout from '../layouts/StaticContentLayout'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    fontSize: '1rem',
  },
  coverImageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  coverImage: {
    width: '100%',
    height: 'auto',
  },
}))

export default function StaticContentPage () {
  const [content, setContent] = useState(null)
  const classes = useStyles()
  const routeParams = useParams()
  const { slug } = routeParams

  useEffect(() => {
    const _fetchContent = async (slug) => {
      if (slug) {
        return fetch(`https://xcc.tyny.app/${slug}`)
          .then(async (response) => {
            if (response.status === 200) {
              setContent(await response.json())
            }
          })
      }
    }
    _fetchContent(slug)
  }, [slug])

  if (!content) {
    return <React.Fragment>Loading content...</React.Fragment>
  }

  return (
    <StaticContentLayout>
      <Grid className={classes.lectureContainer} container>
        <Grid xs={12} item>
          <Box className={classes.coverImageContainer}>
            <img className={classes.coverImage} src={`https://xcc.tyny.app${content.coverImage.url}`}
                 alt="Cover"/>
          </Box>
          <Box mt={3}>
            <ReactMarkdown source={content.content} escapeHtml={false}/>
          </Box>
        </Grid>
      </Grid>
    </StaticContentLayout>
  )
}
