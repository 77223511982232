import { get, isNull } from 'lodash-es'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { logEvent } from '../../analytics'
import { updateUserDoc } from '../../base'
import {
  COURSE_USER_SET,
  COURSE_USER_CHANGE_INPUT,
  COURSE_USER_CHANGE_LAST_CONTACT,
  COURSE_USER_COMPLETE_LECTURE,
  COURSE_USER_CHANGE_CHALLENGES,
  COURSE_USER_COMPLETE_CHALLENGES,
} from '../actionTypes'

export const setCourseUser = courseUser => ({
  type: COURSE_USER_SET,
  payload: {
    courseUser
  }
})

const _changeCourseUsersInput = (name, value, course) => ({
  type: COURSE_USER_CHANGE_INPUT,
  payload: {
    name, value, course
  }
})

const _changeCourseUsersLastContact = (lastContact, course) => ({
  type: COURSE_USER_CHANGE_LAST_CONTACT,
  payload: {
    lastContact, course
  }
})

const _changeCourseUsersChallenges = (changedIndex, checked, percentage, course) => ({
  type: COURSE_USER_CHANGE_CHALLENGES,
  payload: {
    changedIndex, checked, percentage, course
  }
})

const _completeCourseUsersChallenges = course => ({
  type: COURSE_USER_COMPLETE_CHALLENGES,
  payload: {
    course
  }
})

const _completeCourseUsersLecture = (lecture, course) => ({
  type: COURSE_USER_COMPLETE_LECTURE,
  payload: {
    lecture, course
  }
})

export const changeUserInput = (e, uid, course) => {
  return dispatch => {
    const type = e.target.type
    const value = type === 'checkbox' ? e.target.checked : e.target.value
    return Promise.resolve()
      .then(() => dispatch(_changeCourseUsersInput(e.target.name, value, course)))
      .then(() => {
        if (['checkbox', 'radio'].includes(type)) {
          return dispatch(saveUserInput(e, uid, course))
        }
      })
  }
}

export const saveUserInput = (e, uid, course) => {
  return dispatch => {
    const type = e.target.type
    const value = type === 'checkbox' ? e.target.checked : e.target.value
    const lastContact = firebase.firestore.Timestamp.fromDate(new Date())

    return Promise.resolve()
      .then(() => dispatch(_changeCourseUsersLastContact(lastContact, course)))
      .then(() => {
        // Do update in background
        // ATTENTION, ATTENTION: Only work with atomic updates on single fields to prevent overwriting in case of offline!
        updateUserDoc(uid, {
          [`courses.${course.id}.inputs.${e.target.name}`]: value,
          [`courses.${course.id}.lastContact`]: lastContact,
        })
      })
  }
}

export const changeChallenges = (changedIndex, checked, percentage, uid, course) => {
  return (dispatch, getState) => {
    const lastContact = firebase.firestore.Timestamp.fromDate(new Date())

    return Promise.resolve()
      .then(() => dispatch(_changeCourseUsersChallenges(changedIndex, checked, percentage, course)))
      .then(() => dispatch(_changeCourseUsersLastContact(lastContact, course)))
      .then(() => {
        const { courseUser } = getState().courseUser
        const challenges = get(courseUser, ['courses', course.id, 'challenges'], [])
        // Do update in background
        // ATTENTION, ATTENTION: Only work with atomic updates on single fields to prevent overwriting in case of offline!
        updateUserDoc(uid, {
          [`courses.${course.id}.challenges`]: challenges,
          [`courses.${course.id}.challengesProgress`]: percentage,
          [`courses.${course.id}.lastContact`]: lastContact,
        })
        if (checked) {
          const changedChallenge = get(challenges, changedIndex)
          const sendToAppSlack = firebase.functions().httpsCallable('sendToAppSlack')
          const label = !isNull(changedChallenge.amount) ? `${changedChallenge.amount} ${changedChallenge.label_en}` : changedChallenge.label_en
          sendToAppSlack({ message: `${courseUser.firstName} ${courseUser.lastName} finished a challenge (progress ${percentage} %)\n>${label} (${changedChallenge.headline_en})` })
            .catch(err => {
              logEvent('exception', {
                description: `Could not send to slack: ${err.message}`,
              })
            })
        }
      })
  }
}

export const completeChallenges = (uid, course) => {
  return (dispatch, getState) => {
    const { courseUser } = getState().courseUser

    if (get(courseUser, ['courses', course.id, 'challengesProgress'], 0) < 100) {
      const lastContact = firebase.firestore.Timestamp.fromDate(new Date())

      return Promise.resolve()
        .then(() => dispatch(_completeCourseUsersChallenges(course)))
        .then(() => dispatch(_changeCourseUsersLastContact(lastContact, course)))
        .then(() => {
          const { courseUser } = getState().courseUser
          // Do update in background
          // ATTENTION, ATTENTION: Only work with atomic updates on single fields to prevent overwriting in case of offline!
          updateUserDoc(uid, {
            [`courses.${course.id}.challenges`]: get(courseUser, ['courses', course.id, 'challenges'], []),
            [`courses.${course.id}.challengesProgress`]: get(courseUser, ['courses', course.id, 'challengesProgress'], 0),
            [`courses.${course.id}.lastContact`]: lastContact,
          })
        })
    }
  }
}

export const completeLecture = (uid, course, lecture) => {
  return dispatch => {
    const lastContact = firebase.firestore.Timestamp.fromDate(new Date())

    return Promise.resolve()
      .then(() => dispatch(_completeCourseUsersLecture(lecture, course)))
      .then(() => dispatch(_changeCourseUsersLastContact(lastContact, course)))
      .then(() => {
        // Do update in background
        // ATTENTION, ATTENTION: Only work with atomic updates on single fields to prevent overwriting in case of offline!
        updateUserDoc(uid, {
          [`courses.${course.id}.completedLectures`]: firebase.firestore.FieldValue.arrayUnion(lecture.id),
          [`courses.${course.id}.lastContact`]: lastContact,
        })
      })
  }
}
