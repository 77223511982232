import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManCrawlingThroughMud from '../assets/man-crawling-through-mud.jpg'
import EnglandFlag from '../assets/england-flag.png'
import EventDatesEngland from './event-dates/EventDatesEngland'

export default function Day53 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  MUD
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="left" variant="h5">
                  DIRT
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="right" variant="h5">
                  CRAP
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  (i.e: THE THINGS IN LIFE THAT GET THROWN AT US AND HOLD US BACK)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  HOW DO YOU REACT WHEN YOU GET STUCK OR WHEN THE WAY AHEAD LOOKS AWFUL?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManCrawlingThroughMud} className={classes.responsiveImage}
                   alt="Man crawling through mud"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h5">
                  <mark>
                    ARE YOU ANYTHING<br/>
                    LIKE THAT GUY?!
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="right" variant="h5">
                  <mark>
                    WHY NOT?
                  </mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box mt={4}>
              <WhiteText align="left" variant="h5">
                LOOK AT HIM.<br/>
                HOW CAN HE BE SMILING WHILST IN ALL THAT?!
              </WhiteText>
            </Box>
            <Box mt={4} style={{ alignItems: 'center' }}>
              <img src={ManCrawlingThroughMud} className={classes.responsiveImage}
                   alt="Man crawling through mud"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  JOY IS A CHOICE
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  EVEN IN HARD TIMES, YOU CAN BE JOYFUL
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  CHANGE YOUR MIND ABOUT THE MUD.<br/>
                  IT'S NOT <u>ALL</u> BAD.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={EnglandFlag} className={classes.responsiveImage} alt="England flag"/>
              </Box>
              <Box mt={6}>
                <WhiteText align="center">
                  <EventDatesEngland/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
