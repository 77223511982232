import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import EnglandFlag from '../assets/england-flag.png'
import BeardedMan from '../assets/bearded-man.jpg'
import PanoramaLoch from '../assets/panorama-loch.jpg'
import EventDatesEngland from './event-dates/EventDatesEngland'

export default function Day54 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} p={8}>
              <Box>
                <WhiteText align="left" variant="h5">
                  Hello mate.
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h5">
                  Fancy a blessing? - a word of truth and hope for you today?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={PanoramaLoch} className={classes.responsiveImage}
                   alt="Panorama of loch"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  May God cause you to flourish, both you and your family.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  May you be blessed by the Lord, the Maker of heaven and earth.
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="right" variant="h5">
                  (Psalm 115)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={BeardedMan} className={classes.responsiveImage}
                   alt="Bearded man"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  TAKE IT<br/>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  &
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  FLOURISH
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={EnglandFlag} className={classes.responsiveImage} alt="England flag"/>
              </Box>
              <Box mt={6}>
                <WhiteText align="center">
                  <EventDatesEngland/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullTeamsBackdropGrey}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
