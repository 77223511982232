import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  logoBox: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: '80%',
    maxWidth: '600px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  singleCard: {
    minHeight: 'calc(100vh - 16px - 56px)',
  },
  singleCardWithoutNavigation: {
    minHeight: 'calc(100vh - 16px)',
  },
  withinCardStack: {
    minHeight: '100%',
  },
  contentBox: {
    flex: 1,
    margin: theme.spacing(1, 2, 3, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '91%',
  },
  actionsBox: {
    margin: 0,
  },
}))

export default function Card (props) {
  const classes = useStyles()
  const { className, classNameContentBox, isWithinCardStack = false, hideNavigation = false } = props

  return (
    <Paper elevation={6}
           className={clsx(classes.paper, className, isWithinCardStack && classes.withinCardStack, !isWithinCardStack && classes.singleCard, hideNavigation && classes.singleCardWithoutNavigation)}>
      <Box className={clsx(classes.contentBox, classNameContentBox)}>
        {props.children}
      </Box>
    </Paper>
  )
}
