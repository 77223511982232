import React, { useContext } from 'react'
import { Banner } from 'material-ui-banner'
import AppIcon from '../assets/apple-touch-icon.png'
import { makeStyles } from '@material-ui/core/styles'
import { InstallPromptContext } from './InstallPrompt'
import { logEvent } from '../analytics'
import { showiOSInstallPrompt } from '../state/actions/app'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiBanner-label .MuiTypography-body2': {
      fontWeight: 'bold',
    }
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
  },
  card: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
  },
}))

export default function AddToHomeScreenBanner () {
  const classes = useStyles()
  const { installPromptEvent } = useContext(InstallPromptContext)
  const dispatch = useDispatch()

  const showReactiOSPWAPrompt = () => {
    const isiOS = /iphone|ipad|ipod/.test(
      window.navigator.userAgent.toLowerCase()
    )
    const isiPadOS =
      navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1

    return isiOS || isiPadOS
  }

  const handleInstall = () => {
    if (installPromptEvent) {
      // Show the modal add to home screen dialog
      installPromptEvent.prompt()
      // Wait for the user to respond to the prompt
      installPromptEvent.userChoice.then(choice => {
        if (choice.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt')
          logEvent('select_content', {
            content_type: 'installation',
            content_id: 'android'
          })
          window.location.reload()
        } else {
          //console.log('User dismissed the A2HS prompt')
        }
        // Clear the saved prompt since it can't be used again
        //installPromptEvent = null;
      })
    } else if (showReactiOSPWAPrompt()) {
      dispatch(showiOSInstallPrompt())
    } else {
      logEvent('exception', {
        description: `Could not install the App: ${navigator.platform}; ${window.navigator.userAgent}`
      })
    }
  }

  return (
    <Banner
      icon={<img src={AppIcon} alt="App icon" width="100%" height="auto"/>}
      iconProps={{ variant: 'rounded' }}
      paperProps={{ classes: { root: classes.paper } }}
      cardProps={{ classes: { root: classes.card } }}
      label="BE BOLD. GET OUR APP."
      buttonLabel="Install"
      buttonOnClick={handleInstall}
      showDismissButton={false}
      open={true}
      appBar={true}
    />
  )
}
