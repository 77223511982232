import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManLookingOut from '../assets/man-looking-out.jpg'
import XCCMAS from '../assets/XCCMAS.jpg'
import BOGOFPic from '../assets/BOGOF-pic.jpg'

export default function ChristmasDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.fullChristmasCardBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h6">
                  "Now life has been turned upside down by a virus I am so grateful for the experience I had. So many of
                  the emotions and fears I experienced in the Welsh hills I now recognise in this current crisis - a
                  similar loss of control, total uncertainty about what's coming next and the times of questioning
                  whether I have what it takes not just to survive, but to stand up and play my part." A.G.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManLookingOut} className={classes.responsiveImage}
                   alt="Man looking out"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h5">
                  <b>
                    "I now know it's ok<br/>
                    to ask for help,<br/>
                    to stand together;<br/>
                    real manliness<br/>
                    is not defined by<br/>
                    what we do<br/>
                    but how we live."<br/>
                  </b>
                  R.L.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullChristmasSignatureCard1Backdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullChristmasSignatureCard2Backdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullChristmasSignatureCard3Backdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCChristmasTreeBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={XCCMAS} className={classes.responsiveImage}
                   alt="A stack of XCCMAS vouchers"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={BOGOFPic} className={classes.responsiveImage} alt="Bring a mate for free"/>
              </Box>
              <Box mt={6}>
                <WhiteText align="left" variant="h6" style={{ lineHeight: '1.2em' }}>
                  BRING YOUR MATE ON XCC<br/>
                  FOR FREE in DECEMBER<br/>
                  Sign up for 2 places,<br/>
                  enter the code DECXCCBOGOF<br/>
                  and you pay for just one!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
