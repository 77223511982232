import { isEmpty } from 'lodash-es'
import React, { useContext, useEffect, useState } from 'react'
import { CourseContext } from '../providers/CourseProvider'
import { compareValues } from '../Utils'
import { Route, Redirect, useParams, useHistory } from 'react-router-dom'
import LectureContent from './LectureContent/LectureContent'
import CourseLayout from '../layouts/CourseLayout/CourseLayout'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import WelcomeToCourse from './WelcomeToCourse'
import CourseDone from './CourseDone'
import useCompletedLectures from '../hooks/useCompletedLectures'
import { parseISO, parse, isAfter } from 'date-fns'
import { LectureContext } from '../providers/LectureProvider'
import { useSelector, useDispatch } from 'react-redux'
import { completeLecture } from '../state/actions/courseUser'
import PageVisibilityAPI from '../apis/PageVisibilityAPI'

export const TIME_AFTER_COURSE_FINISHED = {
  days: 2 // Tuesday noon
}

export const sanitizeCourseType = course => {
  return course.courseType.type.toLowerCase().replace(/\s/g, '-')
}

export default function CourseNavigator () {
  const completedLectures = useCompletedLectures()
  const [courseStart, setCourseStart] = useState(null)
  const [publishAt, setPublishAt] = useState(null)
  const [indexedLectures, setIndexedLectures] = useState([])
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [reRender, setReRender] = useState(false)
  const { course } = useContext(CourseContext)
  const { section, lecture } = useContext(LectureContext)
  const { courseUser } = useSelector(state => state.courseUser)
  const routeParams = useParams()
  const history = useHistory()
  const { lectureId } = routeParams
  const dispatch = useDispatch()

  useEffect(() => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    const pageVisibilityAPI = PageVisibilityAPI()
    const _listener = () => {
      if (pageVisibilityAPI) {
        const { hidden } = pageVisibilityAPI
        if (!document[hidden] && !isLocalhost) {
          // App becomes visible (foreground) again
          setReRender(!reRender)
        }
      }
    }
    if (pageVisibilityAPI) {
      const { visibilityChange } = pageVisibilityAPI
      document.addEventListener(visibilityChange, _listener, false)
    }
    return () => {
      if (pageVisibilityAPI) {
        const { visibilityChange } = pageVisibilityAPI
        document.removeEventListener(visibilityChange, _listener, false)
      }
    }
  }, [reRender])

  // useEffect(() => {
  //   // Update last contact whenever navigation happens
  //   if (user && course) {
  //     updateLastContact(user, course)
  //   }
  // }, [lectureId, user, course])

  useEffect(() => {
    if (section) {
      setPublishAt(parse(section.publishAt, 'EEEE HH:mm:ss', courseStart))
    } else {
      setPublishAt(null)
    }
  }, [section, courseStart])

  useEffect(() => {
    if (course) {
      const lectures = []
      course.courseType.courseSections.sort(compareValues('sortValue', 'asc')).map(section => {
        section.courseLectures.sort(compareValues('sortValue', 'asc')).map(lecture => {
          lectures.push(lecture.id)
          return lecture
        })
        return section
      })
      setIndexedLectures(lectures)
      setCourseStart(parseISO(course.startDateIso))
    }
  }, [course])

  useEffect(() => {
    let currentIndex = -1
    switch (lectureId) {
      case 'start':
        currentIndex = -1
        break
      case 'done':
        currentIndex = indexedLectures.length
        break
      default:
        indexedLectures.map((id, index) => {
          if (id === lectureId) {
            currentIndex = index
          }
          return id
        })
    }
    setCurrentIndex(currentIndex)
  }, [lectureId, indexedLectures, completedLectures])

  const canNavigateToNextLecture = () => {
    const lectureAvailable = course.master === true || !publishAt || isAfter(new Date(), publishAt)
    return lectureAvailable && (Boolean(indexedLectures[currentIndex + 1]) || lectureId !== 'done')
  }

  const getNextLectureUrl = () => {
    let index = currentIndex
    if (!lectureId && completedLectures !== null) {
      // Continue course where user left off
      index = isEmpty(completedLectures) ? -2 : completedLectures.length - 1
    }
    if (index === -2) {
      return `/course/${course.id}/${sanitizeCourseType(course)}/start`
    }
    if (indexedLectures[index + 1]) {
      return `/course/${course.id}/${sanitizeCourseType(course)}/lecture/${indexedLectures[index + 1]}`
    } else {
      return `/course/${course.id}/${sanitizeCourseType(course)}/done`
    }
  }

  const canNavigateToPreviousLecture = () => {
    return Boolean(indexedLectures[currentIndex - 1]) || lectureId !== 'start'
  }

  const getPreviousLectureUrl = () => {
    if (indexedLectures[currentIndex - 1]) {
      return `/course/${course.id}/${sanitizeCourseType(course)}/lecture/${indexedLectures[currentIndex - 1]}`
    } else {
      return `/course/${course.id}/${sanitizeCourseType(course)}/start`
    }
  }

  const doNavigateToNextLecture = () => {
    Promise.resolve()
      .then(() => {
        if (currentIndex > -1) {
          return dispatch(completeLecture(courseUser.uid, course, lecture))
        }
      })
      .then(() => history.push(getNextLectureUrl()))
  }

  const doNavigateToPreviousLecture = () => {
    history.push(getPreviousLectureUrl())
  }

  if (isEmpty(indexedLectures) || completedLectures === null) {
    // Pending
    return (
      <Backdrop open={true} transitionDuration={0}>
        <CircularProgress color="secondary"/>
      </Backdrop>
    )
  }

  const courseLayoutProps = {
    doNavigateToNextLecture,
    doNavigateToPreviousLecture,
    canNavigateToNextLecture,
    canNavigateToPreviousLecture
  }

  if (!lectureId) {
    // Continue course where user left off
    return (
      <Route><Redirect to={getNextLectureUrl()}/></Route>
    )
  }

  return (
    <CourseLayout {...courseLayoutProps}>
      {
        lectureId === 'start' &&
        <WelcomeToCourse/>
      }
      {
        lectureId === 'done' &&
        <CourseDone/>
      }
      {
        !['start', 'done'].includes(lectureId) &&
        <LectureContent/>
      }
    </CourseLayout>
  )
}
