import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TeamByFire from '../assets/team-by-fire.jpg'
import QuoteAdmitYouAreWrong from '../assets/QUOTE-Admit-you-are-wrong.jpg'

export default function Day24 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={QuoteAdmitYouAreWrong} className={classes.responsiveImage}
                   alt="Being able to admit when you're wrong without getting defensive is a skill"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  What have you been WRONG about recently?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  Have you said SORRY?
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="center" variant="h4">
                  Do it.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullRemoteBackdropGrey}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={TeamByFire} className={classes.responsiveImage} alt="Team by fire"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
