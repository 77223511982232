import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import RedSkyOverTents from '../assets/red-sky-over-tents.jpg'
import LetYourDreamsGrow from '../assets/Let-your-dreams.jpg'
import Warning from '../assets/warning.jpg'

export default function Day17 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  what are you
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  DREAMING
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  about?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  I CAN do ALL THINGS through HIM who strengthens me
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h6">
                  Philippians 4 : 13
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={RedSkyOverTents} className={classes.responsiveImage} alt="Red sky over tents"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  What excites & scares you at the same time?...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  ...Just Do It!
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  [Assuming it's legal, ethical, moral etc!]
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={LetYourDreamsGrow} className={classes.responsiveImage}
                   alt="Let your dreams grow so big that you feel uncomfortable looking at them"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={Warning} className={classes.responsiveImage}
                   alt="WARNING: It is bold. It is challenging. It is difficult. BUT: you will come back changed. A different man. Closer to the real you."/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
