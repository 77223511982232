import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import HappyNewYear from '../assets/happy-new-year.jpg'
import NewYearGreeting from '../assets/new-year-greeting.jpg'
import NoPlaceToHide from '../assets/no-place-to-hide.jpg'
import Mask from '../assets/Mask1-fb.jpg'
import EventDatesFirstOfYear from './event-dates/EventDatesFirstOfYear'

export default function NewYearsDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={HappyNewYear} className={classes.responsiveImage}
                   alt="HAPPY NEW YEAR!"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h5">
                  new YEAR<br/>
                  new MOMENTS<br/>
                  new (XCC) ADVENTURES<br/>
                  new MEMORIES<br/>
                  new CHALLENGES<br/>
                  new LESSONS<br/>
                  new OPPORTUNITIES
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={NoPlaceToHide} className={classes.responsiveImage}
                   alt="...there's no place to hide"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Go for a run/walk
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  & either go FURTHER than you usually go,
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  or FASTER.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={NewYearGreeting} className={classes.responsiveImage}
                   alt="This year, let's say YES to new adventures"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <EventDatesFirstOfYear classes={classes}/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>XCC WARNING:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  It is bold, it is challenging, it is difficult. But you will come back changed. A different man.
                  Closer to the real you.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={Mask} className={classes.responsiveImage}
                   alt="Look behind the mask"/>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
