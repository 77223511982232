import React, { useState, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import Backdrop from "@material-ui/core/Backdrop";
import CardLayout from "../layouts/CardLayout/CardLayout";
import { isMobile } from "react-device-detect";
import Reels from "../reels/Reels";
import { addDays, subDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  //Check the cardlayout.js and cardstack.js for more styles for this page 
  
  backdrop: {
    backgroundColor: "#333335",
  },
}));

export default function ReelsVaultPage() {
  const classes = useStyles();
  const [videoIsPlaying /*, setVideoIsPlaying*/] = useState(false);
  const [deviceOrientation, setDeviceOrientation] = useState("");
  const [currentDay, setCurrentDay] = useState(new Date());

  const onOrientationChange = (orientation, type, angle) => {
    setDeviceOrientation(orientation);
  };

  // const onVideoPlaying = () => {
  //   setVideoIsPlaying(true)
  // }
  //
  // const onVideoPause = () => {
  //   setVideoIsPlaying(false)
  // }

  const onForwardNavigation = useCallback(() => {
    setCurrentDay(addDays(currentDay, 1));
  }, [currentDay]);

  const onBackwardNavigation = useCallback(() => {
    setCurrentDay(subDays(currentDay, 1));
  }, [currentDay]);

  // useEffect(() => {
  //   const { currentIndex, nextIndex, previousIndex } = useReels('sequence', currentDay)
  //   setReels({ currentIndex, nextIndex, previousIndex })
  // }, [currentDay])

  if (isMobile && deviceOrientation === "landscape" && !videoIsPlaying) {
    return (
      <DeviceOrientation lockOrientation={"portrait"} onOrientationChange={onOrientationChange}>
        <Orientation orientation="landscape" alwaysRender={false}>
          <Backdrop open={true} transitionDuration={0} className={classes.backdrop}>
            <Typography variant="h5">Please rotate your screen to portrait mode</Typography>
          </Backdrop>
        </Orientation>
      </DeviceOrientation>
    );
  }

  return (
    <div>
      <CardLayout
        backwardForwardNavigation={true}
        onForwardNavigation={onForwardNavigation}
        onBackwardNavigation={onBackwardNavigation}
      >
        <DeviceOrientation lockOrientation={"portrait"} onOrientationChange={onOrientationChange}>
          <Orientation orientation="portrait">
            <Reels method="sequence" currentDay={currentDay} />
          </Orientation>
        </DeviceOrientation>
      </CardLayout>
    </div>
  );
}
