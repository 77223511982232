import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import BackgroundImage from '../assets/logo-background.jpg'

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.only('md')]: {
      flex: 0.9,
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      flex: 0.7,
      marginLeft: 'auto',
    },

    '@global': {
      '.MuiTypography-body1': {
        fontFamily: '"Noto Sans", sans-serif',
      },
    },
  },
  menuItem: {
    flexGrow: 1,
    fontWeight: 700,
    fontSize: '1.2rem',
    color: '#fff8',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      color: '#ffff'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.2rem',
    },
  },
  active: {
    '&:active': {
      color: '#ffff'
    },
  },
  paper: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '40% 0',
      zIndex: -5,
    },
  },
  mainNavActive: {
    color: '#fff'
  },
}))

const DesktopMenu = () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={[classes.menu, classes.paper].join(' ')}>
        <Typography component={'a'} href="https://xtremecharacterchallenge.com"
                    className={classes.menuItem}>Home</Typography>
        <Typography component={NavLink} exact to="/" className={classes.menuItem}
                    activeClassName={classes.mainNavActive}>News</Typography>
        <Typography component={NavLink} to="/reels" className={classes.menuItem}
                    activeClassName={classes.mainNavActive}>Reels</Typography>
        <Typography component={NavLink} to="/reels-vault" className={classes.menuItem}
                    activeClassName={classes.mainNavActive}>Reels Vault</Typography>
        <Typography component={NavLink} to="/podcasts" className={classes.menuItem}
                    activeClassName={classes.mainNavActive}>Podcasts</Typography>
        <Typography component={NavLink} to="/my-adventures" className={classes.menuItem}
                    activeClassName={classes.mainNavActive}>My Adventures</Typography>
      </div>
    </React.Fragment>
  )
}

export default DesktopMenu
