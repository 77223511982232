import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ImgSofa from "../assets/XCC-C1-Sofa-SMedia-1-IN.jpg"
import MountainTop from "../assets/jerry-zhang-ePpaQC2c1xA-unsplash.jpg"

export default function Day84() {
    const render = (classes) => {
        return (
            <React.Fragment>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={ImgSofa} className={classes.responsiveImage}
                                 alt="Clear Your Cache"/>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                    Wish you could clear <br/>
                                    <strong>out the rubbish you’ve</strong><br/>
                                    built up in your life over lockdown?<br/><br/>
                                    That sense of boredom...<br/><br/>
                                    That Groundhog Day feeling..
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h2" style={{lineHeight: '1.2em', fontWeight: 700}}>
                                    YOU CAN!
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em'}}>
                                    Come away with us to<br/>
                                    REBOOT.<br/>
                                    RECHARGE.<br/>
                                    REFRESH.<br/>
                                    RESTART.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

              <div>
                <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
              </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h2" style={{lineHeight: '1.2em'}}>
                                    It’s time to<br/>
                                    <span style={{color:"red",textDecoration:"underline"}}>take back control.</span>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={MountainTop} className={classes.responsiveImage}
                                 alt="Mountain"/>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    <strong>Today’s Challenge:</strong><br/><br/>
                                    Book yourself on an XCC. Think of those mates that previously said it was too<strong> expensive for them… it’s</strong> now FREE to those that need it.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
