import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MorningSunOverMountain from '../assets/morning-sun-over-mountain.jpg'
import ManGoesUpToMountain from '../assets/man-goes-up-the-mountain.jpg'
import QuoteMenAndMountains from '../assets/QUOTE-Men-and-mountains.jpg'
import EnglandFlag from '../assets/england-flag.png'
import FeedbackCard from './cards/FeedbackCard'
import EventDatesEngland from './event-dates/EventDatesEngland'

export default function Day49 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  CLIMBING<br/>
                  MOUNTAINS<br/>
                  IS GOOD<br/>
                  FOR US
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  WE GET ABOVE IT ALL
                </WhiteText>
              </Box>
              <Box mt={3}>
                <WhiteText align="left" variant="h5">
                  PERSPECTIVE CHANGES
                </WhiteText>
              </Box>
              <Box mt={3}>
                <WhiteText align="left" variant="h5">
                  WE REALISE OUR LIMITATIONS
                </WhiteText>
              </Box>
              <Box mt={3}>
                <WhiteText align="left" variant="h5">
                  WE LEARN
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManGoesUpToMountain} className={classes.responsiveImage}
                   alt="Man goes up the mountain"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MorningSunOverMountain} className={classes.responsiveImage}
                   alt="Morning sun over mountain"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  Let us go up to the mountain of the Lord. He will teach us His ways, so that we may walk in His paths.
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="left" variant="h5">
                  (Isaiah 2:3)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  BOOK A TRIP TO THE MOUNTAINS
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={QuoteMenAndMountains} className={classes.responsiveImage}
                   alt="Great things are done when men and mountains meet"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={EnglandFlag} className={classes.responsiveImage} alt="England flag"/>
              </Box>
              <Box mt={6}>
                <WhiteText align="center">
                  <EventDatesEngland/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <FeedbackCard classes={classes}/>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
