import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MenOnHill from '../assets/men-on-hill.jpg'
import ChangeSettings from '../assets/XCC-C1-Sofa-SMedia-3-IN.jpg'
import ICanAskForHelp from '../assets/I-can-ask-for-help.jpg'
import Link from '@material-ui/core/Link'
import Arise from '../assets/Arise-black-on-white.jpg'
import EventDatesAll from './event-dates/EventDatesAll'

export default function NewYearsEve () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  2022 is not the year you get everything you want ...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h4">
                  it is the year to appreciate everything you have
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ChangeSettings} className={classes.responsiveImage}
                   alt="CHANGE YOUR SETTINGS"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Do as many push ups as you can & and each time you push up do it with a
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  HOPE-FULL<br/>
                  EXPLOSION<br/>
                  of SPEED & POWER!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ICanAskForHelp} className={classes.responsiveImage}
                   alt="I can ask for help..."/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MenOnHill} className={classes.responsiveImage}
                   alt="Men on a hill"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>XCC WILDERNESS:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <EventDatesAll/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center">
                  Do you have a wife, female partner of friend that wants to WAKE UP, RESET & FEEL ALIVE?
                </WhiteText>
              </Box>
              <Box mt={4} className={classes.verticalCenteredBox}>
                <img src={Arise} className={classes.responsiveImage} alt="ARISE UK"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  ARISE UK is an exclusive wilderness event for women that will stretch and build - see <Link
                  href="https://www.4muk.com/arise"
                  target="_blank"
                  rel="noopener" style={{ textDecoration: 'underline' }}>www.4muk.com/arise</Link> to sign up for the
                  20-22nd May 2022 event
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
