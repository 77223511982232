import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardLayout from "../layouts/CardLayout/CardLayout";

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: "100%",
    height: "calc(90vh)",
  },
}));

export default function PodcastsPage() {
  const classes = useStyles();

  return (
    <div>
      <CardLayout>
        <iframe
          className={classes.iframe}
          title="Big Conversations in a Little Car"
          id="multi_iframe"
          src="https://www.podbean.com/media/player/multi?playlist=http%3A%2F%2Fplaylist.podbean.com%2F8020874%2Fplaylist_multi.xml&amp;vjs=1&amp;kdsowie31j4k1jlf913=99aa673a4c70ee8b0b71f9f9ada366d5591aa2b0&amp;size=430&amp;skin=9&amp;episode_list_bg=%23ffffff&amp;bg_left=%23000000&amp;bg_mid=%23535353&amp;bg_right=%23353535&amp;podcast_title_color=%23b5092b&amp;episode_title_color=%23ffffff&amp;auto=0&amp;share=1&amp;fonts=Helvetica&amp;download=0&amp;rtl=0&amp;show_playlist_recent_number=10&amp;pbad=1"
          frameBorder="0"
          scrolling="no"
          allowFullScreen=""
        />
      </CardLayout>
    </div>
  );
}
