import { get } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { CourseContext } from '../providers/CourseProvider'
import { useSelector } from 'react-redux'

export default function useCompletedLectures () {
  const [completedLectures, setCompletedLectures] = useState(null)
  const { courseUser } = useSelector(state => state.courseUser)
  const { course } = useContext(CourseContext)

  useEffect(() => {
    if (courseUser && course) {
      setCompletedLectures(get(courseUser, ['courses', course.id, 'completedLectures'], []))
    }
  }, [courseUser, course])

  return completedLectures
}
