import React from 'react'
import Card from '../components/Card'
import SignUp from '../components/SignUp'
import CardLayout from '../layouts/CardLayout/CardLayout'

export default function SignUpPage () {
  return (
    <CardLayout>
      <Card>
        <SignUp/>
      </Card>
    </CardLayout>
  )
}
