import React from 'react'

export default function EventDatesAll () {
  return (
    <React.Fragment>
      Wales 13-16th Oct 2022<br/>
      Dartmoor 10-13th Nov 2022<br/>
      Northumberland 23-26th Mar 2023<br/>
      Scotland 20-23th Apr 2023
    </React.Fragment>
  )
}
