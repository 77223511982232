import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
//import Avatar from '@material-ui/core/Avatar'
//import WalkIcon from '@material-ui/icons/DirectionsWalk'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { forgotPassword } from '../state/actions/app'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import RegularText from './Typography/RegularText'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '1.5em',
    height: '1.5em',
    //backgroundColor: theme.palette.secondary.main,
    fontSize: '4em',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    margin: theme.spacing(0, 0, 2),
  },
}))

export default function ForgotPassword () {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return (
    <React.Fragment>
      {/*<Box>*/}
      {/*  <Avatar className={classes.avatar}>*/}
      {/*    <WalkIcon fontSize="inherit"/>*/}
      {/*  </Avatar>*/}
      {/*</Box>*/}
      <Box mt={3}>
        <Typography variant="h5" align="center">
          Forgot your password?<br/>
          Don't worry!
        </Typography>
      </Box>
      <Box mt={2}>
        <RegularText align="center">
          Please enter the email address you signed up with and we'll send you an email to reset your password.
        </RegularText>
      </Box>
      <Box mt={2}>
        <form className={classes.form}
              onSubmit={e => dispatch(forgotPassword(e, history, enqueueSnackbar, closeSnackbar))}
              noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Send password reset email
          </Button>
          <Button
            fullWidth
            variant="outlined"
            component={NavLink}
            to="/sign-up"
            className={classes.button}
          >
            Create user account
          </Button>
          <Button
            fullWidth
            variant="outlined"
            component={NavLink}
            to="/sign-in"
            className={classes.button}
          >
            Start the Adventure!
          </Button>
        </form>
      </Box>
    </React.Fragment>
  )
}
