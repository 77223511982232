import { isEmpty, has, get } from 'lodash-es'
import React, { useCallback, useState, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
//import Avatar from '@material-ui/core/Avatar'
//import WalkIcon from '@material-ui/icons/DirectionsWalk'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { signUp } from '../state/actions/app'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import RegularText from './Typography/RegularText'
//import RegularText from './Typography/RegularText'
//import Link from '@material-ui/core/Link'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '1.5em',
    height: '1.5em',
    //backgroundColor: theme.palette.secondary.main,
    fontSize: '4em',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignUp () {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState({})
  const formRef = useRef(null)

  const validate = (firstName, lastName, email, password, passwordConfirm) => {
    const errors = {}
    if (!firstName) {
      errors['firstName'] = 'Please enter your first name'
    }
    if (!lastName) {
      errors['lastName'] = 'Please enter your last name'
    }
    if (!email) {
      errors['email'] = 'Please enter your email address'
    }
    if (!password) {
      errors['password'] = 'Please set your App password'
    }
    if (!passwordConfirm) {
      errors['passwordConfirm'] = 'Please re-enter your App password'
    }
    if (password && passwordConfirm && password !== passwordConfirm) {
      errors['passwordConfirm'] = 'The passwords you entered do not match'
    }
    return errors
  }

  const validateCallback = useCallback(() => {
    const { firstName, lastName, email, password, passwordConfirm } = formRef.current.elements
    const errors = validate(firstName.value.trim(), lastName.value.trim(), email.value.trim(), password.value.trim(), passwordConfirm.value.trim())
    setErrors(errors)
  }, [])

  const signUpCallback = useCallback(event => {
    event.preventDefault()
    const { firstName, lastName, email, password, passwordConfirm } = event.target.elements
    const firstNameValue = firstName.value.trim()
    const lastNameValue = lastName.value.trim()
    const emailValue = email.value.trim()
    const passwordValue = password.value.trim()
    const passwordConfirmValue = passwordConfirm.value.trim()
    const errors = validate(firstNameValue, lastNameValue, emailValue, passwordValue, passwordConfirmValue)
    if (isEmpty(errors)) {
      dispatch(signUp({
        firstName: firstNameValue,
        lastName: lastNameValue,
        email: emailValue,
        password: passwordValue
      }, history, enqueueSnackbar))
    } else {
      setErrors(errors)
    }
  }, [dispatch, history, enqueueSnackbar])

  return (
    <React.Fragment>
      {/*<Box>*/}
      {/*  <Avatar className={classes.avatar}>*/}
      {/*    <WalkIcon fontSize="inherit"/>*/}
      {/*  </Avatar>*/}
      {/*</Box>*/}
      <Box mt={3}>
        <Typography variant="h5" align="center">
          Sign up
        </Typography>
      </Box>
      <Box mt={2}>
        <RegularText align="center">
          Get access to more sections of the App and your personal XCC experience.
        </RegularText>
      </Box>
      <Box mt={2}>
        <form ref={formRef} className={classes.form} onSubmit={signUpCallback} onChange={validateCallback} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First name"
            name="firstName"
            autoComplete="given-name"
            error={has(errors, 'firstName')}
            helperText={get(errors, 'firstName')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last name"
            name="lastName"
            autoComplete="family-name"
            error={has(errors, 'lastName')}
            helperText={get(errors, 'lastName')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
            error={has(errors, 'email')}
            helperText={get(errors, 'email')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={has(errors, 'password')}
            helperText={get(errors, 'password')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label="Confirm password"
            type="password"
            id="passwordConfirm"
            autoComplete="current-password"
            error={has(errors, 'passwordConfirm')}
            helperText={get(errors, 'passwordConfirm')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            disabled={!isEmpty(errors)}
          >
            Create my account
          </Button>
          <Button
            variant="outlined"
            component={NavLink}
            to="/sign-in"
            fullWidth
          >
            Start the Adventure!
          </Button>
        </form>
      </Box>
    </React.Fragment>
  )
}
