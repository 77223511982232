import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import OpenMind from '../assets/open-mind.jpg'
import MorningSunOverMountain from '../assets/morning-sun-over-mountain.jpg'

export default function Day60 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  "HAVE AN<br/>
                  OPEN MIND"
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="right" variant="h5">
                  THEY SAY
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={OpenMind} className={classes.responsiveImage}
                   alt="Open mind"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MorningSunOverMountain} className={classes.responsiveImage}
                   alt="Morning sun over mountain"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  WHAT ARE THE SOLID THINGS THAT YOU ARE WRAPPING YOUR MIND AROUND?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <mark>
                    Getting wisdom is the wisest thing you can do! And whatever else you do, develop good judgement.
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="left" variant="h5">
                  <mark>
                    Proverbs 4:7
                  </mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  Shut your mind onto something solid.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
