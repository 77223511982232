import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'

export default function FridayBeforeFathersDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="center" variant="h4" style={{ lineHeight: '1.2em' }}>
                  <b>FATHER'S DAY...</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="center" variant="h4" style={{ lineHeight: '1.2em' }}>
                  DOES IT MAKE YOU FEEL LIKE THIS...
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullFatherAndSonBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="center" variant="h4" style={{ lineHeight: '1.2em' }}>
                  OR LIKE THIS...
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullKidCryingBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b><u>Today's Challenge:</u></b>
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Whether Father's Day makes you feel happy or sad, take 2 minutes and ponder the unflinching and
                  unchanging love your Heavenly Father has for you.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>PRAYER:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Father God, thank you that you love me.<br/>
                  Thank you that you know ALL of me and still love me.<br/>
                  Thank you that you died for me, that you never leave me nor forsake me.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Help me <b>know</b> this to be true. Make it real for me.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '100%' }}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  YOU ARE<br/>
                  LOVED.
                </WhiteText>
              </Box>
              <Box mt={16}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  <b><u>Meditate</u></b> on it.<br/>
                  <b><u>Know</u></b> it.<br/>
                  <b><u>Live</u></b> from it.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
