import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/XCC-C1-Gamer-SMedia-1-TW.jpg"
import Img2 from "../assets/men-hike-line.jpg"
import Img3 from "../assets/men-snow-climb.jpg"

export default function Day88() {
    const render = (classes) => {
        return (
            <React.Fragment>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img1} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    Get the guys together…
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img2} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img3} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em', fontWeight: 700}}>
                                    It’s time to get outdoors again.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                    <strong>Don’t take</strong><br/> months to<br/> adapt to the<br/> new normal.<br/><br/>
                                    <strong style={{textDecoration: "underline"}}>Create</strong> the<br/> new normal.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


              <div>
                <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
              </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em'}}>
                                    <strong>Today’s Challenge:</strong>
                                    <br/><br/>
                                    Go somewhere different for your daily exercise today. Take the path less travelled.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


            </React.Fragment>
        )
    }
    return {
        render
    }
}
