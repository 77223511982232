import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import QuoteDisconnect from '../assets/QUOTE-disconnect.jpg'
//import Button from '@material-ui/core/Button'

export default function Day23 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={QuoteDisconnect} className={classes.responsiveImage}
                   alt="XCC is a proper disconnection from the world and a chance to reconnect with life. P.T"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundTakeCareOfYou}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  When was the last time you just switched off?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  Jesus said:
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  "Come away by yourself; let's take a break and get a little rest."
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  For there was constant coming and going. They didn't even have time to eat.
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="right" variant="h6">
                  Mark 6:31
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  SWITCH OFF for a few moments
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  Get FAR AWAY from it all for a while.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
        {/*<div>*/}
        {/*  <Card isWithinCardStack={true} className={classes.fullKeysToFREEDOMBackdrop}>*/}
        {/*    <Box className={classes.callToActionButtonContainer}>*/}
        {/*      <Button variant="outlined" href="https://www.mercyuk.org/keystofreedomremote"*/}
        {/*              target="_blank" rel="noopener" size="large" fullWidth={true}>*/}
        {/*        Check it out!*/}
        {/*      </Button>*/}
        {/*    </Box>*/}
        {/*  </Card>*/}
        {/*</div>*/}
      </React.Fragment>
    )
  }
  return {
    render
  }
}
