import React, { useEffect, useState } from 'react'
import { logEvent } from '../analytics'
import { GET_COURSE } from '../graphql/queries'
import { useApolloClient } from '@apollo/react-hooks'
import { useParams } from 'react-router'

export const CourseContext = React.createContext()

export const CourseProvider = ({ children }) => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  const [course, setCourse] = useState(null)
  const routeParams = useParams()
  const { courseId } = routeParams
  const apolloClient = useApolloClient()

  useEffect(() => {
    if (courseId) {
      console.debug('try to fetch single course data')
      apolloClient
        .query({ query: GET_COURSE(courseId), fetchPolicy: isLocalhost ? 'network-only' : 'cache-first' })
        .then(result => {
          console.debug('single course data fetched')
          setCourse(result.data.course)
        })
        .catch(err => {
          logEvent('exception', { description: `Could not fetch course: ${err.message}`, fatal: true })
        })
    } else {
      setCourse(null)
    }
  }, [courseId, apolloClient, isLocalhost])

  return (
    <CourseContext.Provider
      value={{
        course
      }}
    >
      {children}
    </CourseContext.Provider>
  )
}
