import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManInDirtyShirt from '../assets/man-in-dirty-shirt.jpg'
import SunriseOverLoch from '../assets/Sunrise-over-loch.jpg'

export default function Day51 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  ARE YOU<br/>
                  IN GOOD<br/>
                  SHAPE?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              {/*<Box>*/}
              {/*  <WhiteText align="center" variant="h5">*/}
              {/*    CHRISTMAS IS<br/>*/}
              {/*    COMING*/}
              {/*  </WhiteText>*/}
              {/*</Box>*/}
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  GET READY:<br/>
                  GET FIT NOW - DON'T<br/>
                  WAIT UNTIL AFTER.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManInDirtyShirt} className={classes.responsiveImage}
                   alt="Man in dirty shirt"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  SERIOUSLY.<br/>
                  THINK ABOUT IT.<br/>
                  MAKE A PLAN.<br/>
                  STICK TO IT.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  LOOK AFTER<br/>
                  YOUR BODY - IT<br/>
                  CARRIES THE<br/>
                  REST OF YOU!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <mark>Do you not know that your body is a temple where the Holy Spirit lives?</mark>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <mark>1 Corinthians 6:19</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={SunriseOverLoch} className={classes.responsiveImage}
                   alt="Sunrise over loch"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
