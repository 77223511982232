import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../components/Card";
import WhiteText from "../components/Typography/WhiteText";
import ReelsSquare from "../assets/REELS-square.jpg";
import isBefore from "date-fns/isBefore";
import format from "date-fns/format";
import useReels from "../hooks/useReels";

const useStyles = makeStyles((theme) => ({
  centeredBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  responsiveImage: {
    maxWidth: "100%",
  },
}));

export default function Cover() {
  const classes = useStyles();
  const { startDate } = useReels();

  return (
    <React.Fragment>
      <div>
        <Card isWithinCardStack={true}>
          <Box className={classes.centeredBox}>
            <img src={ReelsSquare} className={classes.responsiveImage} alt="REELS logo" />
          </Box>
          <Box className={classes.textInTheMiddle}>
            <WhiteText align="center" variant="h6">
              Your Daily Dose of Inspiration!
            </WhiteText>
          </Box>
          {isBefore(new Date(), startDate) && (
            <Box className={classes.callToActionButtonContainer}>
              <WhiteText>Check back in on {format(startDate, "dd/MM/yyyy")}</WhiteText>
            </Box>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
}
