import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MountainTop from "../assets/mountain-top.jpg"

export default function Day83() {
    const render = (classes) => {
        return (
            <React.Fragment>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h2" style={{lineHeight: '1.2em'}}>
                                    <strong>POWER<br/>
                                        &<br/>
                                        PRIDE</strong>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                    “<strong>Power infested</strong> with <span style={{color: "red"}}>pride</span> will
                                    surely give rise to <strong>egomania</strong>”
                                    <br/><br/><small>- Richard Foster</small>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em', fontWeight: 700}}>
                                    <strong>“The biggest mountain I’ve had to fight in my life is my own ego – it
                                        betrays me”</strong><br/><br/>
                                    - B.M.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h3" style={{lineHeight: '1.2em'}}>
                                    How big a<br/> mountain is<br/> your ego?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={MountainTop} className={classes.responsiveImage}
                                 alt="Mountain"/>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.4em'}}>
                                    <strong>Prayer:</strong> God. Help me to stop covering my own sense of lacking by
                                    obtaining power, but help me come to you..to receive true power through my weakness.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


            </React.Fragment>
        )
    }
    return {
        render
    }
}
