import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Crossroads from '../assets/Crossroads.jpg'
import BridgeAtNight from '../assets/bridge-at-night.jpg'
import WorkAsATeam from '../assets/Work-as-a-team.jpg'
import YouWereNotBornTo from '../assets/You-were-not-born-to.jpg'
import Link from '@material-ui/core/Link'

export default function Day20 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={Crossroads} className={classes.responsiveImage}
                   alt="Stop at the crossroads and look around. Ask for the old, godly way, and walk in it. Travel its path, and you will find rest for your souls. Jeremiah 6:16"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  The key to finding the right path is often first to
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h4">
                  STOP
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  Be still
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Now GO and follow the old, godly way....travel in it and you will find rest.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Stop trying to do it all yourself: let's face it....you don't know what you're doing!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  But none of us do really....<br/>
                  we all need to follow the old, Godly way
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={BridgeAtNight} className={classes.responsiveImage} alt="Bridge at night"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={WorkAsATeam} className={classes.responsiveImage} alt="Work as a team"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                <img src={YouWereNotBornTo} className={classes.responsiveImage} style={{ width: '60%' }}
                     alt="You were not born to just go to work, pay bills and die"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  TAKE SOME<br/>
                  TIME OUT TO STOP:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  Join us on an XTREME CHARACTER CHALLENGE adventure<br/>
                  See our website for next dates and locations<br/>
                  <Link href="https://www.xtremecharacterchallenge.com" target="_blank"
                        rel="noopener">www.xtremecharacterchallenge.com</Link>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
