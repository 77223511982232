import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import HeartHill from '../assets/heart-hill.jpg'
import HeartMountain from '../assets/heart-mountain.jpg'
import Hugs from '../assets/hugs.jpg'
import SnowHeartOnRedCar from '../assets/snow-heart-on-red-car.jpg'
import LogoTextCircle from '../assets/Logo-Text-Circle.jpg'

export default function ValentinesDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={HeartHill} className={classes.responsiveImage}
                   alt="Lake in the shape of a heart"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="right" variant="h4" style={{ lineHeight: '1.8em', fontWeight: 'bold' }}>
                  TODAY IS ALL<br/>
                  ABOUT<br/>
                  LOVE
                </WhiteText>
              </Box>
              <Box style={{ width: '100%' }} mt={8}>
                <WhiteText align="right" variant="h4" style={{ lineHeight: '1.2em' }}>
                  But there are<br/>
                  different types<br/>
                  of love
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={SnowHeartOnRedCar} className={classes.responsiveImage}
                   alt="Snow heart on a red car"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} p={4}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  &hearts; EROS &hearts;
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  The erotic<br/>
                  passionate love
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  ... that has hijacked St<br/>
                  Valentine's Day
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} p={4}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  &hearts; PHILIA &hearts;
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  Love of friends<br/>
                  and equals
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  To which friend<br/>
                  could you say<br/>
                  "thanks for your friendship"<br/>
                  today?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  ... would they value<br/>
                  a gift or a card?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} p={4}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  &hearts; AGAPE &hearts;
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  Selfless and<br/>
                  unconditional love
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  ... the love that human<br/>
                  beings have for God<br/>
                  and the love that God<br/>
                  has for them
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  SIT BACK & THINK
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  Do a wall sit exercise for 30 seconds ... repeat if you dare
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center">
                  (your body should form 2 right angles - one at the hips and one at the knees)
                </WhiteText>
              </Box>
              <Box mt={3}>
                <WhiteText align="center" variant="h5">
                  Think about who you love ....
                </WhiteText>
              </Box>
              <Box mt={3}>
                <WhiteText align="center" variant="h5">
                  Show the love and say "thank you" to them (God too)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={Hugs} className={classes.responsiveImage}
                   alt="Two persons hugging"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={HeartMountain} className={classes.responsiveImage}
                   alt="Cave entrance in a shape of a heart"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullPoster2021Backdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={LogoTextCircle} className={classes.responsiveImage} style={{ maxWidth: '85%' }}
                   alt="XTREME CHARACTER CHALLENGE"/>
            </Box>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align={'left'} variant={'h5'}>
                  BE <b>BOLD</b>,<br/>
                  BE <b>ADVENTUROUS</b><br/>
                  ... <b>SIGN UP</b> TODAY
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
