import React from 'react'
import Box from '@material-ui/core/Box'
import Flag from '../../assets/england-flag.png'
import WhiteText from '../../components/Typography/WhiteText'

export default function EventDatesFirstOfYear ({ classes }) {
  return (
    <React.Fragment>
      <Box className={classes.verticalCenteredBox}>
        <img src={Flag} className={classes.responsiveImage} alt="Flag"/>
      </Box>
      <Box mt={6}>
        <WhiteText align="center">
          Join us:<br/>
          XTREME CHARACTER CHALLENGE<br/>
          WILDERNESS<br/>
          Northumberland 23-26th March 2023
        </WhiteText>
      </Box>
    </React.Fragment>
  )
}
