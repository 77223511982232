import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManSittingInFrontOfMountainWithMug from '../assets/man-sitting-in-front-of-mountain-with-mug.jpg'
import MenGatheringAtLake from '../assets/men-gathering-at-lake.jpg'

export default function Day29 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em', fontWeight: 'bold' }}>
                  YOU<br/>
                  ARE<br/>
                  NOT<br/>
                  PERFECT
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em', fontWeight: 'bold' }}>
                  DON'T<br/>
                  PRETEND<br/>
                  TO<br/>
                  BE!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManSittingInFrontOfMountainWithMug} className={classes.responsiveImage}
                   alt="Man sitting in front of mountain with mug"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  BEING ABLE TO ADMIT WHEN YOU ARE WRONG WITHOUT GETTING DEFENSIVE IS A SKILL
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Admit to someone that you made a mistake.<br/>
                  Say sorry.<br/>
                  Move on.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MenGatheringAtLake} className={classes.responsiveImage}
                   alt="Men gathering at lake"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullRemoteBackdropRed}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
