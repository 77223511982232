import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import WildernessHomeXCC from '../assets/Wilderness-Home-XCC.jpg'
import RainbowAbove from '../assets/rainbow-above.jpg'
import TentsAtNight from '../assets/Tents-at-night.jpg'
import ManOnMountainTop from '../assets/man-on-mountain-with-rainbow.jpg'

export default function Day63 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={WildernessHomeXCC} className={classes.responsiveImage}
                   alt="WILDERNESS: HOME OF THE XCC"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={RainbowAbove} className={classes.responsiveImage}
                   alt="Rainbow above"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={TentsAtNight} className={classes.responsiveImage}
                   alt="Tents at night"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} p={4}>
              <Box>
                <WhiteText align="left" variant="h5">
                  JUST A FEW PICTURES OF THE PLACES WE HAVE BEEN TOGETHER
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManOnMountainTop} className={classes.responsiveImage}
                   alt="Man on mountain top with rainbow in distance"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
