import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/sunny-cloud.jpg"

export default function Day94() {
    const render = (classes) => {
        return (
            <React.Fragment>
                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em'}}>
                                “Perfectionism is self-abuse of the highest order” <br/><br/>
                                </WhiteText>
                                <WhiteText align="left" variant="h5">
                                - Anne Wilson Schaef
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                <b>Are you exhausted <br/>
                                 by life?<br/> <br/>
                                Or are you paralysed <br/>
                                by the fear and enormity <br/>
                                 of it?   </b>
                                </WhiteText>

                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.9em'}}>
                                “Understanding the <br/>
                                 difference between healthy <br/>
                                 striving and perfectionism <br/>
                                 is critical to laying down <br/>
                                 the shield and picking up<br/>
                                  your life" <br/><br/>
                                </WhiteText>

                                <WhiteText align="left" variant="h5">
                                – Brené Brown

                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.3em'}}>
                                <b>Prayer:</b> <br/> <br/>
                                God help me.<br/>
                                I’m tired of being tired.<br/>

                                Teach me those unforced<br/>
                                rhythms of grace.<br/>
                                Bring to my mind practical<br/>
                                ways I can adjust my<br/>
                                approach.<br/> <br/>

                                Lift my eyes to you God.

                                </WhiteText>

                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                    <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                        <Box mt={2}>
                        <img src={Img1} className={classes.responsiveImage}
                            alt="the heavens"/>
                        </Box>
                    </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <Box>
                            <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em'}}>
                                <b>Need a break right <br/>
                                where you are? <br/><br/>
                                Come join us...
                                </b>
                            </WhiteText>
                            </Box>

                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.fullRemoteBackdropRed}/>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
