import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../providers/AuthProvider'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { saveReferrer } from '../state/actions/app'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { fireUser } = useContext(AuthContext)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (!fireUser) {
      dispatch(saveReferrer(location))
    }
  }, [fireUser, dispatch, location])

  return (
    <Route
      {...rest}
      render={routeProps =>
        !!fireUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={'/sign-in'}/>
        )
      }
    />
  )
}

export default PrivateRoute
