import React, { useEffect, useRef, useState, useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { BrowserView } from 'react-device-detect'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '100vh',
//     backgroundColor: '#333335',
//     padding: theme.spacing(2)
//   },
//   /*container: {
//     position: 'relative',
//   },
//   paper: {
//     height: '100%',
//   },*/
// }))
const GlobalStyles = withStyles(theme => ({
  '@global': {
    '.no-transition': {
      '-webkit-transition': 'none ! important',
      '-o-transition': 'none ! important',
      transition: 'none ! important'
    },
    '.stackedcards-overflow': {
      overflowY: 'hidden !important',
    },
    '.stackedcards.init': {
      opacity: 0, /* set the opacity to 0 if you want a fade-in effect to stacked cards on page load */
    },
    '.stackedcards': {
      position: 'relative',
    },
    // '.stackedcards *': {
    //   '-webkit-user-select': 'none',
    //   '-moz-user-select': 'none',
    //   '-ms-user-select': 'none',
    //   userSelect: 'none'
    // },
    '.stackedcards--animatable': {
      '-webkit-transition': 'all 400ms ease',
      '-o-transition': 'all 400ms ease',
      transition: 'all 400ms ease',
    },
    '.stackedcards-top': {
      overflow: 'hidden',
    },
    '.stackedcards .stackedcards-container > *, .stackedcards-overlay': {
      position: 'absolute',
      width: '100%',
      //height: 'calc(100vh - 16px - 56px - 12px)', // DOESN'T work anymore because AppBar size varies
      height: 'calc(90vh)',
      'will-change': 'transform, opacity',
      top: 0,
      [theme.breakpoints.up('md')]: {
        height: 'calc(76vh)',
        'will-change': 'transform, opacity',
      },
      [theme.breakpoints.up('lg')]: {
        height: 'calc(70vh)',
        'will-change': 'transform, opacity',
      },
    },
    '.stackedcards-overlay.left > div, .stackedcards-overlay.right > div, .stackedcards-overlay.top > div': {
      width: '100%',
      height: '100%',
      '-webkit-box-align': 'center',
      '-ms-flex-align': 'center',
      alignItems: 'center',
      //display: -webkit-box;
      //display: -ms-flexbox;
      display: 'flex',
      '-webkit-box-pack': 'center',
      '-ms-flex-pack': 'center',
      justifyContent: 'center'
    },
    '.stackedcards-overlay.left, .stackedcards-overlay.right, .stackedcards-overlay.top': {
      '-webkit-box-align': 'center',
      '-ms-flex-align': 'center',
      alignItems: 'center',
      //display: -webkit-box;
      //display: -ms-flexbox;
      display: 'flex',
      '-webkit-box-pack': 'center',
      '-ms-flex-pack': 'center',
      justifyContent: 'center',
      left: 0,
      opacity: 0,
      top: 0,
      height: '100%',
    },
    '.stackedcards-overlay.top': {
      background: '#E38568'
    },
    '.stackedcards-overlay.right': {
      background: '#7BB3C5'
    },
    '.stackedcards-overlay.left': {
      background: '#E58D93'
    },
    '.stackedcards-overlay.left:empty, .stackedcards-overlay.right:empty, .stackedcards-overlay.top:empty': {
      display: 'none !important'
    },
    '.stackedcards-overlay-hidden': {
      display: 'none'
    },
    '.stackedcards-origin-bottom': {
      '-webkit-transform-origin': 'bottom',
      '-ms-transform-origin': 'bottom',
      'transform-origin': 'bottom'
    },
    '.stackedcards-origin-top': {
      '-webkit-transform-origin': 'top',
      '-ms-transform-origin': 'top',
      'transform-origin': 'top'
    },
    '.stackedcards-bottom, .stackedcards-top, .stackedcards-none': {
      height: '100%'
    },
    '.stackedcards .stackedcards-container > :nth-child(1)': {
      position: 'relative',
      //display: 'block'
    },
    '.global-actions': {
      //display: -webkit-inline-box;
      //display: -ms-inline-flexbox;
      display: 'inline-flex',
      width: '100%',
    },
    '.button': {
      // [theme.breakpoints.up("lg")]:{
      //   width: '50%',
      //  position: 'absolute',
      //  padding: '1.5rem',
      //  borderRadius: '100px',
      //  top: '25rem',
      //  right: '15rem'
      // },
    },
    '.top-action, .right-action, .left-action': {
      width: '100%',
      height: '50px',
      borderRadius: '10px',
      //display: -webkit-box;
      //display: -ms-flexbox;
      display: 'flex',
      '-webkit-box-align': 'center',
      '-ms-flex-align': 'center',
      alignItems: 'center',
      '-webkit-box-pack': 'center',
      '-ms-flex-pack': 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontWeight: 500,
      color: '#fff'
    },
    '.top-action': {
      background: '#E38568',
      margin: '0 10px'
    },
    '.right-action': {
      background: '#7BB3C5'
    },
    '.left-action': {
      background: '#E58D93'
    },
  }
}))(() => null)

export default function CardStack ({ children, id }) {
  const stackedOptions = 'Top' // Change stacked cards view from 'Bottom', 'Top' or 'None'
  const rotate = true // Activate the elements' rotation for each move on stacked cards
  const elementsMargin = 10 // Define the distance of each element when the stacked options are bottom or top
  //const useOverlays = false // Enable or disable the overlays for swipe elements
  const velocity = 0.3 // Minimum velocity allowed to trigger a swipe

  //const classes = useStyles()
  const prevIdRef = useRef()
  const stackedCardsBlockRef = useRef(null)
  const containerRef = useRef(null)
  // const buttonLeftAction = useRef(null)
  // const buttonTopAction = useRef(null)
  // const buttonRightAction = useRef(null)
  const buttonRef = useRef(null)
  const topObjRef = useRef(null)
  const rightObjRef = useRef(null)
  const leftObjRef = useRef(null)

  const [items, setItems] = useState(3) // Number of visible elements when the stacked options are bottom or top
  const [maxElements, setMaxElements] = useState() // Total of stacked cards on DOM
  const [currentPosition, setCurrentPosition] = useState(0) // Keep the position of active stacked card
  const [listElNodesObj, setListElNodesObj] = useState() // Keep the list of nodes from stacked cards
  const [listElNodesWidth, setListElNodesWidth] = useState() // Keep the stacked cards width
  const [currentElementObj, setCurrentElementObj] = useState() // Keep the stacked card element to swipe
  //const [isFirstTime, setIsFirstTime] = useState(true)
  const [elementHeight, setElementHeight] = useState()

  // Touch events
  const [startTime, setStartTime] = useState()
  const [startX, setStartX] = useState()
  const [startY, setStartY] = useState()
  //const [translateX, setTranslateX] = useState()
  //const [translateY, setTranslateY] = useState()
  const [currentX, setCurrentX] = useState()
  const [currentY, setCurrentY] = useState()
  const [touchingElement, setTouchingElement] = useState(false)
  // const [topOpacity, setTopOpacity] = useState()
  // const [rightOpacity, setRightOpacity] = useState()
  // const [leftOpacity, setLeftOpacity] = useState()

  /**
   * Set previous refs on every render. This is how you store/access previous props in functional components
   */
  useEffect(() => {
    prevIdRef.current = id
  })
  const prevId = prevIdRef.current

  // Functions to swipe left elements on logic external action
  // function onActionLeft () {
  //   if (!(currentPosition >= maxElements)) {
  //     if (useOverlays) {
  //       leftObj.classList.remove('no-transition')
  //       topObj.classList.remove('no-transition')
  //       leftObj.style.zIndex = '8'
  //       transformUi(0, 0, 1, leftObj)
  //
  //     }
  //
  //     setTimeout(function () {
  //       onSwipeLeft()
  //       resetOverlayLeft()
  //     }, 300)
  //   }
  // }

  // Functions to swipe right elements on logic external action
  // function onActionRight () {
  //   if (!(currentPosition >= maxElements)) {
  //     if (useOverlays) {
  //       rightObj.classList.remove('no-transition')
  //       topObj.classList.remove('no-transition')
  //       rightObj.style.zIndex = '8'
  //       transformUi(0, 0, 1, rightObj)
  //     }
  //
  //     setTimeout(function () {
  //       onSwipeRight()
  //       resetOverlayRight()
  //     }, 300)
  //   }
  // }

  // Functions to swipe top elements on logic external action
  // function onActionTop () {
  //   if (!(currentPosition >= maxElements)) {
  //     if (useOverlays) {
  //       leftObj.classList.remove('no-transition')
  //       rightObj.classList.remove('no-transition')
  //       topObj.classList.remove('no-transition')
  //       topObj.style.zIndex = '8'
  //       transformUi(0, 0, 1, topObj)
  //     }
  //
  //     setTimeout(function () {
  //       onSwipeTop()
  //       resetOverlays()
  //     }, 300) //wait animations end
  //   }
  // }

  // Remove transitions from all elements to be moved in each swipe movement to improve performance of stacked cards
  const removeNoTransition = useCallback(() => {
    if (listElNodesObj[currentPosition]) {

      // if (useOverlays) {
      //   leftObj.classList.remove('no-transition')
      //   rightObj.classList.remove('no-transition')
      //   topObj.classList.remove('no-transition')
      // }

      listElNodesObj[currentPosition].classList.remove('no-transition')
      listElNodesObj[currentPosition].style.zIndex = 6
    }
  }, [listElNodesObj, currentPosition])

  // Move the overlay left to initial position
  // function resetOverlayLeft () {
  //   if (!(currentPosition >= maxElements)) {
  //     if (useOverlays) {
  //       setTimeout(function () {
  //
  //         if (stackedOptions === 'Top') {
  //
  //           elTrans = elementsMargin * (items - 1)
  //
  //         } else if (stackedOptions === 'Bottom' || stackedOptions === 'None') {
  //
  //           elTrans = 0
  //
  //         }
  //
  //         if (!isFirstTime) {
  //
  //           leftObj.classList.add('no-transition')
  //           topObj.classList.add('no-transition')
  //
  //         }
  //
  //         requestAnimationFrame(function () {
  //
  //           leftObj.style.transform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           leftObj.style.webkitTransform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           leftObj.style.opacity = '0'
  //
  //           topObj.style.transform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           topObj.style.webkitTransform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           topObj.style.opacity = '0'
  //
  //         })
  //
  //       }, 300)
  //
  //       isFirstTime = false
  //     }
  //   }
  // }

  // Move the overlay right to initial position
  // function resetOverlayRight () {
  //   if (!(currentPosition >= maxElements)) {
  //     if (useOverlays) {
  //       setTimeout(function () {
  //
  //         if (stackedOptions === 'Top') {
  //           //+2
  //
  //           elTrans = elementsMargin * (items - 1)
  //
  //         } else if (stackedOptions === 'Bottom' || stackedOptions === 'None') {
  //
  //           elTrans = 0
  //
  //         }
  //
  //         if (!isFirstTime) {
  //
  //           rightObj.classList.add('no-transition')
  //           topObj.classList.add('no-transition')
  //
  //         }
  //
  //         requestAnimationFrame(function () {
  //
  //           rightObj.style.transform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           rightObj.style.webkitTransform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           rightObj.style.opacity = '0'
  //
  //           topObj.style.transform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           topObj.style.webkitTransform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           topObj.style.opacity = '0'
  //
  //         })
  //
  //       }, 300)
  //
  //       isFirstTime = false
  //     }
  //   }
  // }

  // Move the overlays to initial position
  // function resetOverlays () {
  //   if (!(currentPosition >= maxElements)) {
  //     if (useOverlays) {
  //
  //       setTimeout(function () {
  //         if (stackedOptions === 'Top') {
  //
  //           elTrans = elementsMargin * (items - 1)
  //
  //         } else if (stackedOptions === 'Bottom' || stackedOptions === 'None') {
  //
  //           elTrans = 0
  //
  //         }
  //
  //         if (!isFirstTime) {
  //
  //           leftObj.classList.add('no-transition')
  //           rightObj.classList.add('no-transition')
  //           topObj.classList.add('no-transition')
  //
  //         }
  //
  //         requestAnimationFrame(function () {
  //
  //           leftObj.style.transform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           leftObj.style.webkitTransform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           leftObj.style.opacity = '0'
  //
  //           rightObj.style.transform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           rightObj.style.webkitTransform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           rightObj.style.opacity = '0'
  //
  //           topObj.style.transform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           topObj.style.webkitTransform = 'translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
  //           topObj.style.opacity = '0'
  //
  //         })
  //
  //       }, 300)	// wait for animations time
  //
  //       isFirstTime = false
  //     }
  //   }
  // }

  // function setActiveHidden () {
  //   if (!(currentPosition >= maxElements)) {
  //     listElNodesObj[currentPosition - 1].classList.remove('stackedcards-active')
  //     listElNodesObj[currentPosition - 1].classList.add('stackedcards-hidden')
  //     listElNodesObj[currentPosition].classList.add('stackedcards-active')
  //   }
  // }

  // Set the new z-index for specific card
  const setZindex = useCallback(zIndex => {
    if (listElNodesObj[currentPosition]) {
      listElNodesObj[currentPosition].style.zIndex = zIndex
    }
  }, [listElNodesObj, currentPosition])

  // Remove element from the DOM after swipe. To use this method you need to call this function in onSwipeLeft,
  // onSwipeRight and onSwipeTop and put the method just above the variable 'currentPosition = currentPosition + 1'.
  // On the actions onSwipeLeft, onSwipeRight and onSwipeTop you need to remove the currentPosition variable
  // (currentPosition = currentPosition + 1) and the function setActiveHidden

  // function removeElement () {
  //   currentElementObj.remove()
  //   if (!(currentPosition >= maxElements)) {
  //     listElNodesObj[currentPosition].classList.add('stackedcards-active')
  //   }
  // }

  // Add translate X and Y to active card for each frame
  const transformUi = useCallback((moveX, moveY, opacity, elementObj) => {
    //console.debug('TRANSFORM UI')
    requestAnimationFrame(function () {
      const element = elementObj

      // Function to generate rotate value
      function RotateRegulator (value) {
        if (value / 10 > 15) {
          return 15
        } else if (value / 10 < -15) {
          return -15
        }
        return value / 10
      }

      let rotateElement
      if (rotate) {
        rotateElement = RotateRegulator(moveX)
      } else {
        rotateElement = 0
      }

      if (stackedOptions === 'Top') {
        const elTrans = elementsMargin * (items - 1)
        if (element) {
          element.style.webkitTransform = 'translateX(' + moveX + 'px) translateY(' + (moveY + elTrans) + 'px) translateZ(0) rotate(' + rotateElement + 'deg)'
          element.style.transform = 'translateX(' + moveX + 'px) translateY(' + (moveY + elTrans) + 'px) translateZ(0) rotate(' + rotateElement + 'deg)'
          element.style.opacity = opacity
        }
      } else if (stackedOptions === 'Bottom' || stackedOptions === 'None') {

        if (element) {
          element.style.webkitTransform = 'translateX(' + moveX + 'px) translateY(' + (moveY) + 'px) translateZ(0) rotate(' + rotateElement + 'deg)'
          element.style.transform = 'translateX(' + moveX + 'px) translateY(' + (moveY) + 'px) translateZ(0) rotate(' + rotateElement + 'deg)'
          element.style.opacity = opacity
        }
      }
    })
  }, [stackedOptions, elementsMargin, items, rotate])

  // Action to update all elements on the DOM for each stacked card
  const updateUi = useCallback(() => {
    //console.debug('UPDATE UI', currentPosition)
    requestAnimationFrame(function () {
      let elTrans = 0
      let elZindex = 5
      //var elScale = 1
      let elScale = 0.98
      let elOpac = 1
      let elTransTop = items
      const elTransInc = elementsMargin

      for (let i = currentPosition; i < (currentPosition + items); i++) {
        if (listElNodesObj[i]) {
          if (stackedOptions === 'Top') {

            listElNodesObj[i].classList.add('stackedcards-top', 'stackedcards--animatable', 'stackedcards-origin-top')

            // if (useOverlays) {
            //   leftObj.classList.add('stackedcards-origin-top')
            //   rightObj.classList.add('stackedcards-origin-top')
            //   topObj.classList.add('stackedcards-origin-top')
            // }

            elTrans = elTransInc * elTransTop
            elTransTop--

          } else if (stackedOptions === 'Bottom') {
            listElNodesObj[i].classList.add('stackedcards-bottom', 'stackedcards--animatable', 'stackedcards-origin-bottom')

            // if (useOverlays) {
            //   leftObj.classList.add('stackedcards-origin-bottom')
            //   rightObj.classList.add('stackedcards-origin-bottom')
            //   topObj.classList.add('stackedcards-origin-bottom')
            // }

            elTrans = elTrans + elTransInc

          } else if (stackedOptions === 'None') {

            listElNodesObj[i].classList.add('stackedcards-none', 'stackedcards--animatable')
            elTrans = elTrans + elTransInc

          }

          listElNodesObj[i].style.transform = 'scale(' + elScale + ') translateX(0) translateY(' + (elTrans - elTransInc) + 'px) translateZ(0)'
          listElNodesObj[i].style.webkitTransform = 'scale(' + elScale + ') translateX(0) translateY(' + (elTrans - elTransInc) + 'px) translateZ(0)'
          listElNodesObj[i].style.opacity = elOpac
          listElNodesObj[i].style.zIndex = elZindex

          elScale = elScale - 0.04
          elOpac = elOpac - (1 / items)
          elZindex--
        }
      }
    })
  }, [items, elementsMargin, currentPosition, listElNodesObj, stackedOptions])

  // const setOverlayOpacity = useCallback(() => {
  //   let topOpacity = (((translateY + (elementHeight) / 2) / 100) * -1)
  //   let rightOpacity = (translateX / 100)
  //   let leftOpacity = ((translateX / 100) * -1)
  //
  //   if (topOpacity > 1) {
  //     topOpacity = 1
  //   }
  //
  //   if (rightOpacity > 1) {
  //     rightOpacity = 1
  //   }
  //
  //   if (leftOpacity > 1) {
  //     leftOpacity = 1
  //   }
  //
  //   setTopOpacity(topOpacity)
  //   setRightOpacity(rightOpacity)
  //   setLeftOpacity(leftOpacity)
  // }, [translateX, translateY, elementHeight])

  const backToMiddle = useCallback(() => {
    //console.debug('BACK TO MIDDLE', currentPosition)
    removeNoTransition()
    transformUi(0, 0, 1, currentElementObj)

    // if (useOverlays) {
    //   transformUi(0, 0, 0, leftObj)
    //   transformUi(0, 0, 0, rightObj)
    //   transformUi(0, 0, 0, topObj)
    // }

    setZindex(5)

    if (!(currentPosition >= maxElements)) {
      //roll back the opacity of second element
      if ((currentPosition + 1) < maxElements) {
        listElNodesObj[currentPosition + 1].style.opacity = '.8'
      }
    }
  }, [currentElementObj, currentPosition, maxElements, listElNodesObj, removeNoTransition, transformUi, setZindex])

  // Swipe active card to left
  const onSwipeLeft = useCallback(() => {
    removeNoTransition()
    transformUi(-1000, 0, 0, currentElementObj)

    // if (useOverlays) {
    //   transformUi(-1000, 0, 0, leftObj) //Move leftOverlay
    //   transformUi(-1000, 0, 0, topObj) //Move topOverlay
    //   resetOverlayLeft()
    // }

    //currentPosition = currentPosition + 1
    setCurrentPosition(currentPosition === (maxElements - 1) ? 0 : currentPosition + 1)
    //updateUi()
    //currentElement()
    //setActiveHidden()
  }, [currentElementObj, currentPosition, maxElements, removeNoTransition, transformUi])

  // Swipe active card to right
  const onSwipeRight = useCallback(() => {
    removeNoTransition()
    transformUi(1000, 0, 0, currentElementObj)

    // if (useOverlays) {
    //   transformUi(1000, 0, 0, rightObj) //Move rightOverlay
    //   transformUi(1000, 0, 0, topObj) //Move topOverlay
    //   resetOverlayRight()
    // }

    //currentPosition = currentPosition + 1
    setCurrentPosition(currentPosition === (maxElements - 1) ? 0 : currentPosition + 1)
    //updateUi()
    //currentElement()
    //setActiveHidden()
  }, [currentElementObj, currentPosition, maxElements, removeNoTransition, transformUi])

  // Swipe active card to top
  const onSwipeTop = useCallback(() => {
    removeNoTransition()
    transformUi(0, -1000, 0, currentElementObj)

    // if (useOverlays) {
    //   transformUi(0, -1000, 0, leftObj) //Move leftOverlay
    //   transformUi(0, -1000, 0, rightObj) //Move rightOverlay
    //   transformUi(0, -1000, 0, topObj) //Move topOverlay
    //   resetOverlays()
    // }

    //currentPosition = currentPosition + 1
    setCurrentPosition(currentPosition === (maxElements - 1) ? 0 : currentPosition + 1)
    //updateUi()
    //currentElement()
    //setActiveHidden()
  }, [currentElementObj, currentPosition, maxElements, removeNoTransition, transformUi])

  const onTouchEnd = useCallback(evt => {
    //console.debug('TOUCH END')
    if (!touchingElement) {
      return
    }

    const translateX = currentX - startX
    const translateY = currentY - startY
    const timeTaken = new Date().getTime() - startTime

    //setTranslateX(translateX)
    //setTranslateY(translateY)

    setTouchingElement(false)

    if (!(currentPosition >= maxElements)) {
      if (translateY < (elementHeight * -1) && translateX > ((listElNodesWidth / 2) * -1) && translateX < (listElNodesWidth / 2)) {  // is Top?

        if (translateY < (elementHeight * -1) || (Math.abs(translateY) / timeTaken > velocity)) { // Did It Move To Top?
          onSwipeTop()
        } else {
          backToMiddle()
        }

      } else {

        if (translateX < 0) {
          if (translateX < ((listElNodesWidth / 2) * -1) || (Math.abs(translateX) / timeTaken > velocity)) { // Did It Move To Left?
            onSwipeLeft()
          } else {
            backToMiddle()
          }
        } else if (translateX > 0) {

          if (translateX > (listElNodesWidth / 2) && (Math.abs(translateX) / timeTaken > velocity)) { // Did It Move To Right?
            onSwipeRight()
          } else {
            backToMiddle()
          }

        }
      }
    }
  }, [touchingElement, currentX, currentY, startX, startY, startTime, currentPosition, maxElements, elementHeight, listElNodesWidth, velocity, backToMiddle, onSwipeRight, onSwipeLeft, onSwipeTop])

  const onTouchStart = useCallback(evt => {
    //console.debug('TOUCH START', currentPosition)
    setStartTime(new Date().getTime())

    const startX = evt.changedTouches[0].clientX
    const startY = evt.changedTouches[0].clientY

    setStartX(startX)
    setStartY(startY)

    setCurrentX(startX)
    setCurrentY(startY)

    //setOverlayOpacity()

    setTouchingElement(true)

    if (!(currentPosition >= maxElements)) {
      if (listElNodesObj[currentPosition]) {
        listElNodesObj[currentPosition].classList.add('no-transition')
        setZindex(6)

        // if (useOverlays) {
        //   leftObj.classList.add('no-transition')
        //   rightObj.classList.add('no-transition')
        //   topObj.classList.add('no-transition')
        // }

        if ((currentPosition + 1) < maxElements) {
          listElNodesObj[currentPosition + 1].style.opacity = '1'
        }

        setElementHeight(listElNodesObj[currentPosition].offsetHeight / 3)
      }
    }
  }, [listElNodesObj, currentPosition, maxElements, setZindex])

  const onTouchMove = useCallback(evt => {
    //console.debug('TOUCH MOVE', currentPosition)
    const currentX = evt.changedTouches[0].pageX
    const currentY = evt.changedTouches[0].pageY

    setCurrentX(currentX)
    setCurrentY(currentY)

    const translateX = currentX - startX
    const translateY = currentY - startY

    //setOverlayOpacity()

    if (!(currentPosition >= maxElements)) {
      evt.preventDefault()
      transformUi(translateX, translateY, 1, currentElementObj)

      // if (useOverlays) {
      //   transformUi(translateX, translateY, topOpacity, topObj)
      //
      //   if (translateX < 0) {
      //     transformUi(translateX, translateY, leftOpacity, leftObj)
      //     transformUi(0, 0, 0, rightObj)
      //
      //   } else if (translateX > 0) {
      //     transformUi(translateX, translateY, rightOpacity, rightObj)
      //     transformUi(0, 0, 0, leftObj)
      //   }
      //
      //   if (useOverlays) {
      //     leftObj.style.zIndex = 8
      //     rightObj.style.zIndex = 8
      //     topObj.style.zIndex = 7
      //   }
      // }
    }
  }, [startX, startY, currentPosition, maxElements, currentElementObj, transformUi])

  // Functions to swipe right elements on button click
  const onButtonClick = useCallback(() => {
    if (!(currentPosition >= maxElements)) {
      // if (useOverlays) {
      //   rightObj.classList.remove('no-transition')
      //   topObj.classList.remove('no-transition')
      //   rightObj.style.zIndex = '8'
      //   transformUi(0, 0, 1, rightObj)
      // }

      setTimeout(function () {
        onSwipeRight()
        //resetOverlayRight()
      }, 300)
    }
  }, [currentPosition, maxElements, onSwipeRight])

  const onInit = useCallback(() => {
    const stackedCardsBlock = stackedCardsBlockRef.current
    const container = containerRef.current
    const listElNodesObj = container.children
    const listElNodesWidth = container.offsetWidth
    const maxElements = container.children.length
    const leftObj = leftObjRef.current
    const rightObj = rightObjRef.current
    const topObj = topObjRef.current
    // console.debug('INIT new card stack', listElNodesObj, items, maxElements)

    setListElNodesObj(listElNodesObj)
    setListElNodesWidth(listElNodesWidth)
    setMaxElements(maxElements)
    setCurrentPosition(0)

    if (items > maxElements) {
      setItems(maxElements)
    }

    // Prepare elements on DOM
    const addMargin = elementsMargin * (items - 1) + 'px'
    let elTrans

    if (stackedOptions === 'Top') {

      for (let i = items; i < maxElements; i++) {
        listElNodesObj[i].classList.add('stackedcards-top', 'stackedcards--animatable', 'stackedcards-origin-top')
      }

      elTrans = elementsMargin * (items - 1)

      container.style.marginBottom = addMargin

    } else if (stackedOptions === 'Bottom') {

      for (let i = items; i < maxElements; i++) {
        listElNodesObj[i].classList.add('stackedcards-bottom', 'stackedcards--animatable', 'stackedcards-origin-bottom')
      }

      elTrans = 0

      container.style.marginBottom = addMargin

    } else if (stackedOptions === 'None') {

      for (let i = items; i < maxElements; i++) {
        listElNodesObj[i].classList.add('stackedcards-none', 'stackedcards--animatable')
      }

      elTrans = 0
    }

    for (let i = items; i < maxElements; i++) {
      listElNodesObj[i].style.zIndex = 0
      listElNodesObj[i].style.opacity = 0
      listElNodesObj[i].style.webkitTransform = 'scale(' + (1 - (items * 0.04)) + ') translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
      listElNodesObj[i].style.transform = 'scale(' + (1 - (items * 0.04)) + ') translateX(0) translateY(' + elTrans + 'px) translateZ(0)'
    }

    // if (useOverlays) {
    //   leftObj.style.transform = 'translateX(0px) translateY(' + elTrans + 'px) translateZ(0px) rotate(0deg)'
    //   leftObj.style.webkitTransform = 'translateX(0px) translateY(' + elTrans + 'px) translateZ(0px) rotate(0deg)'
    //
    //   rightObj.style.transform = 'translateX(0px) translateY(' + elTrans + 'px) translateZ(0px) rotate(0deg)'
    //   rightObj.style.webkitTransform = 'translateX(0px) translateY(' + elTrans + 'px) translateZ(0px) rotate(0deg)'
    //
    //   topObj.style.transform = 'translateX(0px) translateY(' + elTrans + 'px) translateZ(0px) rotate(0deg)'
    //   topObj.style.webkitTransform = 'translateX(0px) translateY(' + elTrans + 'px) translateZ(0px) rotate(0deg)'
    //
    // } else {
    leftObj.className = ''
    rightObj.className = ''
    topObj.className = ''

    leftObj.classList.add('stackedcards-overlay-hidden')
    rightObj.classList.add('stackedcards-overlay-hidden')
    topObj.classList.add('stackedcards-overlay-hidden')
    //}

    // Remove class init
    setTimeout(function () {
      stackedCardsBlock.classList.remove('init')
    }, 150)
  }, [items, elementsMargin, stackedOptions])

  // Keep the active card
  // const currentElement = () => {
  //   setCurrentElementObj(listElNodesObj[currentPosition])
  // }
  useEffect(() => {
    if (listElNodesObj && listElNodesObj[currentPosition]) {
      setCurrentElementObj(listElNodesObj[currentPosition])
      updateUi()
      listElNodesObj[currentPosition].classList.add('stackedcards-active')
    }
  }, [listElNodesObj, currentPosition, updateUi])

  useEffect(() => {
    if (id !== prevId) {
      onInit()
    }
  }, [id, prevId, onInit])

  useEffect(() => {
    const stackedCardsBlock = stackedCardsBlockRef.current
    //stackedCardsBlock.addEventListener('touchstart', gestureStart, { capture: false, passive: true })
    stackedCardsBlock.addEventListener('touchstart', onTouchStart, false)
    //stackedCardsBlock.addEventListener('touchmove', gestureMove, { capture: false, passive: true })
    stackedCardsBlock.addEventListener('touchmove', onTouchMove, false)
    stackedCardsBlock.addEventListener('touchend', onTouchEnd, false)

    //Add listeners to call global action for swipe cards
    //var buttonLeft = document.querySelector('.left-action')
    //const buttonLeft = buttonLeftAction.current
    //var buttonTop = document.querySelector('.top-action')
    //const buttonTop = buttonTopAction.current
    //var buttonRight = document.querySelector('.right-action')
    //const buttonRight = buttonRightAction.current
    const button = buttonRef.current

    // buttonLeft.addEventListener('click', onActionLeft, false)
    // buttonTop.addEventListener('click', onActionTop, false)
    // buttonRight.addEventListener('click', onActionRight, false)
    if (button) {
      button.addEventListener('click', onButtonClick, false)
    }
    return () => {
      stackedCardsBlock.removeEventListener('touchstart', onTouchStart)
      stackedCardsBlock.removeEventListener('touchmove', onTouchMove)
      stackedCardsBlock.removeEventListener('touchend', onTouchEnd)

      if (button) {
        button.removeEventListener('click', onButtonClick)
      }
    }
  })

  return (
    // <Grid container component="main" className={classes.root}>
    //   <Grid item xs={12} className={classes.container}>
    //     <Paper className={classes.paper} elevation={4}>A</Paper>
    //     <Paper className={classes.paper} elevation={4}>B</Paper>
    //     <Paper className={classes.paper} elevation={4}>C</Paper>
    //     <Paper className={classes.paper} elevation={4}>D</Paper>
    //     <Paper className={classes.paper} elevation={4}>E</Paper>
    //   </Grid>
    // </Grid>
    <React.Fragment>
      <GlobalStyles/>

      <div ref={stackedCardsBlockRef} id="stacked-cards-block" className="stackedcards stackedcards--animatable init">
        <div ref={containerRef} className="stackedcards-container">
          {children}
        </div>
        <div ref={topObjRef} className="stackedcards--animatable stackedcards-overlay top">TOP</div>
        <div ref={rightObjRef} className="stackedcards--animatable stackedcards-overlay right">RIGHT</div>
        <div ref={leftObjRef} className="stackedcards--animatable stackedcards-overlay left">LEFT</div>
      </div>
      <BrowserView>
        <div className="global-actions">
          {/*<div ref={buttonLeftAction} className="left-action">Left</div>*/}
          {/*<div ref={buttonTopAction} className="top-action">Top</div>*/}
          {/*<div ref={buttonRightAction} className="right-action">Right</div>*/}
          <Button ref={buttonRef} variant="contained" color="secondary" size="large" fullWidth={true}
                  className="button">
            Next card
          </Button>
        </div>
      </BrowserView>

    </React.Fragment>
  )
}
