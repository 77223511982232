import {
  APP_TOGGLE_IOS_INSTALL_PROMPT,
  APP_TOGGLE_SIDE_MENU,
  APP_CHANGE_UNREAD_MESSAGES_COUNT,
  APP_SIGN_UP,
  APP_SAVE_REFERRER,
} from '../actionTypes'
import {
  signIn as firebaseSignIn,
  signUp as firebaseSignUp,
  forgotPassword as firebaseForgotPassword,
} from '../../base'
import { logEvent } from '../../analytics'
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

export const showiOSInstallPrompt = () => ({
  type: APP_TOGGLE_IOS_INSTALL_PROMPT,
  payload: {
    showiOSInstallPrompt: true
  }
})

export const hideiOSInstallPrompt = () => ({
  type: APP_TOGGLE_IOS_INSTALL_PROMPT,
  payload: {
    showiOSInstallPrompt: false,
  },
})

export const toggleSideMenu = showSideMenu => ({
  type: APP_TOGGLE_SIDE_MENU,
  payload: {
    showSideMenu,
  },
})

export const changeUnreadMessagesCount = count => ({
  type: APP_CHANGE_UNREAD_MESSAGES_COUNT,
  payload: {
    count
  }
})

const _signUp = (firstName, lastName) => ({
  type: APP_SIGN_UP,
  payload: {
    firstName,
    lastName,
  },
})

export const saveReferrer = referrer => ({
  type: APP_SAVE_REFERRER,
  payload: {
    referrer,
  },
})

export const signIn = (event, history, enqueueSnackbar) => {
  return async (dispatch, getState) => {
    event.preventDefault()
    const { email, password, /*remember*/ } = event.target.elements
    await firebaseSignIn(email.value.trim(), password.value.trim(), enqueueSnackbar)
      .then(() => logEvent('sign-in', { method: 'emailAndPassword' }))
      .then(() => {
        const { referrer } = getState().app
        if (referrer) {
          Promise.resolve(dispatch(saveReferrer(null)))
            .then(() => {
              setTimeout(() => history.push(referrer.pathname), 1000)
            })
        } else {
          setTimeout(() => history.push('/my-adventures'), 1000)
        }
      })
      .catch(err => {
        logEvent('exception', { description: `Could not sign in: ${err.message}`, fatal: true })
        enqueueSnackbar(err.message, { variant: 'error' })
      })
  }
}

export const signUp = (userAttributes, history, enqueueSnackbar) => {
  return dispatch => {
    const { firstName, lastName } = userAttributes
    return Promise.resolve()
      .then(() => dispatch(_signUp(firstName, lastName)))
      .then(() => firebaseSignUp(userAttributes, history, enqueueSnackbar))
  }
}

export const forgotPassword = (event, history, enqueueSnackbar, closeSnackbar) => {
  return async () => {
    event.preventDefault()
    const { email } = event.target.elements
    await firebaseForgotPassword(email.value)
      .then(() => logEvent('forgot-password'))
      .then(() => {
        enqueueSnackbar('Email sent. Please check your inbox (and junk folder)', {
          persist: true,
          action: (key) => {
            return (
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
                  <CloseIcon fontSize="small"/>
                </IconButton>
              </React.Fragment>
            )
          }
        })
        setTimeout(() => history.push('/sign-in'), 1000)
      })
      .catch(err => {
        logEvent('exception', { description: `Could not send password reset email: ${err.message}` })
        enqueueSnackbar(err.message, { variant: 'error' })
      })
  }
}