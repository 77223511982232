import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import LifeMatters from '../assets/3men-life-matters.jpg'

export default function Day6 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Stuff, gadgets, things: they build up and clutter up our lives.
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  LIFE is not about what you have (you probably don't need all that anyway)
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  A fulfilled LIFE is simply all about relationships, community, family, faith
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundLogoAndFlags}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={LifeMatters} className={classes.responsiveImage} alt="3 men - life matters"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  "Keep your life free from the love of money and be content with what you have"
                </WhiteText>
              </Box>
              <Box mb={6}>
                <WhiteText align="center" variant="h6">
                  (Hebrews 13v5)
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center" variant="h6">
                  <b>Love people and use money - NOT the other way round!</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Give something away!
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center">
                  Something that you like, but don't need.
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center">
                  We need to practice being comfortable with discomfort & generosity.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
