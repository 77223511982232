import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import EasterBunny from '../assets/easter-bunny.jpg'
import HeIsRisen from '../assets/HE-IS-RISEN.jpg'
import ManKneelingInRoad from '../assets/man-kneeling-in-road.jpg'

export default function EasterMonday () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={EasterBunny} className={classes.responsiveImage}
                   alt="Easter Bunny"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  I think this little Bunny<br/>
                  just found out<br/>
                  that Easter isn't<br/>
                  all about him!!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={HeIsRisen} className={classes.responsiveImage}
                   alt="HE IS RISEN"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.4em' }}>
                  "There is nothing to<br/>
                  fear here.<br/>
                  I know you are<br/>
                  looking for Jesus,<br/>
                  the One they nailed<br/>
                  to the cross.<br/>
                  He is not here.<br/>
                  He was raised, just<br/>
                  as he said."
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Matthew 28v 6
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h6">
                  What do you think about the<br/>
                  death and resurrection of<br/>
                  Jesus?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  Has the Good News<br/>
                  impacted you this year?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  How will you change?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManKneelingInRoad} className={classes.responsiveImage}
                   alt="Man kneeling in road"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.4em' }}>
                  Whatever road<br/>
                  you are on:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  <b>STOP and SURRENDER</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
