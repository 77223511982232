import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TrainYourMind from '../assets/train-your-mind.jpg'
import SunOverLoch from '../assets/sun-over-loch.jpg'

export default function Day37 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  CALM DOWN
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <mark>Calmness can prevent great offences</mark>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <mark>Ecclesiastes 10:4</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  WHAT<br/>
                  HELPS YOU<br/>
                  STAY CALM?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={TrainYourMind} className={classes.responsiveImage}
                   alt="Train your mind to be calm in every situation"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={SunOverLoch} className={classes.responsiveImage}
                   alt="Sun over loch"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  CHILL OUT...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  ...FIND PEACE & RELAX
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullRemoteBackdropGrey}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
