import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import FaceHeldWithQuote from '../assets/Look-ahead.jpg'
//import Button from '@material-ui/core/Button'

export default function Day19 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Not forgiving somebody is like YOU drinking poison and then watching to see if THEY die.....!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Don't be a plum!!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  FORGIVE
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Do not judge, and you will not be judged.<br/>
                  Do not condemn, and you will not be condemned.<br/>
                  Forgive, and you will be forgiven
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h6">
                  Luke 6: 37
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={FaceHeldWithQuote} className={classes.responsiveImage} alt="Face held with quote"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Forgiveness isn't easy
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  BUT
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  it IS good for you
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  FORGIVE (again?)<br/>
                  someone who has hurt you
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="center" variant="h6">
                  read the book<br/>
                  "TOTAL FORGIVENESS"<br/>
                  if you want to find out more
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
        {/*<div>*/}
        {/*  <Card isWithinCardStack={true} className={classes.fullKeysToFREEDOMBackdrop}>*/}
        {/*    <Box className={classes.callToActionButtonContainer}>*/}
        {/*      <Button variant="outlined" href="https://www.mercyuk.org/keystofreedomremote"*/}
        {/*              target="_blank" rel="noopener" size="large" fullWidth={true}>*/}
        {/*        Check it out!*/}
        {/*      </Button>*/}
        {/*    </Box>*/}
        {/*  </Card>*/}
        {/*</div>*/}
      </React.Fragment>
    )
  }
  return {
    render
  }
}
