import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/XCC-C1-Sofa-SMedia-3-IN.jpg"
import Img2 from "../assets/joshua-earle--87JyMb9ZfU-unsplash.jpg"

export default function Day86() {
    const render = (classes) => {
        return (
            <React.Fragment>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h2" style={{lineHeight: '1.2em', fontWeight: 700}}>
                                    What is your default?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    <strong>What is your ‘go to’ in</strong><br/>
                                    times of stress?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em', fontWeight: 700}}>
                                    What do you want to change within?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}  >
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <img src={Img1} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.4em', fontWeight: 700}}>
                                    Reboot. <br/>
                                    Recharge. <br/>
                                    Refresh.<br/>
                                    Restart.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h3" style={{lineHeight: '1.2em'}}>
                                    <span style={{textDecoration:"underline"}}>Whoever told you that you <span style={{color:"red"}}>can</span>’t change?</span>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

              <div>
                <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
              </div>

                <div>
                    <Card isWithinCardStack={true}  >
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <WhiteText variant="h5">FACE YOUR EVEREST WITHIN</WhiteText><br/>
                            <img src={Img2} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
