export const APP_TOGGLE_IOS_INSTALL_PROMPT = 'APP_TOGGLE_IOS_INSTALL_PROMPT'
export const APP_TOGGLE_SIDE_MENU = 'APP_TOGGLE_SIDE_MENU'
export const APP_CHANGE_UNREAD_MESSAGES_COUNT = 'APP_CHANGE_UNREAD_MESSAGES_COUNT'
export const APP_SIGN_UP = 'APP_SIGN_UP'
export const APP_SAVE_REFERRER = 'APP_SAVE_REFERRER'
export const COURSE_USER_SET = 'COURSE_USER_SET'
export const COURSE_USER_CHANGE_INPUT = 'COURSE_USER_CHANGE_INPUT'
export const COURSE_USER_CHANGE_LAST_CONTACT = 'COURSE_USER_CHANGE_LAST_CONTACT'
export const COURSE_USER_COMPLETE_LECTURE = 'COURSE_USER_COMPLETE_LECTURE'
export const COURSE_USER_CHANGE_CHALLENGES = 'COURSE_USER_CHANGE_CHALLENGES'
export const COURSE_USER_COMPLETE_CHALLENGES = 'COURSE_USER_COMPLETE_CHALLENGES'
