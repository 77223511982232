import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import BeardedFaceWithQuote from '../assets/bearded-face-with-quote.jpg'
import MugByRiver from '../assets/mug-by-river.jpg'

export default function Day9 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  If happiness is the goal - and it should be, then <b>ADVENTURES</b> should be a priority
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h6">
                  Richard Branson
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h6">
                  (We can agree with R.B if by happiness he means JOY!)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.greyHeadlineBox}>
              <WhiteText align="center" variant="h6">
                Where's your happy place?
              </WhiteText>
            </Box>
            <Box className={classes.verticalCenteredBox}>
              <img src={MugByRiver} className={classes.responsiveImage} alt="Mug by river"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={BeardedFaceWithQuote} className={classes.responsiveImage} alt="Bearded face with quote"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundMenWalkingPathRainbow}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  GET FIT FOR ADVENTURE
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center">
                  Without stopping ...<br/>
                  <b>10 press ups</b><br/>
                  <b>10 sit ups</b><br/>
                  <b>10 Squats</b><br/>
                  <b>10 star jumps</b><br/>
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  Get your breath back and ...<br/>
                  do it all again!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
