import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import UpTheHill from '../assets/IMG_0710.jpeg'

export default function Day5 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Look for peace IN the storm...<br/>
                  ...rather than thinking peace is only possible AFTER the storm.
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  The former is possible if we don't get distracted looking for the latter.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Be strong and courageous. Do not fear or be in dread of them, for it is the Lord your God who goes
                  with you. He will not leave you or forsake you.
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center" variant="h6">
                  (Deuteronomy 31v6)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={UpTheHill} className={classes.responsiveImage} alt="Up the hill"/>
            </Box>
            <Box>
              <WhiteText align="center" variant="h6">
                Join us in England, Scotland or Wales for YOUR next wilderness adventure
              </WhiteText>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundTents}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Arrange to have an email & social media free evening this week
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center">
                  ... tell other members in your household you are doing this so they can hold you accountable
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
