import { get } from 'lodash-es'
import { NavLink } from 'react-router-dom'
import React from 'react'

export default {
  // Custom <a> processing
  shouldProcessNode: function (node) {
    return get(node, 'name') === 'a' && get(node, 'attribs.rel') === 'internal'
  },
  processNode: function (node, children) {
    return (
      <NavLink to={get(node, 'attribs.href')}>{get(node, 'firstChild.data')}</NavLink>
    )
  }
}
