import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManOnMountainTop from '../assets/man-on-mountain-top.jpg'

export default function Day3 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  HOPE rises
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  ... LOOK UP.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={ManOnMountainTop} className={classes.responsiveImage} alt="Man on mountain top"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  What motivates <b>YOU</b>?
                </WhiteText>
              </Box>
              <Box mb={6}>
                <WhiteText align="center" variant="h6">
                  How can you increase your HOPE today?
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  "There IS a future & your HOPE will NOT be cut off"
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center" variant="h6">
                  (Proverbs 23v18)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundClimbingHill}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Do 50 sit ups
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center">
                  As you sit UP...look UP...and think about how your HOPE will grow in each area of your life.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
