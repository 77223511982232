export default function getGlobalStyles (theme) {
  return {
    'a': {
      color: '#cd151c',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '.default-lecture-form': {
      '& label:not(.inline)': {
        display: 'block',
        marginBottom: '6px',
      },
      '& textarea': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '1rem',
        width: '100%',
        marginBottom: '16px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,0.075)',
        '-webkit-transition': 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
        'transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',

        '&:focus': {
          outline: '0px !important',
          '-webkit-appearance': 'none',
          boxShadow: 'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(220,21,28,0.6)',
          borderColor: theme.palette.secondary.main,
        },
      },
      '& input[type="text"]': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '1rem',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,0.075)',
        '-webkit-transition': 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
        'transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',

        '&:focus': {
          outline: '0px !important',
          '-webkit-appearance': 'none',
          boxShadow: 'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(220,21,28,0.6)',
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    '.panel-group': {
      marginBottom: '20px',
    },
    '.panel-group .panel': {
      marginBottom: 0,
      borderRadius: '4px',
    },
    '.panel': {
      marginBottom: '20px',
      backgroundColor: '#fff',
      borderColor: '#cd151c',
      border: '1px solid transparent',
      borderRadius: '4px',
      boxShadow: '0 1px 1px rgba(0,0,0,0.05)',
    },
    '.panel-group .panel-heading': {
      borderBottom: 0,
    },
    '.panel-heading': {
      color: '#fff',
      backgroundColor: '#cd151c',
      borderColor: '#cd151c',
      padding: '10px 15px',
      borderBottom: '1px solid transparent',
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
    },
    '.panel-footer': {
      color: '#fff',
      backgroundColor: '#cd151c',
      borderColor: '#cd151c',
      padding: '10px 15px',
      borderBottom: '1px solid transparent',
    },
    '.panel-title': {
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 0,
      fontSize: '16px',
      color: 'inherit',
    },
    '.panel-group .panel-heading+.panel-collapse>.panel-body': {
      borderTop: '1px solid #ddd',
    },
    '.panel-body': {
      padding: '15px',
      color: '#000',
    },
    '.challenges-list ul': {
      paddingInlineStart: 0,
      listStyle: 'none',
      margin: 0,
    },
    '.challenges-list li': {
      marginBottom: '15px',

      '&.icon': {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',

        '&::before': {
          marginRight: '30px',
          content: '""',
          display: 'inline-block',
          height: '3em',
          width: '3em',
          minWidth: '3em',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }
      },
      '& textarea': {
        margin: 0,
      },
      '& a': {
        display: 'contents',
      },
    },
    '.challenges-list li:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/challenge_fbc8b0d5f2.svg)',
    },
    '.challenges-list li.cleaning:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/cleaning_4c92b78cc1.png)',
    },
    '.challenges-list li.cold-shower:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/cold_shower_5e5e23c34e.svg)',
    },
    '.challenges-list li.cooking:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/cooking_outside_f3f8ab1ba2.png)',
    },
    '.challenges-list li.connect-with-group:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/connect_with_group_9836ed1e08.png)',
    },
    '.challenges-list li.contact-listen:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/contact_listen_1b859128d7.png)',
    },
    '.challenges-list li.creative:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/creative_103eeebb63.svg)',
    },
    '.challenges-list li.fasting:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/fasting_82afd1caae.svg)',
    },
    '.challenges-list li.feast:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/feast_cd2d6aa5d9.png)',
    },
    '.challenges-list li.fix-something:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/fix_something_f4a8a2749a.png)',
    },
    '.challenges-list li.group-encourage:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/group_encourage_98b38634d4.png)',
    },
    '.challenges-list li.group-photographs:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/group_photographs_71379f58c9.png)',
    },
    '.challenges-list li.group-discussion:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/group_discussion_5c26218428.png)',
    },
    '.challenges-list li.overhead-press:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/overhead_press_fa94ab82c4.png)',
    },
    '.challenges-list li.planks:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/plank_9f45d9c9b0.png)',
    },
    '.challenges-list li.push-ups:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/push_up_6c662b1ee1.png)',
    },
    '.challenges-list li.quiet:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/quiet_428edecd8a.png)',
    },
    '.challenges-list li.reading:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/reading_7e58056e81.svg)',
    },
    '.challenges-list li.sit-ups:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/sit_up_08b609d2b6.png)',
    },
    '.challenges-list li.squats:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/squats_0b8e30c7b6.png)',
    },
    '.challenges-list li.stair-climb:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/stair_climb_c3534439ea.svg)',
    },
    '.challenges-list li.walk-or-run:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/walk_or_run_2551b317d9.png)',
    },
    '.challenges-list li.writing:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/writing_29d77f0a89.svg)',
    },
    '.challenges-list li.write-letter:before': {
      backgroundImage: 'url(https://xcc.tyny.app/uploads/write_letter_89fb3e10aa.png)',
    },
    '.challenges-list li.help:before': {
      height: '1.7em',
      width: '1.7em',
      minWidth: '1.7em',
      backgroundImage: 'url(https://xcc.tyny.app/uploads/help_93c0163871.png)',
    },
    '.course-video': {
      width: '100%',
      height: 'auto',
      maxHeight: 'calc(60vh)',
    },
    '.course-image': {
      width: '100%',
      height: 'auto',
    },
    '.text-center': {
      textAlign: 'center',
    },
    '.youtube-container': {
      position: 'relative',
      paddingBottom: '56.25%',
      paddingTop: '30px',
      height: '0',
      overflow: 'hidden',
    },
    '.youtube-container iframe, .youtube-container object, .youtube-container embed': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    }
  }
}
