import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LectureContext } from '../../providers/LectureProvider'
import { CourseContext } from '../../providers/CourseProvider'
import { isAfter, parse, parseISO } from 'date-fns'
import LectureNotYetPublished from './../LectureNotYetPublished'
import Grid from '@material-ui/core/Grid'
import ReactMarkdown from 'react-markdown'
import htmlParser from 'react-markdown/plugins/html-parser'
import HeadlineIcon from '@material-ui/icons/Description'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import getGlobalStyles from './globalStyles'
import HtmlToReact from 'html-to-react'
import { changeUserInput, saveUserInput, changeChallenges, completeChallenges } from '../../state/actions/courseUser'
import { useSelector, useDispatch } from 'react-redux'
import Link from './Parsers/Link'
import LinkToMap from './Parsers/LinkToMap'
import Textarea from './Parsers/Textarea'
import TextBox from './Parsers/TextBox'
import Checkbox from './Parsers/Checkbox'
import Radio from './Parsers/Radio'
import ChallengeBox from './Parsers/ChallengeBox'
import CompleteChallenges from './Parsers/CompleteChallenges'

const useStyles = makeStyles((theme) => ({
  '@global': getGlobalStyles(theme),
  lectureContainer: {
    fontSize: '1rem',
  },
  headlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function LectureContent () {
  const classes = useStyles()
  const { course } = useContext(CourseContext)
  const { section, lecture } = useContext(LectureContext)
  const { courseUser } = useSelector(state => state.courseUser)
  const dispatch = useDispatch()

  const _handleChange = async (e) => {
    e.persist()
    await dispatch(changeUserInput(e, courseUser.uid, course))
  }

  const _handleBlur = async (e) => {
    e.persist()
    await dispatch(saveUserInput(e, courseUser.uid, course))
  }

  if (!lecture) {
    return <React.Fragment>Loading lecture content...</React.Fragment>
  }

  const courseStart = parseISO(course.startDateIso)
  const publishAt = parse(section.publishAt, 'EEEE HH:mm:ss', courseStart)
  const sectionAvailable = course.master === true || isAfter(new Date(), publishAt)

  if (!sectionAvailable) {
    return <LectureNotYetPublished publishAt={publishAt}/>
  }

  // Order matters. Instructions are processed in the order they're defined
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
  const processingInstructions = [
    Link,
    LinkToMap(courseUser, course),
    ChallengeBox(courseUser, course, async (changedIndex, checked, percentage) => {
      await dispatch(changeChallenges(changedIndex, checked, percentage, courseUser.uid, course))
    }),
    CompleteChallenges(async () => {
      await dispatch(completeChallenges(courseUser.uid, course))
    }),
    Textarea(courseUser, course, _handleChange, _handleBlur),
    TextBox(courseUser, course, _handleChange, _handleBlur),
    Checkbox(courseUser, course, _handleChange),
    Radio(courseUser, course, _handleChange),
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true
      },
      processNode: processNodeDefinitions.processDefaultNode
    }
  ]

  const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions
  })

  return (
    <React.Fragment>
      <Grid className={classes.lectureContainer} container>
        <Grid xs={12} item>
          <Box className={classes.headlineContainer}>
            <Box mr={1}><HeadlineIcon/></Box>
            <Typography variant="h5">{lecture.title}</Typography>
          </Box>
          <Box mt={3}>
            <ReactMarkdown source={lecture.content} escapeHtml={false} astPlugins={[parseHtml]}/>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
