import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import NothingIsCertain from '../assets/Nothing-is-certain.jpg'
import ManOnMountainWithRainbow from '../assets/man-on-mountain-with-rainbow.jpg'
import TimeForYourOwnMINDBODYSOULAdventure from '../assets/Time-for-your-own-MIND-BODY-SOUL-adventure.jpg'
import FeedbackCard from './cards/FeedbackCard'

export default function Day21 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  WHO do you TRUST?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  HOW do YOU deal with the UNKNOWN?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={NothingIsCertain} className={classes.responsiveImage}
                   alt="Not much in life is certain but you can guarantee that things will never go exactly according to plan: allow for the unknown."/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  TRUST God from the bottom of your heart: don't try to figure out everything on your own.<br/>
                  Listen to God's voice in everything you do, everywhere you go; he's the one who will keep you on
                  track.
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="right" variant="h6">
                  Proverbs 3
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={ManOnMountainWithRainbow} className={classes.responsiveImage}
                   alt="Man on mountain with rainbow"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h5">
                  A PRAYER:
                </WhiteText>
              </Box>
              <Box mb={4}>
                <WhiteText align="center" variant="h5">
                  "Father God, I'm worried about ...
                </WhiteText>
              </Box>
              <Box mb={4}>
                <WhiteText align="center" variant="h5">
                  help me to trust you from the bottom of my heart.<br/>
                  Please keep me on track."
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={TimeForYourOwnMINDBODYSOULAdventure} className={classes.responsiveImage}
                   alt="Time for your own MIND BODY SOUL adventure"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
        <FeedbackCard classes={classes}/>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
