import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import LegsAndMap from '../assets/tims-legs-in-snow.jpg'
import QuoteDoWhatYouCan from '../assets/QUOTE-Do-what-you-can.jpg'

export default function Day69 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Picture a man sitting on his rock & thinking: The years pass.
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  He wonders where the time has gone. He regrets a life of sterility: He is a voyeur, an armchair
                  adventurer.
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  In the fear of making the wrong decision, he has made none.
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  - Robert L. Moore
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={LegsAndMap} className={classes.responsiveImage} alt="Legs and map"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  DO NOT BE PASSIVE
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="center" variant="h6">
                  "Choose LIFE so you and your family may LIVE!"
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Deuteronomy 30v19
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h6">
                  Walk for at least half an hour without looking at your phone or media device
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  ... observe and listen to what's around you...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  look for LIFE
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <i>Great things are done when men and mountains meet</i>
                </WhiteText>
                <WhiteText align="center" variant="h5">
                  - William Blake
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="center" variant="h6">
                  Join us:<br/>
                  XTREME<br/>
                  CHARACTER CHALLENGE<br/>
                  2022
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={QuoteDoWhatYouCan} className={classes.responsiveImage} alt="Quote: Do what you can"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropGrey}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
