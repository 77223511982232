import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { CourseContext } from './CourseProvider'

export const LectureContext = React.createContext()

export const LectureProvider = ({ children }) => {
  const [section, setSection] = useState(null)
  const [lecture, setLecture] = useState(null)
  const routeParams = useParams()
  const { course } = useContext(CourseContext)
  const { lectureId } = routeParams

  useEffect(() => {
    if (lectureId && course) {
      console.debug('try to fetch lecture from course data')
      const { courseSections } = course.courseType
      let section = null
      let lecture = null
      for (const cSection of courseSections) {
        const { courseLectures } = cSection
        for (const cLecture of courseLectures) {
          if (cLecture.id === lectureId) {
            section = cSection
            lecture = cLecture
            break
          }
        }
      }
      if (lecture) {
        console.debug('lecture fetched')
      }
      setSection(section)
      setLecture(lecture)
    } else {
      setSection(null)
      setLecture(null)
    }
  }, [lectureId, course])

  return (
    <LectureContext.Provider
      value={{
        section,
        lecture
      }}
    >
      {children}
    </LectureContext.Provider>
  )
}
