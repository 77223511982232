import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import PoppyHighKey from '../assets/poppy-high-key.png'
import PoppiesAndSoldier from '../assets/poppies-and-soldier.jpg'
import PoppiesPeopleAndCross from '../assets/poppies-people-and-cross.jpg'
import { Typography } from '@material-ui/core'

export default function RemembranceDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={PoppyHighKey} className={classes.responsiveImage} alt="Lest we forget"/>
              <div className={classes.imgOverlayBottom}>
                <Typography align="center" variant="h5" style={{ color: '#000' }}>
                  Lest We Forget
                </Typography>
              </div>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={PoppiesAndSoldier} className={classes.responsiveImage} alt="Poppies and soldier"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  In that day I will make a covenant for them with the beasts of the field, the birds in the sky and
                  the creatures that move along the ground. Bow and sword and battle I will abolish from the land, so
                  that all may lie down in safety.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h6">
                  Hosea 2:18
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  On a day where we remember those who have fallen in WAR:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  COMMIT YOURSELF TO BE A PEACE-MAKER
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  MAKE PEACE
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={PoppiesPeopleAndCross} className={classes.responsiveImage} alt="Poppies, people and cross"/>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
