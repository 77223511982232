import React, { useCallback, useContext/*, useContext*/ } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountIcon from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'
import app, { signOut } from '../../base'
import DoorIcon from '@material-ui/icons/MeetingRoom'
import CollectionsIcon from '@material-ui/icons/Collections'
// import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen'
import { makeStyles } from '@material-ui/core/styles'
import { /*useDispatch,*/ useSelector } from 'react-redux'
import UpdateIcon from '@material-ui/icons/SystemUpdate'
import Badge from '@material-ui/core/Badge'
import ChatIcon from '@material-ui/icons/Chat'
import { useApolloClient } from '@apollo/react-hooks'
// import { useIntercom } from 'react-use-intercom'
import { logEvent } from '../../analytics'
import useIsApp from '../../hooks/useIsApp'
// import { showiOSInstallPrompt } from '../../state/actions/app'
// import { InstallPromptContext } from '../../components/InstallPrompt'
// import Avatar from '@material-ui/core/Avatar'
import { AuthContext } from '../../providers/AuthProvider'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    marginRight: theme.spacing(2)
  },
  accountIcon: {
    fontSize: 36,
    [theme.breakpoints.up('md')]: {
      fontSize: 45,
    }
  }
}))

export default function UserMenu () {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const apolloClient = useApolloClient()
  const { unreadMessagesCount } = useSelector(state => state.app)
  // const { show, shutdown, boot } = useIntercom()
  const isApp = useIsApp()
  const { /*intercomUser,*/ user } = useContext(AuthContext)
  // const { installPromptEvent } = useContext(InstallPromptContext)
  // const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // const showReactiOSPWAPrompt = () => {
  //   const isiOS = /iphone|ipad|ipod/.test(
  //     window.navigator.userAgent.toLowerCase()
  //   )
  //   const isiPadOS =
  //     navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
  //
  //   return isiOS || isiPadOS
  // }

  // const handleInstall = () => {
  //   if (installPromptEvent) {
  //     // Show the modal add to home screen dialog
  //     installPromptEvent.prompt()
  //     // Wait for the user to respond to the prompt
  //     installPromptEvent.userChoice.then(choice => {
  //       if (choice.outcome === 'accepted') {
  //         //console.log('User accepted the A2HS prompt')
  //         logEvent('select_content', {
  //           content_type: 'installation',
  //           content_id: 'android'
  //         })
  //         window.location.reload()
  //       } else {
  //         //console.log('User dismissed the A2HS prompt')
  //       }
  //       // Clear the saved prompt since it can't be used again
  //       //installPromptEvent = null;
  //     })
  //   } else if (showReactiOSPWAPrompt()) {
  //     dispatch(showiOSInstallPrompt())
  //   }
  // }

  const signOutCallback = useCallback(() => {
    signOut(apolloClient/*, shutdown, boot*/)
  }, [/*shutdown, boot, */apolloClient])

  const contact = useCallback(() => {
    // show()
    window.open('mailto:info@4muk.com?subject=App%20question', '_blank')
    // Send event to GA
    logEvent('select_content', {
      content_type: 'get_in_touch',
      content_id: 'get_in_touch'
    })
    // Close user menu
    setAnchorEl(null)
  }, [/*show*/])

  const checkForUpdates = useCallback(() => {
    // Send event to GA
    logEvent('select_content', {
      content_type: 'check_for_updates',
      content_id: 'check_for_updates'
    })
    window.location.reload()
  }, [])

  return (
    <React.Fragment>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="open user menu"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <Badge badgeContent={unreadMessagesCount} color="secondary">
          {/*<>*/}
          {/*  {*/}
          {/*    intercomUser && intercomUser.avatar &&*/}
          {/*    <Avatar src={intercomUser.avatar}/>*/}
          {/*  }*/}
          {/*  {*/}
          {/*    (!intercomUser || !intercomUser.avatar) &&*/}
          {/*    <AccountIcon className={classes.accountIcon}/>*/}
          {/*  }*/}
          {/*</>*/}
          <AccountIcon className={classes.accountIcon}/>
        </Badge>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={contact}>
          <Badge badgeContent={unreadMessagesCount} color="secondary">
            <ChatIcon className={classes.menuIcon}/>
            Get in touch
          </Badge>
        </MenuItem>
        {
          isApp &&
          <MenuItem onClick={checkForUpdates}>
            <UpdateIcon className={classes.menuIcon}/>
            Check for updates
          </MenuItem>
        }
        {/*{*/}
        {/*  !isApp &&*/}
        {/*  <MenuItem onClick={handleInstall}>*/}
        {/*    <AddToHomeScreenIcon className={classes.menuIcon}/>*/}
        {/*    Install App*/}
        {/*  </MenuItem>*/}
        {/*}*/}
        {
          user && user.isReelsAdmin &&
          <MenuItem component={NavLink} to={'/reels-admin'}>
            <CollectionsIcon className={classes.menuIcon}/>
            REELS Admin
          </MenuItem>
        }
        {
          app.auth().currentUser &&
          <MenuItem onClick={signOutCallback}>
            <DoorIcon className={classes.menuIcon}/>
            Sign out
          </MenuItem>
        }
      </Menu>
    </React.Fragment>
  )
}
