import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManCarryingSnow from '../assets/Man-carrying-snow.jpg'
import SunriseOverLoch from '../assets/Sunrise-over-loch.jpg'

export default function Day41 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  DOING<br/>
                  WHAT<br/>
                  YOU<br/>
                  WANT<br/>
                  TODAY?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  WE OFTEN HAVE<br/>
                  TO DO LOTS OF<br/>
                  THINGS WE DON'T<br/>
                  WANT<br/>
                  TO DO
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManCarryingSnow} className={classes.responsiveImage}
                   alt="Man carrying snow up the mountain"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  HOW ARE YOU WITH THAT?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  DO YOU DO THAT STUFF WITH A GROAN OR A SMILE?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  YOU CAN'T HAVE EVERYTHING ON YOUR TERMS.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  GET USED TO IT.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  A PRAYER:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  God, please help me to do the jobs that I don't want to do or try to avoid.<br/>
                  Help me to be a blessing to others.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  Amen.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={SunriseOverLoch} className={classes.responsiveImage}
                   alt="Sunrise over loch"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
