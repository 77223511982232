import React, { useState } from 'react'
import CardStack from '../components/CardStack'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import Backdrop from '@material-ui/core/Backdrop'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import CardLayout from '../layouts/CardLayout/CardLayout'
// import WildernessRedSquare from "../assets/XCC-Wilderness-Red-Square.jpg";
import ReelsSquare from '../assets/REELS-square.jpg'
import { isMobile } from 'react-device-detect'
import Button from '@material-ui/core/Button'
import { NavLink } from 'react-router-dom'
import styles from '../styles'
import AddToHomeScreenBanner from '../components/AddToHomeScreenBanner'
import useIsApp from '../hooks/useIsApp'
import { logEvent } from '../analytics'
//import REMOTE from "../assets/REMOTE.jpg";
//import TEAMS from '../assets/TEAMS.jpg'

const useStyles = makeStyles((theme) =>
    Object.assign(
      {},
      {
        backdrop: {
          //zIndex: theme.zIndex.drawer + 1,
          backgroundColor: '#333335',
        },
        video: {
          width: '100%',
        },
      },
      styles(theme)
    )
  //Other styles for the card components and images are found in the styles.js
)

export default function LandingPage () {
  const classes = useStyles()
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const [deviceOrientation, setDeviceOrientation] = useState('')
  const isApp = useIsApp()

  const onOrientationChange = (orientation, type, angle) => {
    setDeviceOrientation(orientation)
  }

  const onVideoPlaying = () => {
    setVideoIsPlaying(true)
    logEvent('select_content', {
      content_type: 'video',
      content_id: 'How-to-use-XCC-App',
    })
  }

  const onVideoPause = () => {
    setVideoIsPlaying(false)
  }

  if (isMobile && deviceOrientation === 'landscape' && !videoIsPlaying) {
    return (
      <DeviceOrientation lockOrientation={'portrait'} onOrientationChange={onOrientationChange}>
        <Orientation orientation="landscape" alwaysRender={false}>
          <Backdrop open={true} transitionDuration={0} className={classes.backdrop}>
            <Typography variant="h5">Please rotate your screen to portrait mode</Typography>
          </Backdrop>
        </Orientation>
      </DeviceOrientation>
    )
  }

  return (
    <div>
      {isMobile && !isApp && <AddToHomeScreenBanner/>}
      <CardLayout>
        <DeviceOrientation lockOrientation={'portrait'} onOrientationChange={onOrientationChange}>
          <Orientation orientation="portrait">
            <CardStack id="landing-page">
              <Card isWithinCardStack={true}>
                <Box className={classes.textInTheMiddle}>
                  <Typography style={{ alignSelf: 'flex-start', fontFamily: '"Roboto Condensed", sans-serif' }}>
                    Hi!
                  </Typography>
                  <br/>
                  <WhiteText>
                    Welcome to the browser-based XCC App. If you read this on a mobile device, you'll be asked to
                    install the App directly to your Homescreen. Be bold and up-to-date about anything XCC-related.
                    <br/><br/>
                    If you are on a bigger screen like your PC or Laptop, don't worry, the App will work exactly the
                    same and adopt to your screen size.
                    <br/><br/>
                    On the next card you'll find an introduction video how things work with the App.
                    <br/><br/>
                    Then REELS offers daily XCC-related inspiration ... thoughts, images, ideas and challenges!
                    <br/><br/>
                    Stay adventurous, The XCC Team
                  </WhiteText>
                </Box>
              </Card>
              <Card isWithinCardStack={true}>
                {/*<Box mt={3}>*/}
                {/*  <Typography variant="h4" gutterBottom={true} align="center" className={classes.heading}>*/}
                {/*    The Stories*/}
                {/*  </Typography>*/}
                {/*</Box>*/}
                {/*<Box mt={6}>*/}
                {/*  <RegularText align="center">*/}
                {/*    OK, You've done an XTREME Character Challenge before - so why would you want to do XCC Remote?*/}
                {/*    Listen up ...*/}
                {/*  </RegularText>*/}
                {/*</Box>*/}
                <Box className={classes.textInTheMiddle}>
                  <video
                    className={classes.video}
                    width="405"
                    height="720"
                    preload="auto"
                    poster="https://firebasestorage.googleapis.com/v0/b/xtremecharacterchallenge.appspot.com/o/videos%2FXCC-APP-Cover.jpeg?alt=media&token=c3c5a342-6ffd-4808-8751-31cc73ea3f85"
                    onPlaying={onVideoPlaying}
                    onPause={onVideoPause}
                    crossOrigin="anonymous"
                    controls
                  >
                    <source
                      src="https://firebasestorage.googleapis.com/v0/b/xtremecharacterchallenge.appspot.com/o/videos%2FXCC-APP.mp4?alt=media&token=854db5f9-e8a4-407f-84d0-7bb7f51621d5"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              </Card>
              <Card isWithinCardStack={true}>
                <Box className={classes.centeredBox}>
                  <img
                    src={ReelsSquare}
                    className={classes.responsiveImage}
                    style={{ maxWidth: '70%' }}
                    alt="REELS logo"
                  />
                </Box>
                <Box className={classes.textInTheMiddle}>
                  <WhiteText align="center">
                    REELS offers DAILY updates of thoughts, images, ideas and challenges that should give you a boost
                    and a nudge towards the sort of life you wanted to live after your first XCC!!
                  </WhiteText>
                </Box>
                <Box className={classes.callToActionButtonContainer}>
                  <Button variant="outlined" size="large" component={NavLink} to="/reels" fullWidth={true}>
                    Start now!
                  </Button>
                </Box>
              </Card>
              {/*<Card isWithinCardStack={true} className={classes.redCard}>*/}
              {/*  <Box className={classes.centeredBox}>*/}
              {/*    <img*/}
              {/*      src={WildernessRedSquare}*/}
              {/*      className={classes.responsiveImage}*/}
              {/*      style={{ maxWidth: "90%" }}*/}
              {/*      alt="WILDERNESS logo"*/}
              {/*    />*/}
              {/*  </Box>*/}
              {/*  <Box className={classes.textInTheMiddle}>*/}
              {/*    <WhiteText align="center">*/}
              {/*      UPCOMING*/}
              {/*      <br />*/}
              {/*      13-16 Oct 2022 Wales & 10-13 Nov 2022 Dartmoor*/}
              {/*    </WhiteText>*/}
              {/*  </Box>*/}
              {/*  <Box className={classes.callToActionButtonContainer}>*/}
              {/*    <Button*/}
              {/*      variant="outlined"*/}
              {/*      href="https://www.xtremecharacterchallenge.com/shop/#tags=13341"*/}
              {/*      target="_blank"*/}
              {/*      rel="noopener"*/}
              {/*      size="large"*/}
              {/*      fullWidth={true}*/}
              {/*    >*/}
              {/*      Join us!*/}
              {/*    </Button>*/}
              {/*  </Box>*/}
              {/*</Card>*/}
              {/*<Card isWithinCardStack={true} className={classes.redCard}>*/}
              {/*  <Box className={classes.centeredBox} style={{ display: 'flex', flex: 1, alignItems: 'center' }}>*/}
              {/*    <img*/}
              {/*      src={TEAMS}*/}
              {/*      className={classes.responsiveImage}*/}
              {/*      style={{ maxHeight: '60vh' }}*/}
              {/*      alt="TEAMS logo"*/}
              {/*    />*/}
              {/*  </Box>*/}
              {/*  <Box className={classes.callToActionButtonContainer} style={{ flex: 0 }}>*/}
              {/*    <Button*/}
              {/*      variant="outlined"*/}
              {/*      href="https://www.xtremecharacterchallenge.com/shop/product/74411/xcc-teams/"*/}
              {/*      size="large"*/}
              {/*      fullWidth={true}*/}
              {/*    >*/}
              {/*      Let's go!*/}
              {/*    </Button>*/}
              {/*  </Box>*/}
              {/*</Card>*/}
              {/*<Card isWithinCardStack={true} className={classes.redCard}>*/}
              {/*  <Box className={classes.centeredBox} style={{ display: 'flex', flex: 1, alignItems: 'center' }}>*/}
              {/*    <img*/}
              {/*      src={REMOTE}*/}
              {/*      className={classes.responsiveImage}*/}
              {/*      style={{ maxHeight: '60vh' }}*/}
              {/*      alt="REMOTE logo"*/}
              {/*    />*/}
              {/*  </Box>*/}
              {/*  <Box className={classes.callToActionButtonContainer} style={{ flex: 0 }}>*/}
              {/*    <Button*/}
              {/*      variant="outlined"*/}
              {/*      href="https://www.xtremecharacterchallenge.com/shop/product/68239/xcc-remote/"*/}
              {/*      size="large"*/}
              {/*      fullWidth={true}*/}
              {/*    >*/}
              {/*      Sign up now!*/}
              {/*    </Button>*/}
              {/*  </Box>*/}
              {/*</Card>*/}
              <Card isWithinCardStack={true}>
                <Box className={classes.textInTheMiddle}>
                  <WhiteText align="center" variant="h6">
                    CHECK IN ANOTHER TIME FOR MORE
                  </WhiteText>
                </Box>
              </Card>
            </CardStack>
          </Orientation>
        </DeviceOrientation>
      </CardLayout>
    </div>
  )
}
