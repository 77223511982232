import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import EnglandFlag from '../assets/england-flag.png'
import EventDatesEngland from './event-dates/EventDatesEngland'

export default function Day43 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  WHAT'S<br/>
                  YOUR<br/>
                  BEST<br/>
                  JOKE?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h6">
                  How many tickles does it take to make an octopus laugh?
                </WhiteText>
              </Box>
              <Box mt={20}>
                <WhiteText align="right" variant="h6">
                  Ten tickles
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h6">
                  <mark>What do you call someone with no body and no nose?</mark>
                </WhiteText>
              </Box>
              <Box mt={20}>
                <WhiteText align="right" variant="h6">
                  <mark>Nobody knows</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h6">
                  <mark>I decided to sell my vacuum cleaner</mark>
                </WhiteText>
              </Box>
              <Box mt={20}>
                <WhiteText align="right" variant="h6">
                  <mark>it was just gathering dust!</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  DON'T TAKE YOURSELF TOO SERIOUSLY. TELL A JOKE OR TWO!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={EnglandFlag} className={classes.responsiveImage} alt="England flag"/>
              </Box>
              <Box mt={6}>
                <WhiteText align="center">
                  <EventDatesEngland/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
