import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText' 
import Img1 from "../assets/man-looking-at-stars.jpg"
import Img2 from "../assets/blue-clouds.jpg"




export default function Day98() {
    const render = (classes) => {
        return (
            <React.Fragment>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                <b>“There are two ways to live your life. One is as though nothing is a miracle. The
                                  other is as though everything is.”</b><br/><br/>
                                </WhiteText>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                - Albert Einstein               
                                </WhiteText>
                          
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em'}}>
                                Have you lost the awe in the ordinary?
                                </WhiteText>      
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                        <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                “There is nothing that God hath established in a constant course of nature, and which
                                  therefore is done every day, but would seem a miracle, and exercise our admiration,
                                  if were done but once.” <br/> <br/>
                                </WhiteText>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    - John Donne                               
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                <strong><u>Today’s Challenge:</u></strong><br/><br/>
                                Appreciate something ordinary today.<br/> <br/>
                                Breath. Nature. Laughter. Food. Walking. Water.
                                </WhiteText>
                                
                            </Box>
                        </Box>
                    </Card>
                </div>
                
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '100%'}}>
                            <Box mt={2} style={{width: '100%'}}>
                                <img src={Img1} className={classes.responsiveImage}
                                alt="Amazing galaxy of stars"/>
                            </Box>
                           
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '100%'}}>
                            <Box mt={2} style={{width: '100%'}}>
                                <img src={Img2} className={classes.responsiveImage}
                                alt="Beautiful clouds on a sunny day"/>
                            </Box>
                           
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <Box>
                                <WhiteText align="left" variant="h3" style={{lineHeight: '1.2em'}}>
                                    <b>LIFE IS A<br/>
                                    MIRACLE.</b>
                                </WhiteText>                                           
                            </Box>                               
                        </Box>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
