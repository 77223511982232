import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'

export default function Day97() {
    const render = (classes) => {
        return (
            <React.Fragment>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em', fontSize:'2.6em'}}>
                                Don’t tell me what you believe...<br/><br/><br/>
                                Show me your actions and I’ll tell YOU what you believe!
                                </WhiteText>
                          
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                What do your actions say about you?
                                </WhiteText>
                                
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                               <b> How you spend your money.<br/><br/>
                                How you speak of those you don’t like or disagree with.<br/><br/>
                                What you do to unwind.<br/><br/>
                                What you do with your free time.</b>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                Every good business takes inventory. They analyse what is good and should be kept, and
                                  what is bad should be thrown away.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                <strong><u>Today’s Challenge:</u></strong><br/><br/>
                                Set an alarm for 9am, 12pm, 3pm and 6pm.<br/> <br/>                       
                                At those times ask yourself:<br/> 
                                1. How have I acted so far today?<br/>
                                2. Have I been kind?<br/>
                                3. Have I been trying to distract myself? If so, what from?<br/>
                                4. What plans am I scheming? Are they good?
                                </WhiteText>
                                
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <Box>
                                <WhiteText align="center" variant="h3" style={{lineHeight: '1.2em', color:'red'}}>
                                    <b><u>What do you need to get rid of?</u></b>
                                </WhiteText>                                           
                            </Box>                               
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <Box>
                                <WhiteText align="left" variant="h3" style={{lineHeight: '1.2em'}}>
                                    <b>COME AWAY<br/>
                                     WITH US FOR <br/>
                                     YOUR ANNUAL <br/>AUDIT.</b>                            
                                </WhiteText>                                           
                            </Box>                               
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
