import React, { useContext, useEffect } from 'react'
import { CourseContext } from '../../providers/CourseProvider'
import precache from './precache.json'
import { sanitizeCourseType } from '../CourseNavigator'
import PageVisibilityAPI from '../../apis/PageVisibilityAPI'

const CourseCache = ({ children }) => {
  const { course } = useContext(CourseContext)

  useEffect(() => {
    const pageVisibilityAPI = PageVisibilityAPI()
    const _precache = async (course) => {
      const courseType = sanitizeCourseType(course)
      if (precache[courseType]) {
        const caches = precache[courseType]
        console.debug(`Ensuring cache for course ${courseType}`)
        for (const cacheName in caches) {
          if (!Object.prototype.hasOwnProperty.call(caches, cacheName)) continue
          const requests = caches[cacheName]

          await window.caches.open(cacheName)
            .then(async (cache) => {
              const cacheKeys = await cache.keys().then(keys => keys.map(k => k.url))
              for (const request of requests) {
                if (!cacheKeys.includes(request)) {
                  //console.debug(`Fetching: ${request}`)
                  await cache.add(request)
                    .then(() => console.debug(`Fetched: ${request}`))
                } else {
                  //console.debug(`Cached: ${request}`)
                }
              }
            })
        }
        console.debug(`Content for course ${courseType} was cached`)
      }
    }
    const _listener = () => {
      if (pageVisibilityAPI) {
        const { hidden } = pageVisibilityAPI
        if (document[hidden]) {
          // App goes in background
          _precache(course)
        }
      }
    }
    if (course) {
      if (pageVisibilityAPI) {
        const { visibilityChange } = pageVisibilityAPI
        document.addEventListener(visibilityChange, _listener, false)
      } else {
        // Do it immediately
        _precache(course)
      }
    }
    return () => {
      if (pageVisibilityAPI) {
        const { visibilityChange } = pageVisibilityAPI
        document.removeEventListener(visibilityChange, _listener, false)
      }
    }
  }, [course])

  return (
    <React.Fragment>{children}</React.Fragment>
  )
}

export default CourseCache
