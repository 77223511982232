import TeamsLogoGrey from './assets/XCC-Teams-Grey.jpg'
import WildernessLogoRed from './assets/XCC-Wilderness-Red.jpg'
import WildernessLogoGrey from './assets/XCC-Wilderness-Grey.jpg'
import RemoteLogoGrey from './assets/XCC-Remote-Grey.jpg'
import ReelsLogoGrey from './assets/REELS.jpg'
import TakeCareOfYou from './assets/Take-care-of-you.jpg'
import KeysToFREEDOM from './assets/Keys-to-FREEDOM.png'
import AlphaOnline from './assets/Pause-Netflix-Try-Alpha-Online.jpg'
import MenWithGoodIntentions from './assets/men-with-good-intentions.jpg'
import SantaClausFun from './assets/santa-claus-fun.jpg'
import ChristmasTree from './assets/christmas-tree-background.jpg'
import ChristmasStar from './assets/christmas-star-background.jpg'
import ChristmasCard from './assets/christmas-card-2020.jpg'
import ChristmasSignatureCard1 from './assets/christmas-signatures-card-1.jpg'
import ChristmasSignatureCard2 from './assets/christmas-signatures-card-2.jpg'
import ChristmasSignatureCard3 from './assets/christmas-signatures-card-3.jpg'
import XCCChristmasTree from './assets/xcc-christmas-tree.jpg'
import Dark from './assets/dark.jpg'
import Earth from './assets/earth.jpg'
import Cross from './assets/cross.jpg'
import AprilFirst from './assets/april-1-calendar.jpg'
import FatherAndSon from './assets/jhonatan-saavedra-perales-LILq0x0T-DM-unsplash.jpg'
import KidCrying from './assets/kat-j-NPmR0RblyhQ-unsplash.jpg'
import REMOTE from './assets/REMOTE.jpg'
import TEAMS from './assets/TEAMS.jpg'
import LogoText from './assets/Logo-Text.jpg'
import XCCPoster2021 from './assets/XCC-Poster-2021.jpg'
import ManAtNight from './assets/man-at-night.jpg'
import ManMud from './assets/Man-with-mudd-in-his-face.jpg'
import HardestClimb from './assets/hardest-climb.jpg'
import LogoAndFlags from './assets/logo-and-flags.jpg'
import ClimbingHill from './assets/Climbing-hill.jpg'
import Tents from './assets/IMG_3272.JPG'
import ManSmiling from './assets/DSCF1405.JPG'
import MenWalkingPathRainbow from './assets/IMG_7899.JPEG'
import ManSteepSlope from './assets/35-IMG_5654.jpg'
import AllComingDownMountain from './assets/DJI_0173.JPG'

export default function (theme) {
  return {
    redCard: {
      backgroundColor: theme.palette.secondary.main,
    },
    blackCard: {
      backgroundColor: "#0E0E0E",
    },
    realBlackCard: {
      backgroundColor: "#000",
    },
    fullRemoteBackdropRed: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: `url('${REMOTE}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullRemoteBackdropGrey: {
      backgroundImage: `url('${RemoteLogoGrey}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullTeamsBackdropRed: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: `url('${TEAMS}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullTeamsBackdropGrey: {
      backgroundImage: `url('${TeamsLogoGrey}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullWildernessBackdropRed: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: `url('${WildernessLogoRed}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullWildernessBackdropGrey: {
      backgroundImage: `url('${WildernessLogoGrey}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullXCCLogoBackdrop: {
      backgroundImage: `url('${LogoText}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullReelsLogoBackdrop: {
      backgroundImage: `url('${ReelsLogoGrey}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundTakeCareOfYou: {
      backgroundImage: `url('${TakeCareOfYou}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullKeysToFREEDOMBackdrop: {
      backgroundImage: `url('${KeysToFREEDOM}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "60%",
      backgroundColor: "#393536",
    },
    fullAlphaOnlineBackdrop: {
      backgroundImage: `url('${AlphaOnline}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "86%",
      backgroundColor: "#fff",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullMenWithGoodIntentionsBackdrop: {
      backgroundImage: `url('${MenWithGoodIntentions}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "120%",
      backgroundColor: "#161616",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullSantaClausFunBackdrop: {
      backgroundImage: `url('${SantaClausFun}')`,
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: "#cc001f",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullChristmasTreeBackdrop: {
      backgroundImage: `url('${ChristmasTree}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "160%",
      backgroundColor: "#560100",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullChristmasStarBackdrop: {
      backgroundImage: `url('${ChristmasStar}')`,
      backgroundPosition: "left top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "200%",
      backgroundColor: "#090C0F",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullChristmasCardBackdrop: {
      backgroundImage: `url('${ChristmasCard}')`,
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: "#f7f7f7",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullChristmasSignatureCard1Backdrop: {
      backgroundImage: `url('${ChristmasSignatureCard1}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: theme.palette.secondary.main,

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullChristmasSignatureCard2Backdrop: {
      backgroundImage: `url('${ChristmasSignatureCard2}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: "#3c3834",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullChristmasSignatureCard3Backdrop: {
      backgroundImage: `url('${ChristmasSignatureCard3}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: theme.palette.secondary.main,

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullXCCChristmasTreeBackdrop: {
      backgroundImage: `url('${XCCChristmasTree}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "80%",
      backgroundColor: "#e9edf9",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullDarkBackdrop: {
      backgroundImage: `url('${Dark}')`,
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: "#000",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullEarthBackdrop: {
      backgroundImage: `url('${Earth}')`,
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: "#000",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullCrossBackdrop: {
      backgroundImage: `url('${Cross}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#000",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullAprilFirstBackdrop: {
      backgroundImage: `url('${AprilFirst}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundColor: "#3F4042",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullFatherAndSonBackdrop: {
      backgroundImage: `url('${FatherAndSon}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#F2B761",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullKidCryingBackdrop: {
      backgroundImage: `url('${KidCrying}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#000",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    fullPoster2021Backdrop: {
      backgroundImage: `url('${XCCPoster2021}')`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundColor: '#333234',
    },
    backdropHeadingContainer: {
      justifyContent: "flex-end",
    },
    verticalCenteredBox: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",

      "& mark": {
        backgroundColor: "#fffffe",
      },
    },
    video: {
      width: "100%",
      height: "auto",
      maxHeight: "calc(60vh)",
    },
    responsiveImage: {
      width: "100%",
      height: "auto",
    },
    greyHeadlineBox: {
      backgroundColor: theme.palette.background.paper,
      position: "absolute",
      width: "100%",
      top: theme.spacing(8),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    centeredBox: {
      textAlign: "center",
    },
    textInTheMiddle: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    callToActionButtonContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    imgOverlayBottom: {
      position: "relative",
      backgroundColor: "#fff",
    },
    backgroundManAtNight: {
      backgroundImage: `url('${ManAtNight}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundManMud: {
      backgroundImage: `url('${ManMud}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundHardestClimb: {
      backgroundImage: `url('${HardestClimb}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "80%",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundLogoAndFlags: {
      backgroundImage: `url('${LogoAndFlags}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundClimbingHill: {
      backgroundImage: `url('${ClimbingHill}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundTents: {
      backgroundImage: `url('${Tents}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundManSmiling: {
      backgroundImage: `url('${ManSmiling}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundMenWalkingPathRainbow: {
      backgroundImage: `url('${MenWalkingPathRainbow}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundManSteepSlope: {
      backgroundImage: `url('${ManSteepSlope}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
    backgroundAllComingDownMountain: {
      backgroundImage: `url('${AllComingDownMountain}')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      '@global': {
        '> .MuiBox-root': {
          margin: '0',
        },
      },
    },
  };
}
