import React, { useEffect, useState, useMemo } from 'react'
import app from '../base.js'
import { logEvent } from '../analytics'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GET_USER } from '../graphql/queries'
import { useApolloClient } from '@apollo/react-hooks'
// import { useIntercom } from 'react-use-intercom'
// import packageJson from '../../package.json'
import { useSelector } from 'react-redux'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [fireUser, setFireUser] = useState(null)
  // const [intercomUser, setIntercomUser] = useState(null)
  const [pending, setPending] = useState(true)
  const { signUp } = useSelector(state => state.app)
  // const { update } = useIntercom()
  const apolloClient = useApolloClient()

  const memoizedFetchAppUser = useMemo(() => {
      return (fetchPolicy = 'cache-first') => {
        if (fireUser) {
          const { uid } = fireUser

          console.debug('try to fetch app user')
          apolloClient
            .query({ query: GET_USER(uid), fetchPolicy })
            .then(async (result) => {
              const appUser = result.data.appUser
              if (appUser) {
                console.debug('app user fetched', result)
                return appUser
              } else {
                console.debug('app user missing - going to create one', result)
                const { firstName, lastName } = signUp
                const createAppUser = app.functions().httpsCallable('createAppUser')
                return createAppUser({ firstName, lastName })
                  .then(result => {
                    fireUser.sendEmailVerification()
                    return result
                  })
                  .then(result => result.data)
                  .catch(err => {
                    logEvent('exception', {
                      description: `Could not create app user: ${err.message}`,
                      fatal: true
                    })
                  })
              }
            })
            .then(appUser => {
              setUser(appUser)
              //  Update Intercom user
              // update({
              //   userId: appUser.uid,
              //   email: appUser.email,
              //   name: [appUser.firstName, appUser.lastName].join(' '),
              //   //hideDefaultLauncher: true,
              //   customAttributes: {
              //     team_leader: appUser.isTeamLeader,
              //     app_version: packageJson.version,
              //     firstName: appUser.firstName,
              //     surname: appUser.lastName,
              //   },
              // })
            })
            .catch(err => {
              logEvent('exception', {
                description: `Could not fetch app user: ${err.message}`,
                fatal: true
              })
            })

          /**
           * Start in parallel to AppUser!
           */
          // const getIntercomContact = app.functions().httpsCallable('getIntercomContact')
          // getIntercomContact()
          //   .then(result => {
          //     setIntercomUser(result.data)
          //   })
          //   .catch(err => {
          //     logEvent('exception', {
          //       description: `Could not fetch Intercom contact: ${err.message}`
          //     })
          //   })

        } else {
          // Sign out
          setUser(null)
          // setIntercomUser(null)
        }
      }
    }, [fireUser, /*update, */apolloClient, signUp]
  )

  useEffect(() => {
    app.auth().onAuthStateChanged((u) => {
      setFireUser(u)
      setPending(false)
    })
  }, [])

  useEffect(() => {
    memoizedFetchAppUser()
  }, [memoizedFetchAppUser])

  if (pending) {
    return (
      <Backdrop open={true} transitionDuration={0}>
        <CircularProgress color="secondary"/>
      </Backdrop>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        fireUser,
        user,
        /*intercomUser,*/
        refreshUser: memoizedFetchAppUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
