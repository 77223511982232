import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TeamInRiverCloseUp from '../assets/team-river-snow-close-up.jpg'
import QUOTEMenAndMountains from '../assets/QUOTE-Men-and-mountains.jpg'
import ScotlandFlag from '../assets/scotland-flag.jpg'
import EventDatesScotland from './event-dates/EventDatesScotland'

export default function Day33 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em', fontWeight: 'bold' }}>
                  ADVENTURE<br/>
                  IS<br/>
                  GOOD<br/>
                  FOR<br/>
                  YOU
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={TeamInRiverCloseUp} className={classes.responsiveImage}
                   alt="Team in river"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em', fontWeight: 'bold' }}>
                  SO IS STANDING<br/>
                  IN A BROWN RIVER...<br/>
                  IN THE SNOW...<br/>
                  IN YOUR PANTS!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  WHAT'S YOUR NEXT ADVENTURE GOING TO BE?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h4">
                  WHY NOT COME AWAY WITH US?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <i>
                    "I'm just one normal guy who needed this, and there are billions of other normal guys out there. I
                    can guarantee they need this too; for each and every one of their own reasons"
                  </i>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  T.H.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={QUOTEMenAndMountains} className={classes.responsiveImage}
                   alt="Great things are done when men and mountains meet"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Look at your diary & plan an adventure
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={ScotlandFlag} className={classes.responsiveImage} alt="Scotland flag"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  TAKE SOME<br/>
                  TIME OUT TO STOP:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  <EventDatesScotland onlyUpcoming={true}/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
