import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'

export default function SaturdayBeforeFathersDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  “In some areas <b>fatherlessness</b> has reached such high levels that they are virtual "<b>men
                  deserts</b>"”
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  - BBC
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  1.1 million children in the UK are growing up without a father in their lives -
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  - (Centre for Social Justice 2017)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  76% of young men in prison in England and Wales had an absent father
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  - (Prison Reform Trust 2013)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b><u>THERE IS A PANDEMIC OF LADS WITHOUT DADS</u></b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="center" variant="h6" style={{ lineHeight: '1.2em' }}>
                  <b>
                    This is a male problem.<br/>
                    We must own it. Let us be<br/>
                    part of the solution.
                  </b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <b>Today's Challenge:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Get out there and help. Mentor those younger than you, and lead others to do the same. Look out for
                  the fatherless in Society, be part of the solution.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Religion that God our Father accepts as pure and faultless is this: to look after orphans...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  - James 1:27 (NIV)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>PRAYER:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  God, help us be a light in the darkness, help me look outside of my own life to those that might be in
                  need.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
