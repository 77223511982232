import { get } from 'lodash-es'
import React from 'react'

export default (courseUser, course, handleChange) => {
  return {
    // Custom <input type="checkbox"> processing
    shouldProcessNode: function (node) {
      return get(node, 'name') === 'input' && get(node, 'attribs.type') === 'checkbox'
    },
    processNode: function (node, children) {
      const name = node.attribs.name
      return (
        <input
          {...node.attribs}
          defaultChecked={get(courseUser, ['courses', course.id, 'inputs', name])}
          onChange={handleChange}
        />
      )
    }
  }
}
