import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ThreeGuysTrekking from '../assets/3-guys-trekking.jpg'
import ScotlandFlag from '../assets/scotland-flag.jpg'
import FeedbackCard from './cards/FeedbackCard'
import EventDatesScotland from './event-dates/EventDatesScotland'

export default function Day28 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <mark>
                    There is a God in heaven who reveals mysteries.
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <mark>Daniel 2:28</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em', fontWeight: 'bold' }}>
                  DO<br/>
                  YOU<br/>
                  BELIEVE<br/>
                  THE<br/>
                  WORDS<br/>
                  ON<br/>
                  THE<br/>
                  LAST<br/>
                  CARD?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  THEN WHY<br/>
                  ARE YOU<br/>
                  SO OBSESSED<br/>
                  TO KNOW<br/>
                  ALL THE<br/>
                  ANSWERS?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h3">
                  YOU
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h3">
                  WILL
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h3">
                  SEE
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={ScotlandFlag} className={classes.responsiveImage} alt="Scotland flag"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  TAKE SOME<br/>
                  TIME OUT TO STOP:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  <EventDatesScotland onlyUpcoming={true}/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ThreeGuysTrekking} className={classes.responsiveImage}
                   alt="Three guys trekking"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropRed}/>
        </div>
        <FeedbackCard classes={classes}/>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
