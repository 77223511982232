import { useEffect, useState } from 'react'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import app from '../base'
import { get, concat } from 'lodash-es'
import { logEvent } from '../analytics'
import Day1 from '../reels/Day1'
import Day2 from '../reels/Day2'
import Day3 from '../reels/Day3'
import Day4 from '../reels/Day4'
import Day5 from '../reels/Day5'
import Day6 from '../reels/Day6'
import Day7 from '../reels/Day7'
import Day8 from '../reels/Day8'
import Day9 from '../reels/Day9'
import Day10 from '../reels/Day10'
import Day11 from '../reels/Day11'
import Day12 from '../reels/Day12'
import Day13 from '../reels/Day13'
import Day14 from '../reels/Day14'
import Day15 from '../reels/Day15'
import Day16 from '../reels/Day16'
import Day17 from '../reels/Day17'
import Day18 from '../reels/Day18'
import Day19 from '../reels/Day19'
import Day20 from '../reels/Day20'
import Day21 from '../reels/Day21'
import Day22 from '../reels/Day22'
import Day23 from '../reels/Day23'
import Day24 from '../reels/Day24'
import Day25 from '../reels/Day25'
import Day26 from '../reels/Day26'
import Day27 from '../reels/Day27'
import Day28 from '../reels/Day28'
import Day29 from '../reels/Day29'
import Day30 from '../reels/Day30'
import Day31 from '../reels/Day31'
import Day32 from '../reels/Day32'
import Day33 from '../reels/Day33'
import Day34 from '../reels/Day34'
import Day35 from '../reels/Day35'
import Day36 from '../reels/Day36'
import Day37 from '../reels/Day37'
import Day38 from '../reels/Day38'
import Day39 from '../reels/Day39'
import Day40 from '../reels/Day40'
import Day41 from '../reels/Day41'
import Day42 from '../reels/Day42'
import Day43 from '../reels/Day43'
import Day44 from '../reels/Day44'
import Day45 from '../reels/Day45'
import Day46 from '../reels/Day46'
import Day47 from '../reels/Day47'
import Day48 from '../reels/Day48'
import Day49 from '../reels/Day49'
import Day50 from '../reels/Day50'
import Day51 from '../reels/Day51'
import Day52 from '../reels/Day52'
import Day53 from '../reels/Day53'
import Day54 from '../reels/Day54'
import Day55 from '../reels/Day55'
import Day56 from '../reels/Day56'
import Day59 from '../reels/Day59'
import Day60 from '../reels/Day60'
import Day63 from '../reels/Day63'
import Day65 from '../reels/Day65'
import Day66 from '../reels/Day66'
import Day67 from '../reels/Day67'
import Day68 from '../reels/Day68'
import Day69 from '../reels/Day69'
import Day70 from '../reels/Day70'
import Day71 from '../reels/Day71'
import Day72 from '../reels/Day72'
import Day73 from '../reels/Day73'
import Day74 from '../reels/Day74'
import Day75 from '../reels/Day75'
import Day76 from '../reels/Day76'
import Day77 from '../reels/Day77'
import Day78 from '../reels/Day78'
import Day79 from '../reels/Day79'
import Day80 from '../reels/Day80'
import Day81 from '../reels/Day81'
import Day82 from '../reels/Day82'
import Day83 from '../reels/Day83'
import Day84 from '../reels/Day84'
import Day85 from '../reels/Day85'
import Day86 from '../reels/Day86'
import Day87 from '../reels/Day87'
import Day88 from '../reels/Day88'
import Day89 from '../reels/Day89'
import Day90 from '../reels/Day90'
import Day91 from '../reels/Day91'
import Day92 from '../reels/Day92'
import Day93 from '../reels/Day93'
import Day94 from '../reels/Day94'
import Day95 from '../reels/Day95'
import Day96 from '../reels/Day96'
import Day97 from '../reels/Day97'
import Day98 from '../reels/Day98'
import Day99 from '../reels/Day99'

import ValentinesDay from '../reels/ValentinesDay'
import RemembranceDay from '../reels/RemembranceDay'
import AprilFoolsDay from '../reels/AprilFoolsDay'
import GoodFriday from '../reels/GoodFriday'
import EasterSaturday from '../reels/EasterSaturday'
import EasterSunday from '../reels/EasterSunday'
import EasterMonday from '../reels/EasterMonday'
import FridayBeforeFathersDay from '../reels/FridayBeforeFathersDay'
import SaturdayBeforeFathersDay from '../reels/SaturdayBeforeFathersDay'
import FathersDay from '../reels/FathersDay'
import ChristmasEve from '../reels/ChristmasEve'
import ChristmasDay from '../reels/ChristmasDay'
import BoxingDay from '../reels/BoxingDay'
import December27 from '../reels/December27'
import NewYearsEve from '../reels/NewYearsEve'
import NewYearsDay from '../reels/NewYearsDay'

const START_DATE = new Date(2021, 0, 9) // the month is 0-indexed: Starting over on January 9 2021
const sequence = [
  Day1(),
  Day2(),
  Day3(),
  Day4(),
  Day5(),
  Day6(),
  Day7(), // First feedback
  Day8(),
  Day9(),
  Day10(),
  Day11(),
  Day12(),
  Day13(),
  Day14(), // Second feedback
  Day15(), // First Keys to FREEDOM Ad
  Day16(),
  Day17(),
  Day18(),
  Day19(), // Second Keys to FREEDOM Ad
  Day20(),
  Day21(), // Third feedback
  Day22(),
  Day23(), // Third Keys to FREEDOM Ad
  Day24(),
  Day25(),
  Day26(),
  Day27(),
  Day28(), // Fourth feedback
  Day29(),
  Day30(),
  Day31(),
  Day32(),
  Day33(),
  Day34(),
  Day35(), // Fifth feedback
  Day36(),
  Day37(),
  Day38(),
  Day39(),
  Day40(),
  Day41(),
  Day42(), // Sixth feedback
  Day43(),
  Day44(),
  Day45(),
  Day46(),
  Day47(),
  Day48(),
  Day49(), // Seventh feedback
  Day50(),
  Day51(),
  Day52(),
  Day53(),
  Day54(),
  Day55(),
  Day56(), // Eighth feedback
  Day59(), // Day 57
  Day60(), // Day 58
  Day63(), // Day 59
  Day65(), // Day 60
  Day66(), // Day 61
  Day67(), // Day 62
  Day68(), // Day 63, Ninth feedback
  Day69(), // Day 64
  Day70(), // Day 65
  Day71(), // Day 66
  Day72(), // Day 67
  Day73(), // Day 68
  Day74(), // Day 69
  // -- Bobby's first cards:
  Day75(), // Day 70
  Day76(), // Day 71
  Day77(), // Day 72
  Day78(), // Day 73
  Day79(), // Day 74
  // ----------------------
  Day80(), // Day 75
  Day81(), // Day 76
  Day82(), // Day 77
  Day83(), // Day 78
  Day84(), // Day 79
  Day85(), // Day 80
  Day86(), // Day 81
  Day87(), // Day 82
  Day88(), // Day 83
  Day89(), // Day 84
  Day90(), // Day 85
  Day91(), // Day 86
  Day92(), // Day 87
  Day93(), // Day 88
  Day94(), // Day 89
  Day95(), // Day 90
  Day96(), // Day 91
  Day97(), // Day 92
  Day98(), // Day 93
  Day99(), // Day 94
]
const fixDays = {
  '01/01': NewYearsDay(),
  '14/02': ValentinesDay(),
  '01/04/2021': AprilFoolsDay(),
  '02/04/2021': GoodFriday(),
  '03/04/2021': EasterSaturday(),
  '04/04/2021': EasterSunday(),
  '05/04/2021': EasterMonday(),
  '18/06/2021': FridayBeforeFathersDay(),
  '19/06/2021': SaturdayBeforeFathersDay(),
  '20/06/2021': FathersDay(),
  '11/11': RemembranceDay(),
  // @todo Review ChristmasIsComing and ChristmasParties
  '24/12': ChristmasEve(),
  '25/12': ChristmasDay(),
  '26/12': BoxingDay(),
  '27/12/2021': December27(), // Needs changing every year
  '31/12/2021': NewYearsEve(), // Needs changing every year
}

export default function useReels (method = 'random', currentDay = new Date()) {
  const currentIndex = (sequence.length + differenceInCalendarDays(currentDay, START_DATE)) % sequence.length
  // const nextIndex = currentIndex < sequence.length - 1 ? currentIndex + 1 : 0
  // const previousIndex = currentIndex > 0 ? currentIndex - 1 : sequence.length - 1
  const [randomIndex, setRandomIndex] = useState(null)

  useEffect(() => {
    const fetchRandomDay = async () => {
      const getRandomDay = app.functions().httpsCallable('getRandomDay')
      await getRandomDay({ amountDays: sequence.length })
        .then(result => {
          setRandomIndex(get(result, 'data.randomDay'))
        })
        .catch(err => {
          logEvent('exception', {
            description: `Could not fetch random day: ${err.message}`
          })
          setRandomIndex(currentIndex)
        })
    }
    if (method === 'random') {
      fetchRandomDay()
    }
  }, [currentIndex, method])

  return {
    startDate: START_DATE,
    currentIndex,
    randomIndex,
    catalogue: method === 'admin' ? concat(sequence, Object.values(fixDays)) : sequence,
    fixDays,
  }
}
