import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MentalHealth from '../assets/mental-health.jpg'
import TeamByFire from '../assets/team-by-fire.jpg'
import QuoteConquer from '../assets/QUOTE-Conquer-EHilary.jpg'
import FeedbackCard from './cards/FeedbackCard'

export default function Day68 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  If you have a brain then you have mental health
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  ... let's normalise talking about it.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={QuoteConquer} className={classes.responsiveImage}
                   alt="It's not the mountain we conquer, but ourselves. - Sir Edmund Hillary"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  It's OK to cry when there's too much on your mind<br/>
                  It's okay to say you feel low.
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h5">
                  What's not OK is to bottle it up and push it down.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={MentalHealth} className={classes.responsiveImage}
                   alt="Let's change the way we all think and act about mental health"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={TeamByFire} className={classes.responsiveImage}
                   alt="Team sitting by fire"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  What are you thinking about?<br/>
                  What plans are you making?<br/>
                  What ideas have you got?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  Who might they help?<br/>
                  Who might they hurt?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  BE<br/>
                  CAREFUL<br/>
                  WHAT<br/>
                  YOU<br/>
                  THINK<br/>
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  YOUR<br/>
                  THOUGHTS<br/>
                  RUN<br/>
                  YOUR<br/>
                  LIFE
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  Proverbs 4:23
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropRed}/>
        </div>
        <FeedbackCard classes={classes}/>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
