import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManInFrontOfMountain from '../assets/man-in-front-of-mountain-black-n-white.jpg'
import BrianWithFood from '../assets/Brian-with-food.jpg'
import Become from '../assets/become-what-you-might-have-been.jpg'

export default function Day70 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  IF YOU DO WHAT<br/>
                  YOU'VE ALWAYS<br/>
                  DONE
                </WhiteText>
              </Box>
              <Box style={{ width: '100%' }} mt={4}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  YOU'LL GET WHAT<br/>
                  YOU'VE ALWAYS<br/>
                  GOT!!
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManInFrontOfMountain} className={classes.responsiveImage}
                   alt="Man in front of mountain (in black and white)"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              {/*<Box>*/}
              {/*  <WhiteText align="center" variant="h5">*/}
              {/*    On this day in 1872 the 1st patent list was issued by the US Patent Office*/}
              {/*  </WhiteText>*/}
              {/*</Box>*/}
              <Box mt={4}>
                <WhiteText align="center" variant="h4">
                  What's unique about you?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={Become} className={classes.responsiveImage}
                   alt="It is never too late to become what you might have been"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <mark>
                    Don't become so well-adjusted to your culture that you fit into it without even thinking. Instead,
                    fix your attention on God & you'll be changed from the inside out.
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <mark>Romans 2, The Bible</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={BrianWithFood} className={classes.responsiveImage} style={{ width: '90%' }}
                   alt="Men sitting on bench eating"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h5">
                  THERE'S NO WIFI IN<br/>
                  THE MOUNTAINS BUT<br/>
                  WE PROMISE ...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  ON XCC ...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  YOU WILL FIND A<br/>
                  BETTER CONNECTION
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
