import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TemporaryDrawer from '../../components/TemporaryDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Logo from '../../assets/XCC-Strip.png'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSideMenu } from '../../state/actions/app'
import UserMenu from './UserMenu'
import BackwardIcon from '@material-ui/icons/ArrowBackIos'
import ForwardIcon from '@material-ui/icons/ArrowForwardIos'
import Button from '@material-ui/core/Button'
import DesktopMenu from '../../components/DesktopMenu'
import LogoLg from '../../assets/XCC-logo.png'
import BackgroundImage from "../../assets/background-cover.jpeg";
import ReelsBGVidWebM from '../../assets/Reels-slow.webm'
import ReelsBGVideoMP4 from '../../assets/Reels-slow.mp4'

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
    overflow: 'hidden',
    height: 'calc(100vh)',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
    },
  },
  mainFullWidth: {
    padding: theme.spacing(1),
  },
  backgroundVideo: {
    objectFit: 'cover',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    filter: 'grayscale(1)',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  desktopMenu:{
    display:"none",
    marginRight: "2.5rem",
    [theme.breakpoints.only("sm")]:{
      display: "flex",
      flex: 1,
      marginRight: ".3rem",
      padding:".6rem"
    },
    [theme.breakpoints.up("md")]:{
      display: "flex",
      flex: 1,
      marginRight: "1rem",
    },
    [theme.breakpoints.up("xl")]:{
      maxWidth: '50vw',
    },
  },
  menuButton:{
    display:"block",
    [theme.breakpoints.up("sm")]:{
      display: "none",
    },
  },
  logoLg:{
    display:"none",
    maxHeight:"8.5rem",
    maxWidth: '100%',
    [theme.breakpoints.only("md")]:{
      padding:"1.3rem",
      display: "block",
      maxWidth: '100%',
      marginLeft:"0.5rem",
    },
    [theme.breakpoints.up("lg")]:{
      padding:"1.2rem",
      marginLeft:"5rem",
      display: "block",
    },
  },
  logo:{
    maxWidth: '100%',
    display:"block",
    [theme.breakpoints.up("sm")]:{
      display: "none",
    },
  },
  logoContainer: {
    display: 'flex',
    flex: 1,
    width: '33%',
    justifyContent: 'center',
  },
  userMenu:{
    [theme.breakpoints.up("md")]:{
      marginRight: "1.5rem",
    },
  },
  buttonContainer: {
    display: 'flex',
    width: '35%',
    [theme.breakpoints.down("sm")]:{
      width: '45%',
    },
  },
  buttonDiv:{
    display:'flex',
    justifyContent:'space-between',
    padding: '2rem 0',
  }
}))

export default function CardLayout({
  children,
  hideNavigation = false,
  backwardForwardNavigation = false,
  onForwardNavigation,
  onBackwardNavigation,
  backgroundVideoRef,
  fullWidth = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSideMenu } = useSelector((state) => state.app);
  const searchParams = new URLSearchParams(window.location.search);
  const isNative = searchParams.get("utm_source") === "native";

  const onToggleSideMenu = useCallback(() => {
    dispatch(toggleSideMenu(!showSideMenu));
  }, [dispatch, showSideMenu]);

  return (
    <div>
      {!isNative && !hideNavigation && (
        <div>
          <AppBar position="sticky" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              {
                //OLD DESIGN FOR REELS-VAULT PAGE NAVBAR
                // backwardForwardNavigation &&
                <>
                  {/* <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<BackwardIcon/>}
                      onClick={onBackwardNavigation}
                      fullWidth={true}
                    >
                      Previous
                    </Button>
                  </div> */}
                  {/* <div className={classes.logoContainer}>
                    <IconButton aria-label="open menu" color="inherit"
                                onClick={onToggleSideMenu}>
                      <MenuIcon fontSize="large"/>
                    </IconButton>
                  </div> */}
                  {/* <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      endIcon={<ForwardIcon/>}
                      onClick={onForwardNavigation}
                      fullWidth={true}
                    >
                      Next
                    </Button>
                  </div> */}
                </>
              }
              {
                // !backwardForwardNavigation &&
                <>
                  <IconButton edge="start" aria-label="open menu" color="inherit"className={classes.menuButton} onClick={onToggleSideMenu}>
                    <MenuIcon fontSize="large" />
                  </IconButton>
                  <div>
                    <img src={LogoLg} alt="XTREME CHARACTER CHALLENGE" className={classes.logoLg}/>
                    <img src={Logo} alt="XTREME CHARACTER CHALLENGE" className={classes.logo} />
                  </div>
                  <div className={classes.desktopMenu}>
                      <DesktopMenu  />
                  </div>
                  <div className={classes.userMenu}>
                      <UserMenu />
                  </div>
                </>
              }
            </Toolbar>
          </AppBar>
          <TemporaryDrawer />
        </div>
      )}
      <Grid container component="main" className={fullWidth ? classes.mainFullWidth : classes.main}>
        {
          backgroundVideoRef &&
          <video id="backgroundVideo" ref={backgroundVideoRef} className={classes.backgroundVideo} playsInline
                 autoPlay
                 muted loop>
            <source src={ReelsBGVidWebM} type="video/webm"/>
            <source src={ReelsBGVideoMP4} type="video/mp4"/>
          </video>
        }
        {
          fullWidth &&
          <Grid item xs={12}>
            {children}
          </Grid>
        }
        {
          !fullWidth &&
          <Grid item xs={12} md={6} lg={4}>
            {
              backwardForwardNavigation &&
              <div className={classes.buttonDiv}>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<BackwardIcon/>}
                    onClick={onBackwardNavigation}
                    fullWidth={true}
                  >
                    PREV STACK
                  </Button>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<ForwardIcon/>}
                    onClick={onForwardNavigation}
                    fullWidth={true}
                  >
                    NEXT STACK
                  </Button>
                </div>
              </div>
            }
            {children}
          </Grid>
        }
      </Grid>
    </div>
  );
}
