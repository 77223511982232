import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Sunset from "../assets/102.jpg"

export default function Day81() {
    const render = (classes) => {
        return (
            <React.Fragment>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    “Lust imprisons the other person, and yet the incarceration can look good from many angels”<br/> <small>- Richard Foster</small>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.6em' }}>
                                    Do you want to be free or imprisoned?<br/><br/>
                                    Do you want other people to be free or imprisoned?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    It is for <span style={{color:"red"}}>freedom</span> that Christ has set us <span style={{color:"red"}}>free</span>.<br/>
                                    Stand firm, then, and do not let yourselves be burdened again by a yoke of slavery.
                                    <br/><br/><small>- Galatians 5:1</small>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <img src={Sunset} className={classes.responsiveImage}
                                 alt="Sunset"/>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    Let today mark a new day. A new chapter in your battle for freedom.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>



                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em',textDecoration:"underline",fontWeight:700 }}>
                                    IT’S TIME<br/>
                                    TO <span style={{color:"red"}}>TURN</span> THE PAGE
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                                    Prayer: LORD help me. Help me start afresh today. Save me from self-pity. Save me from following my feelings. Help me step out into surrender, into freedom.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
              <div>
                <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
              </div>



            </React.Fragment>
        )
    }
    return {
        render
    }
}
