import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MikeTysonGuard from '../assets/mike-tyson-guard.jpg'
import MikeTysonKO from '../assets/mike-tyson-ko.jpg'

export default function Day79 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box>
              <Box mt={12}>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  We are in a battle<br/>
                  whether you like it or not.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Is your guard up?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MikeTysonGuard} className={classes.responsiveImage}
                   alt="Mike Tyson guard"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box style={{ width: '90%' }}>
              <Box mt={8} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  "Keep your guard up.<br/>
                  You're not the only<br/>
                  ones plunged into<br/>
                  these hard times. It's<br/>
                  the same with<br/>
                  Christians all over the<br/>
                  world. So keep a firm<br/>
                  grip on the faith."
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5">
                  - 1 Peter 5:9
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  PUNCHES WILL<br/>
                  COME.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  ARE YOU<br/>
                  PREPARED...?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MikeTysonKO} className={classes.responsiveImage}
                   alt="Mike Tyson knocks his opponent KO"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  "Train yourself to be<br/>
                  godly. For physical<br/>
                  training is of some value,<br/>
                  but godliness has value<br/>
                  for all things"
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  - 1 Tim 4:7-8
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Never mind your<br/>
                  running<br/>
                  plan...How is your<br/>
                  spiritual training<br/>
                  coming along?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Spend 1min reflecting on how your spiritual life is. How can you improve it?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Extra mile: Grab a training partner, chat about how to improve.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
