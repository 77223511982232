import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManOnTopOfSnowMountain from '../assets/man-on-top-of-the-snowmountain.jpg'
import HappyBoxingDay from '../assets/happy-boxing-day.jpg'
import MusicalNotes from '../assets/musical-notes.jpg'
import ScotlandFlag from '../assets/scotland-flag.jpg'
import EventDatesScotland from './event-dates/EventDatesScotland'

export default function BoxingDay () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={HappyBoxingDay} className={classes.responsiveImage}
                   alt="Happy Boxing Day!"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h6">
                  Some suggest it dates back to the Christianised late Roman empire, when collections were made for the
                  poor in honour of St Stephen ...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  and St Stephen's Day became associated with public acts of charity
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box style={{ textAlign: 'center' }}>
                <img src={MusicalNotes} alt="Musical notes"/>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  Therefore, Christian men, be sure<br/>
                  Wealth or rank possessing<br/>
                  Ye who now will bless the poor<br/>
                  Shall yourselves find blessing
                </WhiteText>
              </Box>
              <Box mt={2} style={{ textAlign: 'center' }}>
                <img src={MusicalNotes} alt="Musical notes"/>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  <i>don't stop singing .....</i>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h6">
                  Others suggest Boxing Day tradition began in churches in the Middle Ages - parishioniers collected
                  money for the poor in alms boxes, and these were opened on the day after Christmas in the honour of St
                  Stephen, the first Christian martyr
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Make time/effort to remember the tradespeople who have served you all year
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  <i>
                    "By coach to my shoemaker's and paid all there," he reports, "and gave something to the boys` box
                    against Christmas."
                  </i>
                  <br/>
                  Samuel Pepys´ Diary 19 Dec 1663
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  'Sport' now characterises Boxing Day<br/>
                  - a sea dip, football, horseracing, hunting or shopping ...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  but also make time to stop and consider the original of today which lie in small acts
                  of <b>KINDNESS</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManOnTopOfSnowMountain} className={classes.responsiveImage}
                   alt="Man on top of snow mountain"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={ScotlandFlag} className={classes.responsiveImage} alt="Scotland flag"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  TAKE SOME<br/>
                  TIME OUT TO STOP:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  <EventDatesScotland onlyUpcoming={true}/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
