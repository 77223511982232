import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import QuoteRealHonesty from '../assets/Real-honesty.jpg'
import ScotlandFlag from '../assets/scotland-flag.jpg'
import EventDatesScotland from './event-dates/EventDatesScotland'

export default function Day55 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} p={8}>
              <Box>
                <WhiteText align="center" variant="h5">
                  ARE YOU STRUGGLING TO KEEP AFLOAT?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  Mental pain is less dramatic than physical pain, but it is more common & also more hard to bear. The
                  frequent attempt to conceal mental pain increases the burden: it is easier to say "my tooth is aching"
                  than to say "my heart is broken."
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  C.S. Lewis
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  ARE YOU HEARTBROKEN?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  IT CAN BE HARD TO ADMIT.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={QuoteRealHonesty} className={classes.responsiveImage}
                   alt="Real honesty leads to real courage"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  GET HELP MATE.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  YOU ARE NOT ALONE.<br/>
                  DON'T STRUGGLE ALONE.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  ASK ANOTHER GUY ABOUT HIS MENTAL HEALTH & TELL HIM ABOUT YOURS
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  JUST SAY SOMETHING LIKE:<br/>
                  "how are you doing in your mind? Are you struggling with any dark thoughts?"
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={ScotlandFlag} className={classes.responsiveImage} alt="Scotland flag"/>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  TAKE SOME TIME<br/>
                  OUT TO STOP:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center">
                  <EventDatesScotland onlyUpcoming={true}/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropRed}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
