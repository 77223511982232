import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import LegsAndMap from '../assets/legs-and-map.jpg'
import QuoteDoWhatYouCan from '../assets/QUOTE-Do-what-you-can.jpg'
import Link from '@material-ui/core/Link'

export default function Day4 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Picture a man sitting on his rock & thinking: The years pass.
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  He wonders where the time has gone. He regrets a life of sterility: He is a voyeur, an armchair
                  adventurer.
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  In the fear of making the wrong decision, he has made none.
                </WhiteText>
              </Box>
              <Box mb={4}>
                <WhiteText align="center" variant="h6">
                  - Robert L. Moore
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={LegsAndMap} className={classes.responsiveImage} alt="Legs and map"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  DO NOT BE PASSIVE.
                </WhiteText>
              </Box>
              <Box mb={6}>
                <WhiteText align="center" variant="h6">
                  "Choose LIFE so you and your family may LIVE!"
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center" variant="h6">
                  (Deuteronomy 30v19)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Walk for at least half an hour without looking at your phone or media device
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center">
                  ... observe and listen to what's around you...look for LIFE
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={8}>
                <WhiteText align="center" variant="h6" style={{ fontStyle: 'italic' }}>
                  Great things are done when men and mountains meet
                </WhiteText>
                <WhiteText align="center" variant="h6">
                  - William Blake
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center">
                  Join us on an XTREME CHARACTER CHALLENGE adventure<br/>
                  See our website for next dates and locations<br/>
                  <Link href="https://www.xtremecharacterchallenge.com" target="_blank"
                        rel="noopener">www.xtremecharacterchallenge.com</Link>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={QuoteDoWhatYouCan} className={classes.responsiveImage} alt="Quote: Do what you can"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
