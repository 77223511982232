import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import ReelIcon from '@material-ui/icons/ViewCarousel'
import MountainIcon from '@material-ui/icons/FilterHdr'
import PodcastsIcon from '@material-ui/icons/QueueMusic'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import NewsIcon from '@material-ui/icons/Announcement'
import { NavLink } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import CircleLogo from '../assets/XCC-Grey-Circle-Logo.png'
import BackgroundImage from '../assets/Walking-man-in-woods-sun-red-top.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSideMenu } from '../state/actions/app'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import packageJson from '../../package.json'

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
    opacity: 1,
    height: '100%',
    minHeight: '100%',
  },
  paper: {
    background: theme.palette.secondary.main,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '38% 0',
      opacity: 0.2,
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  flexColumnItem: {
    display: 'flex',
    flexDirection: ' column',
  },
  topMenu: {
    flex: 1,
  },
}))

export default function TemporaryDrawer () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showSideMenu } = useSelector(state => state.app)

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    dispatch(toggleSideMenu(open))
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box className={classes.flexColumn}>
        <Box className={classes.flexColumnItem}>
          <img src={CircleLogo} className={classes.logo} alt="XTREME CHARACTER CHALLENGE grey circle logo"/>
        </Box>
        <List className={clsx(classes.flexColumnItem, classes.topMenu)}>
          <ListItem key="home" component={'a'} href="https://xtremecharacterchallenge.com" button>
            <ListItemIcon><HomeIcon/></ListItemIcon>
            <ListItemText primary="Home" className={classes.menuItem}/>
          </ListItem>
          <ListItem key="news" component={NavLink} to="/" activeClassName="Mui-selected" exact button>
            <ListItemIcon><NewsIcon/></ListItemIcon>
            <ListItemText primary="News" className={classes.menuItem}/>
          </ListItem>
          <ListItem key="reels" component={NavLink} to="/reels" activeClassName="Mui-selected" exact button>
            <ListItemIcon><ReelIcon/></ListItemIcon>
            <ListItemText primary="Reels" className={classes.menuItem}/>
          </ListItem>
          <ListItem key="reels-vault" component={NavLink} to="/reels-vault" activeClassName="Mui-selected" exact button>
            <ListItemIcon><UnarchiveIcon/></ListItemIcon>
            <ListItemText primary="Reels Vault" className={classes.menuItem}/>
          </ListItem>
          <ListItem key="podcasts" component={NavLink} to="/podcasts" activeClassName="Mui-selected" exact button>
            <ListItemIcon><PodcastsIcon/></ListItemIcon>
            <ListItemText primary="Podcasts" className={classes.menuItem}/>
          </ListItem>
          <ListItem key="my-adventures" component={NavLink} to="/my-adventures" activeClassName="Mui-selected" exact
                    button>
            <ListItemIcon><MountainIcon/></ListItemIcon>
            <ListItemText primary="My Adventures" className={classes.menuItem}/>
          </ListItem>
        </List>
        <List className={classes.flexColumnItem}>
          <Typography align="center" variant="caption">App version {packageJson.version}</Typography>
        </List>
      </Box>
    </div>
  )

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor={'left'}
      open={showSideMenu}
      onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
  )
}
