import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManInRedJacketOnHill from '../assets/man-in-red-jacket-on-hill.jpg'
import FeedbackCard from './cards/FeedbackCard'

export default function Day14 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  LIFE<br/>
                  BEGINS<br/>
                  AT<br/>
                  THE END<br/>
                  OF YOUR<br/>
                  COMFORT<br/>
                  ZONE
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Beloved, <span style={{ color: '#c00000' }}>do not be surprised at the fiery trial</span> when it
                  comes upon you to test you.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h6">
                  1 Peter 4: 12-13
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  If it doesn't challenge you<br/>
                  it won't change you.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  - Fred DeVito
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  <b>Go for a run/walk</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  & either go FURTHER than you usually go,
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  or FASTER.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  XCC WARNING:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  It is bold, it is challenging, it is difficult. But you will come back changed. A different man.
                  Closer to the real you.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={ManInRedJacketOnHill} className={classes.responsiveImage}
                   alt="Man in red jacket sitting on hill"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundLogoAndFlags}/>
        </div>
        <FeedbackCard classes={classes}/>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
