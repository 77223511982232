import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManOnTopOfMountain from '../assets/man-on-top-of-the-snowmountain.jpg'

export default function GoodFriday () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  GOOD FRIDAY
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h2">
                  =
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h4">
                  GOD'S FRIDAY
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullCrossBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.4em' }}>
                  This is how much<br/>
                  God loved the world:<br/>
                  He gave his only Son.<br/>
                  And this is why:<br/>
                  so that no one need be<br/>
                  destroyed;<br/>
                  by believing in him,<br/>
                  anyone can have a whole<br/>
                  and lasting life.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box mt={8} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="right" variant="h4" style={{ lineHeight: '1.2em' }}>
                  DO YOU<br/>
                  BELIEVE IN<br/>
                  HIM?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManOnTopOfMountain} className={classes.responsiveImage}
                   alt="Man on top of mountain arms raised"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.4em' }}>
                  "The cross was two<br/>
                  pieces of dead wood;<br/>
                  and a helpless,<br/>
                  unresisting Man was<br/>
                  nailed to it;<br/>
                  yet it was mightier than<br/>
                  the world,<br/>
                  and triumphed,<br/>
                  and will ever triumph<br/>
                  over it."
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h6">
                  - Augustus William Hare
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h5" style={{ lineHeight: '1.4em' }}>
                  <b>
                    A PRAYER FOR<br/>
                    TODAY:
                  </b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5" style={{ lineHeight: '1.4em' }}>
                  Jesus, thank you<br/>
                  that this day of<br/>
                  darkness for you<br/>
                  means eternity of light<br/>
                  for me.<br/>
                  I believe and<br/>
                  trust in you again.<br/>
                  AMEN
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
