import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManMountain from "../assets/joshua-earle--87JyMb9ZfU-unsplash.jpg"
import BookImg from "../assets/richard-foster-money-sex-power.jpg"
export default function Day80() {
    const render = (classes) => {
        return (
            <React.Fragment>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    “Lust captivates rather than emancipates, devours rather than nourishes.”<br/> <small>- Richard Foster</small>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    None of us aim to take others captive... <br/><strong>but we’re all capable of it.</strong>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    What do you think about that?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    “True sexuality leads to humanness, but lust leads to depersonalisation.”
                                    <br/> <small>- Richard Foster</small>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    This suggests it’s a question of <span style={{color:"red"}}>when</span><br/><br/>
                                    not <span style={{color:"red"}}>if</span> we will battle with <span style={{color:"red"}}>lust</span>.<br/><br/>
                                    How are you doing?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                                    <strong>Today’s Challenge:</strong><br/>
                                    Examine your heart.<br/>
                                    Get help: <strong>don’t</strong> fight alone.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <img src={ManMountain} className={classes.responsiveImage}
                                 alt="Man and the mountain"/>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                                    <strong>Extra mile:</strong><br/>
                                    <small>Read “Money, Sex and Power” by Richard Foster.</small><br/>
                                    <img src={BookImg} className={classes.responsiveImage} style={{maxWidth:"60%"}}
                                         alt="Richart Foster"/><br/>

                                    Understand the demons within.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>



            </React.Fragment>
        )
    }
    return {
        render
    }
}
