import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import LegsAndMap from '../assets/legs-and-map.jpg'
import LargeTeam from '../assets/large-team.jpg'
import MountainWithQuote from '../assets/moutain-with-quote.jpg'

export default function Day8 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={LegsAndMap} className={classes.responsiveImage} alt="Legs and map"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundManSmiling}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  It's not the happy people who are grateful
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  <b>It's the grateful people who are happy</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={LargeTeam} className={classes.responsiveImage} alt="Large team"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={MountainWithQuote} className={classes.responsiveImage} alt="Mountain with quote"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  SIT BACK & THINK
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  Do a wall sit exercise for 30 seconds ... repeat if you dare
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center">
                  (your body should form 2 right angles - one at the hips and one at the knees)
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h5">
                  Think about what you are grateful for.<br/>
                  Say THANK YOU
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
