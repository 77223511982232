import React from 'react'
import Card from '../components/Card'
import ForgotPassword from '../components/ForgotPassword'
import CardLayout from '../layouts/CardLayout/CardLayout'

export default function ForgotPasswordPage () {
  return (
    <CardLayout>
      <Card>
        <ForgotPassword/>
      </Card>
    </CardLayout>
  )
}
