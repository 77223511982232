import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Mountain from '../assets/mountain.jpg'

export default function Day75 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Are you<br/>
                  feeling<br/>
                  flat?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Without<br/>
                  purpose?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  "Anything is<br/>
                  better than the<br/>
                  dead calm of<br/>
                  indifference"
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h4">
                  - CH Spurgeon
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  You were<br/>
                  created.
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  You are not an<br/>
                  accident.
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  You have a<br/>
                  purpose.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={Mountain} className={classes.responsiveImage}
                   alt="Mountain"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  "Therefore<br/>
                  go...and surely I<br/>
                  am with you<br/>
                  always..."
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h4">
                  - Matthew 28:19
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>Any journey needs a destination...</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>
                    Write down what you<br/>
                    want to see change in<br/>
                    your life over the next<br/>
                    12 months. Tell<br/>
                    someone what that is.
                  </b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>
                    Start something new<br/>
                    today.
                  </b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
