import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TeamByFire from '../assets/team-by-fire.jpg'
import FeedbackCard from './cards/FeedbackCard'
import UpTheHill from '../assets/IMG_0710.jpeg'

export default function Day7 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  "There's something deeply perverse about expecting those who are oppressed &/or excluded to be the
                  architects of eliminating that oppression &/or exclusion."
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h6">
                  Dr Elizabeth Henry
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={TeamByFire} className={classes.responsiveImage} alt="Team by fire"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  WANT TO FIRE UP YOUR LIFE??<br/>
                  WANT MORE PURPOSE?
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  FIGHT for those who are oppressed & excluded
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  Eliminate oppression & exclusion
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  DON'T tolerate injustice
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  HE who justifies the wicked & HE who condemns the righteous are BOTH an abomination to God
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="center" variant="h6">
                  (Proverbs 17v15)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  The PLANK
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center">
                  Hold a plank position for as long as you can keep good form<br/>
                  Later, do it again for longer than you did before.
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h6">
                  To distract yourself, pray for those who really suffer and ask what you can do to help
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={UpTheHill} className={classes.responsiveImage} alt="Up the hill"/>
              </Box>
              <Box mt={6}>
                <WhiteText align="center">
                  Join us in England, Scotland or Wales for YOUR next wilderness adventure
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
        <FeedbackCard classes={classes}/>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
