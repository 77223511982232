import React from 'react'
import ReactDOM from 'react-dom'
import theme from './darkTheme'
import App from './App'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
// import Intercom from './components/Intercom'
import store from './state/store'
import { persistCache } from 'apollo-cache-persist'
import { cache, client } from './apollo'

const render = async () => {
  //if (!isLocalhost) {
  await persistCache({
    cache,
    storage: window.localStorage,
    debug: true
  })
  //}
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider>
            <ApolloProvider client={client}>
              {/*<Intercom>*/}
              <App/>
              {/*</Intercom>*/}
            </ApolloProvider>
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render()
