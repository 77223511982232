import React, { useCallback, useContext } from 'react'
import Card from '../components/Card'
import CardLayout from '../layouts/CardLayout/CardLayout'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import WhiteText from '../components/Typography/WhiteText'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles'
import Avatar from '@material-ui/core/Avatar'
import EmailIcon from '@material-ui/icons/Email'
import { AuthContext } from '../providers/AuthProvider'
import { logEvent } from '../analytics'
import { useSnackbar } from 'notistack'
import { signOut } from '../base'
import { useApolloClient } from '@apollo/react-hooks'
// import { useIntercom } from 'react-use-intercom'

const useStyles = makeStyles((theme) => (Object.assign({}, {
  avatar: {
    width: '8em',
    height: '8em',
    backgroundColor: theme.palette.secondary.main,
  },
}, styles(theme))))

export default function EmailVerificationPage () {
  const classes = useStyles()
  const { fireUser } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const apolloClient = useApolloClient()
  // const { shutdown, boot } = useIntercom()

  const signOutCallback = useCallback(() => {
    signOut(apolloClient/*, shutdown, boot*/)
  }, [/*shutdown, boot, */apolloClient])

  const sendEmail = useCallback(() => {
    fireUser.sendEmailVerification()
      .then(function () {
        logEvent('select_content', {
          content_type: 'verification',
          content_id: 'email'
        })
        enqueueSnackbar('An email was sent to your address')
      })
      .catch(err => {
        logEvent('exception', {
          description: `Could not send email verification: ${err.message}`
        })
        enqueueSnackbar(err.message, { variant: 'error' })
      })
  }, [fireUser, enqueueSnackbar])

  return (
    <CardLayout>
      <Card>
        <Box className={classes.centeredBox} mt={4}>
          <Avatar className={classes.avatar}>
            <EmailIcon style={{ fontSize: '6em' }}/>
          </Avatar>
        </Box>
        <Box className={classes.textInTheMiddle}>
          <WhiteText align="center">
            Take a second to activate your account via the email we sent you.<br/>
            We want to be sure it's really you.
          </WhiteText>
        </Box>
        <Box className={classes.callToActionButtonContainer}>
          <Box style={{ width: '100%' }}>
            <Button variant="contained" color="secondary" size="large"
                    onClick={signOutCallback} fullWidth={true}>
              I'm ready to go
            </Button>
          </Box>
          <Box style={{ width: '100%' }} mt={2}>
            <Button variant="outlined" size="large" fullWidth={true} onClick={sendEmail}>
              Send email again
            </Button>
          </Box>
        </Box>
      </Card>
    </CardLayout>
  )
}
