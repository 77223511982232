import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import MichaelJordonQuote from '../assets/michael-jordon-quote.jpg'

export default function Day77 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h3" style={{ lineHeight: '1.2em' }}>
                  <b>
                    That thing<br/>
                    you've<br/>
                    always<br/>
                    wanted to<br/>
                    do?
                  </b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box style={{ width: '90%' }}>
              <Box mt={8} style={{ width: '100%' }}>
                <WhiteText align="center" variant="h3" style={{ lineHeight: '1.2em' }}>
                  <b>What is it?</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h3" style={{ lineHeight: '1.2em' }}>
                  <b>
                    What's<br/>
                    stopping<br/>
                    you<br/>
                    from<br/>
                    starting?
                  </b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>
                    <u>F</u>uture<br/>
                    <u>E</u>vents<br/>
                    <u>A</u>ren't<br/>
                    <u>R</u>eal
                  </b>
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>
                    Do not disqualify yourself...the only true failure is not trying.
                  </b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={MichaelJordonQuote} className={classes.responsiveImage}
                   alt="Michael Jordon quote"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <ol>
                    <li><b><u>Ask</u></b> God to release you from your self-centered fear.</li>
                    <li><b><u>Write</u></b> down what your goal is.</li>
                    <li><b><u>Tell</u></b> someone.</li>
                    <li><b><u>Begin</u></b>.</li>
                  </ol>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
