import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import RealHonesty from '../assets/Real-honesty.jpg'
import ManWithCupThinking from '../assets/man-with-cup-thinking.jpg'
//import Button from '@material-ui/core/Button'

export default function Day15 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManWithCupThinking} className={classes.responsiveImage} style={{ width: '90%' }}
                   alt="Man with cup thinking"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  What are you thinking about?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  What plans are you making?<br/>
                  What ideas have you got?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  <b>ARE THEY HEALTHY?</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  Who might they help?<br/>
                  Who might they hurt?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  BE<br/>
                  CAREFUL<br/>
                  WHAT<br/>
                  YOU<br/>
                  THINK<br/>
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  YOUR<br/>
                  THOUGHTS<br/>
                  RUN<br/>
                  YOUR<br/>
                  LIFE
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  Proverbs 4:23
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={RealHonesty} className={classes.responsiveImage} alt="Real honesty leads to real courage"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundLogoAndFlags}/>
        </div>
        {/*<div>*/}
        {/*  <Card isWithinCardStack={true} className={classes.fullKeysToFREEDOMBackdrop}>*/}
        {/*    <Box className={classes.callToActionButtonContainer}>*/}
        {/*      <Button variant="outlined" href="https://www.mercyuk.org/keystofreedomremote"*/}
        {/*              target="_blank" rel="noopener" size="large" fullWidth={true}>*/}
        {/*        Check it out!*/}
        {/*      </Button>*/}
        {/*    </Box>*/}
        {/*  </Card>*/}
        {/*</div>*/}
      </React.Fragment>
    )
  }
  return {
    render
  }
}
