import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import LetDreamsGrow from '../assets/Let-dreams-grow.jpg'
import BlueTent from '../assets/blue-tent.jpg'
//import SomethingNewIsComing from '../assets/something-new-is-coming.jpg'

export default function Day67 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={BlueTent} className={classes.responsiveImage} alt="Blue tent on a hill"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="right" variant="h4">
                  WHAT<br/>
                  ARE<br/>
                  YOU<br/>
                  DREAMING<br/>
                  ABOUT?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={LetDreamsGrow} className={classes.responsiveImage}
                   alt="Let your dreams grow so big that you feel uncomfortable looking at them"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  What excites & scares you at the same time?...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  ...Just Do It!
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  [Assuming it's legal, ethical, moral etc!]
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  I can do all things through HIM who strengthens me
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Philippians 4 : 13
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        {/*<div>*/}
        {/*  <Card isWithinCardStack={true}>*/}
        {/*    <Box className={classes.verticalCenteredBox}>*/}
        {/*      <img src={SomethingNewIsComing} className={classes.responsiveImage} alt="Something new is coming..."/>*/}
        {/*    </Box>*/}
        {/*  </Card>*/}
        {/*</div>*/}
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropGrey}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
