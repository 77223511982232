import React, { useCallback } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ChatIcon from '@material-ui/icons/Chat'
import ListItemText from '@material-ui/core/ListItemText'
import Badge from '@material-ui/core/Badge'
import ExitIcon from '@material-ui/icons/ExitToApp'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { useIntercom } from 'react-use-intercom'
import { logEvent } from '../../analytics'

export default function CourseMenu ({ onDrawerClose }) {
  const { unreadMessagesCount } = useSelector(state => state.app)
  // const { show } = useIntercom()

  const contact = useCallback(() => {
    onDrawerClose()
    // show()
    window.open('mailto:info@4muk.com?subject=App%20question', '_blank')
    // Send event to GA
    logEvent('select_content', {
      content_type: 'get_in_touch',
      content_id: 'get_in_touch'
    })
  }, [onDrawerClose/*, show*/])

  return (
    <div>
      <ListItem key="Get in touch" onClick={contact} button>
        <ListItemIcon>
          <Badge badgeContent={unreadMessagesCount} color="secondary">
            <ChatIcon/>
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Get in touch"/>
      </ListItem>
      <ListItem component={NavLink} to="/my-adventures" activeClassName="Mui-selected" exact button>
        <ListItemIcon>
          <ExitIcon color="secondary"/>
        </ListItemIcon>
        <ListItemText primary="Exit course"/>
      </ListItem>
    </div>
  )
}
