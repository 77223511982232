import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import RockCarrying from '../assets/rock-carrying.jpg'

export default function Day76 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  What is<br/>
                  frustrating<br/>
                  you at the<br/>
                  moment?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Family?<br/>
                  Work?<br/>
                  Children?<br/>
                  Spouse?<br/>
                  Everything?<br/>
                  ...
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h4">
                  Yourself?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={RockCarrying} className={classes.responsiveImage}
                   alt="Rock carrying"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  BREAKING NEWS:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  You are not perfect...nor is anyone else.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  Prayer: God grant me the serenity, to accept the things I cannot change, and courage to change the
                  things I can.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>
                    Take 30secs to ask God to help you let go of impossible expectations of others and yourself.
                  </b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>
                    Be kinder to yourself today and others.
                  </b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  "For if you forgive<br/>
                  other people when<br/>
                  they sin against you,<br/>
                  your heavenly Father,<br/>
                  will also forgive you."
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="center" variant="h5">
                  - Matthew 6:14
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
