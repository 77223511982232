import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManStandingOnMountain from '../assets/Man-standing-on-mountain-overlooking-loch.jpg'

export default function Day52 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box style={{ backgroundColor: '#c7161e' }} p={8}>
                <WhiteText align="center" variant="h5">
                  What is love?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  What is love like?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h6">
                  Love does not delight in evil but rejoices with the truth. It always protects, always trusts, always
                  hopes, always perseveres. Love never fails.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  1 Corinthians 13:6
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  Love:<br/>
                  Protects<br/>
                  Trusts<br/>
                  Perseveres
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="left" variant="h5">
                  Do you?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box style={{ backgroundColor: '#c7161e' }} p={8}>
                <Box>
                  <WhiteText align="left" variant="h5">
                    Love is hopeful<br/>
                    & never fails
                  </WhiteText>
                </Box>
                <Box mt={4}>
                  <WhiteText align="center" variant="h5">
                    ..........
                  </WhiteText>
                </Box>
                <Box mt={4}>
                  <WhiteText align="left" variant="h5">
                    HopeFULL<br/>
                    &<br/>
                    NEVER fails
                  </WhiteText>
                </Box>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  Be more like love.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManStandingOnMountain} className={classes.responsiveImage}
                   alt="Man standing on mountain overlooking loch"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
