import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import KindnessDefinition from '../assets/Kindness-definition.png'
import TimeForAnMOT from '../assets/MOT.jpg'
import SeriousBaggage from '../assets/serious-baggage.jpg'

export default function Day18 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.blackCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={KindnessDefinition} className={classes.responsiveImage} alt="The definition of Kindness"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={TimeForAnMOT} className={classes.responsiveImage} alt="Time for an M.O.T mate"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  This most generous God who gives seed to the farmer that becomes the bread for your meals is more than
                  extravagant with you. He gives you something you can then give away ...<br/>so that you can be
                  generous in every way.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h6">
                  2 Corinthians 9: 10-11
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={SeriousBaggage} className={classes.responsiveImage} alt="Serious baggage"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  Buy somebody something today and don't make a big thing of it when you pass the kindness on.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  In a world where you can be anything:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  BE KIND
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
