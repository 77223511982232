import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/XCC-C1-Sofa-SMedia-2-IN.jpg"
import Img2 from "../assets/P1030739.JPG"
import Img3 from "../assets/14.JPG"

export default function Day85() {
    const render = (classes) => {
        return (
            <React.Fragment>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img1} className={classes.responsiveImage}
                                 alt="Recharge"/>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                    <strong>Feel like you’ve been</strong><br/> carrying the weight of the world the last year?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em', fontWeight: 700}}>
                                    Feel like you’re at the end of the road…like you’ve got nothing left to give?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    Come away with us to<br/>
                                    REBOOT.<br/>
                                    RECHARGE.<br/>
                                    REFRESH.<br/>
                                    RESTART.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <img src={Img2} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h3" style={{lineHeight: '1.2em'}}>
                                    <strong>It’s time to</strong> put<br/>
                                    <span style={{color:"red",textDecoration:"underline"}}>you</span>rself first.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <img src={Img3} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                    Start the <span style={{color:"red"}}>new</span> normal off by throwing off the <span style={{color:"red"}}>old</span>.<br/><br/>
                                    Book onto an <span style={{color:"red"}}>XCC</span> now. Free to anyone who needs it.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
