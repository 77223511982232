import React from 'react'
import Card from '../components/Card'
import SignIn from '../components/SignIn'
import CardLayout from '../layouts/CardLayout/CardLayout'

export default function SignInPage () {
  return (
    <CardLayout>
      <Card>
        <SignIn/>
      </Card>
    </CardLayout>
  )
}
