import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TentsAndMountains from '../assets/tents-and-mountains.jpg'
import QUOTEHumilityInBrotherhood from '../assets/QUOTE-Humility-in-brotherhood.jpg'
import EventDatesAll from './event-dates/EventDatesAll'

export default function Day74 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  WORKING<br/>
                  WITH<br/>
                  OTHERS?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} p={4}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  THREE GOOD<br/>
                  QUESTIONS TO ASK:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>1.</b> WHAT CAN I DO FOR <b>YOU?</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>2.</b> WHAT CAN YOU DO FOR <b>ME?</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <b>3.</b> WHAT CAN WE DO <b>TOGETHER?</b>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={TentsAndMountains} className={classes.responsiveImage}
                   alt="Tents and mountains"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  GO OUT OF YOUR WAY<br/>
                  TO DO SOMETHING KIND<br/>
                  FOR SOMEONE ELSE
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={QUOTEHumilityInBrotherhood} className={classes.responsiveImage}
                   alt="This XCC showed me humility in brotherhood..."/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  It's better to have a<br/>
                  partner than go it alone.<br/>
                  Share the work, share<br/>
                  the wealth.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  And if one falls down,<br/>
                  the other helps.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  Ecclesiastes 4:9, The Bible
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>XCC WILDERNESS:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5">
                  <EventDatesAll/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropRed}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
