/*eslint no-fallthrough: 0*/
import {
  APP_TOGGLE_IOS_INSTALL_PROMPT,
  APP_TOGGLE_SIDE_MENU,
  APP_CHANGE_UNREAD_MESSAGES_COUNT,
  APP_SIGN_UP,
  APP_SAVE_REFERRER,
} from '../actionTypes'

const initialState = {
  showiOSInstallPrompt: false,
  showSideMenu: false,
  unreadMessagesCount: 0,
  signUp: {
    firstName: null,
    lastName: null,
  },
  referrer: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case APP_TOGGLE_IOS_INSTALL_PROMPT: {
      const { showiOSInstallPrompt } = action.payload
      return {
        ...state,
        showiOSInstallPrompt
      }
    }
    case APP_TOGGLE_SIDE_MENU: {
      const { showSideMenu } = action.payload
      return {
        ...state,
        showSideMenu
      }
    }
    case APP_CHANGE_UNREAD_MESSAGES_COUNT: {
      const { count } = action.payload
      return {
        ...state,
        unreadMessagesCount: count
      }
    }
    case APP_SIGN_UP: {
      const { firstName, lastName } = action.payload
      return {
        ...state,
        signUp: {
          firstName,
          lastName,
        }
      }
    }
    case APP_SAVE_REFERRER: {
      const { referrer } = action.payload
      if (referrer === null || (referrer && !['/email-verification', '/sign-in', '/sign-up'].includes(referrer.pathname))) {
        return {
          ...state,
          referrer,
        }
      }
    }
    default:
      return state
  }
}
