import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManGym from "../assets/lorenzo-hamers-jvIaut-V9a4-unsplash.jpg"
import ManPray from "../assets/jon-tyson-CYswOLYDUXY-unsplash.jpg"
import BlackText from "../components/Typography/BlackText";
import BookImg from "../assets/richard-foster-money-sex-power.jpg";
export default function Day82() {
    const render = (classes) => {
        return (
            <React.Fragment>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="center" variant="h2" style={{ lineHeight: '1.2em' }}>
                                    <strong>POWER</strong>
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <img src={ManGym} className={classes.responsiveImage}
                                 alt="Man at gym"/>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                                    Fancy having some more <strong>POWER</strong> in your life?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="center" variant="h3" style={{ lineHeight: '1.2em' }}>
                                    Why?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <BlackText align="left" variant="h4" style={{ lineHeight: '1.2em',fontWeight:700 }}>
                                    “True <span style={{color:"white"}}>power</span> has as its aim to set people <span style={{color:"white"}}>free</span>, whereas pride is determined to <span style={{color:"white"}}>dominate</span>. True power <span style={{color:"white"}}>enhances</span> relationships; pride <span style={{color:"white"}}>destroys</span> them”
                                    <br/><br/><small>- Richard Foster</small>
                                </BlackText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                                    <strong>Today’s Challenge:</strong><br/><br/>
                                    Consider what you are trying to control or dominate?<br/><br/>
                                    Note: We can even use our good deeds in order to manipulate.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                            <img src={ManPray} className={classes.responsiveImage}
                                 alt="Man Praying"/>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
                            <Box style={{ width: '100%' }}>
                                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                                    <strong>Extra mile:</strong><br/>
                                    <small>Read <em>“Money, Sex and Power”</em> by Richard Foster.</small><br/>
                                    <img src={BookImg} className={classes.responsiveImage} style={{maxWidth:"60%"}}
                                         alt="Richart Foster"/><br/>

                                    Get the tools for the job.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


            </React.Fragment>
        )
    }
    return {
        render
    }
}
