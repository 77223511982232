import { useEffect, useState } from 'react'

export default function useIsApp () {
  const [isApp, setIsApp] = useState(null)

  useEffect(() => {
    const isStandaloneiOS = 'standalone' in window.navigator && window.navigator.standalone
    const isStandaloneAndroid = window.matchMedia('(display-mode: standalone)').matches
    const searchParams = new URLSearchParams(window.location.search)
    const isAppQueryString = searchParams.has('utm_source') && ['app', 'native'].includes(searchParams.get('utm_source'))
    // The above query string (manifest.json) "?utm_source=app" in conjunction with the HashRouter is needed for SAMSUNG browsers!
    setIsApp(isStandaloneiOS || isStandaloneAndroid || isAppQueryString)
  }, [])

  return isApp
}
