import { get, has } from 'lodash-es'
import React from 'react'

/**
 * @todo Translations
 */
export default (courseUser, course) => {
  return {
    shouldProcessNode: node => {
      return get(node, 'name') === 'parser' && get(node, 'attribs.type') === 'map' && has(node, 'attribs.id')
    },
    processNode: node => {
      const mapId = node.attribs.id
      const downloadUrl = get(courseUser, ['courses', course.id, 'maps', mapId])
      if (downloadUrl) {
        return (
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
            Click here to get your route map for the long walk
          </a>
        )
      } else {
        return (
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xccremote.appspot.com/o/pdf%2FCreating%20Your%20Own%20Routes.pdf?alt=media&token=806819be-5e2a-4387-a872-181082807ec6"
            target="_blank"
            rel="noopener noreferrer">
            We're sorry that we couldn't provide you with a map for your area - use these instructions to create your
            own route
          </a>
        )
      }
    }
  }
}
