import React, { useState, useEffect, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import Backdrop from '@material-ui/core/Backdrop'
import CardLayout from '../layouts/CardLayout/CardLayout'
import { isMobile } from 'react-device-detect'
import PageVisibilityAPI from '../apis/PageVisibilityAPI'
import Reels from '../reels/Reels'

const useStyles = makeStyles((theme) => ({
  //Check the cardlayout.js and cardstack.js for more styles for this page 
  
  backdrop: {
    backgroundColor: '#333335',
  },
}))

export default function ReelsPage () {
  const classes = useStyles()
  const [videoIsPlaying/*, setVideoIsPlaying*/] = useState(false)
  const [deviceOrientation, setDeviceOrientation] = useState('')
  const [reRender, setReRender] = useState(false)
  const backgroundVideoRef = useRef(null)

  const onOrientationChange = (orientation, type, angle) => {
    setDeviceOrientation(orientation)
  }

  // const onVideoPlaying = () => {
  //   setVideoIsPlaying(true)
  // }
  //
  // const onVideoPause = () => {
  //   setVideoIsPlaying(false)
  // }

  useEffect(() => {
    const pageVisibilityAPI = PageVisibilityAPI()
    const _listener = () => {
      if (pageVisibilityAPI) {
        const { hidden } = pageVisibilityAPI
        if (!document[hidden]) {
          // App becomes visible (foreground) again
          if (backgroundVideoRef && backgroundVideoRef.current.paused) {
            // Resume playback of background video
            backgroundVideoRef.current.play()
          }
          setReRender(!reRender)
        }
      }
    }
    if (pageVisibilityAPI) {
      const { visibilityChange } = pageVisibilityAPI
      document.addEventListener(visibilityChange, _listener, false)
    }
    return () => {
      if (pageVisibilityAPI) {
        const { visibilityChange } = pageVisibilityAPI
        document.removeEventListener(visibilityChange, _listener, false)
      }
    }
  }, [backgroundVideoRef, reRender])

  if (isMobile && deviceOrientation === 'landscape' && !videoIsPlaying) {
    return (
      <DeviceOrientation lockOrientation={'portrait'} onOrientationChange={onOrientationChange}>
        <Orientation orientation='landscape' alwaysRender={false}>
          <Backdrop open={true} transitionDuration={0} className={classes.backdrop}>
            <Typography variant="h5">Please rotate your screen to portrait mode</Typography>
          </Backdrop>
        </Orientation>
      </DeviceOrientation>
    )
  }

  return (
      <CardLayout
        backgroundVideoRef={backgroundVideoRef}
      >
        <DeviceOrientation lockOrientation={'portrait'} onOrientationChange={onOrientationChange}>
          <Orientation orientation='portrait'>
             <Reels/>
          </Orientation>
        </DeviceOrientation>
      </CardLayout>
  )
}
