import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

const app = firebase.initializeApp({
  apiKey: 'AIzaSyDiEk_z186qEE5_HyQqONAduybVFIHQEtI',
  authDomain: 'xtremecharacterchallenge.firebaseapp.com',
  databaseURL: 'https://xtremecharacterchallenge.firebaseio.com',
  projectId: 'xtremecharacterchallenge',
  storageBucket: 'xtremecharacterchallenge.appspot.com',
  messagingSenderId: '920086252783',
  appId: '1:920086252783:web:3328ee225cc9f787269f1a',
  measurementId: 'G-QCXD39X4WY'
})

export default app
