import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TentsAtNight from '../assets/Tents-at-night.jpg'
import QUOTEDoWhatYouCan from '../assets/QUOTE-Do-what-you-can.jpg'

export default function Day25 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  WHERE<br/>
                  ARE<br/>
                  YOU<br/>
                  RIGHT<br/>
                  NOW?<br/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  WHY<br/>
                  ARE<br/>
                  YOU<br/>
                  THERE?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  <mark>
                    Don't be wishing you were someplace else or with someone else. Where you are right now is God's
                    place for you. Live and obey and love and believe right there.
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h6">
                  <mark>(1 Corinthians 7:17)</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={TentsAtNight} className={classes.responsiveImage} style={{ width: '90%' }}
                   alt="Tents at night"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  COMMIT AGAIN TO THE PLACE AND PEOPLE WHERE YOU ARE AT.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullRemoteBackdropRed}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={QUOTEDoWhatYouCan} className={classes.responsiveImage} style={{ width: '90%' }}
                   alt="Start where you are. Use what you have. Do what cou can."/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
