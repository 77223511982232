import { get } from 'lodash-es'
import {
  COURSE_USER_SET,
  COURSE_USER_CHANGE_INPUT,
  COURSE_USER_CHANGE_LAST_CONTACT,
  COURSE_USER_COMPLETE_LECTURE,
  COURSE_USER_CHANGE_CHALLENGES,
  COURSE_USER_COMPLETE_CHALLENGES,
} from '../actionTypes'

const initialState = {
  courseUser: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case COURSE_USER_SET: {
      const { courseUser } = action.payload
      return {
        ...state,
        courseUser
      }
    }
    case COURSE_USER_CHANGE_INPUT: {
      const { name, value, course } = action.payload
      const newCourseUser = Object.assign({}, state.courseUser)
      if (!get(newCourseUser, ['courses', course.id, 'inputs'])) {
        newCourseUser['courses'][course.id]['inputs'] = {}
      }
      newCourseUser['courses'][course.id]['inputs'][name] = value
      return {
        ...state,
        courseUser: newCourseUser
      }
    }
    case COURSE_USER_CHANGE_LAST_CONTACT: {
      const { lastContact, course } = action.payload
      const newCourseUser = Object.assign({}, state.courseUser)
      if (!get(newCourseUser, ['courses', course.id])) {
        newCourseUser['courses'][course.id] = {}
      }
      newCourseUser['courses'][course.id]['lastContact'] = lastContact
      return {
        ...state,
        courseUser: newCourseUser
      }
    }
    case COURSE_USER_CHANGE_CHALLENGES: {
      const { changedIndex, checked, percentage, course } = action.payload
      const newCourseUser = Object.assign({}, state.courseUser)
      newCourseUser['courses'][course.id]['challenges'][changedIndex]['done'] = checked
      newCourseUser['courses'][course.id]['challengesProgress'] = percentage
      return {
        ...state,
        courseUser: newCourseUser
      }
    }
    case COURSE_USER_COMPLETE_CHALLENGES: {
      const { course } = action.payload
      const newCourseUser = Object.assign({}, state.courseUser)
      const challenges = get(newCourseUser, ['courses', course.id, 'challenges'], [])
      for (const index in challenges) {
        if (!Object.prototype.hasOwnProperty.call(challenges, index)) continue
        newCourseUser['courses'][course.id]['challenges'][index]['done'] = true
      }
      newCourseUser['courses'][course.id]['challengesProgress'] = 100
      return {
        ...state,
        courseUser: newCourseUser
      }
    }
    case COURSE_USER_COMPLETE_LECTURE: {
      const { lecture, course } = action.payload
      const newCourseUser = Object.assign({}, state.courseUser)
      const completedLectures = get(newCourseUser, ['courses', course.id, 'completedLectures'], [])
      if (!completedLectures.includes(lecture.id)) {
        completedLectures.push(lecture.id)
      }
      if (!get(newCourseUser, ['courses', course.id])) {
        newCourseUser['courses'][course.id] = {}
      }
      newCourseUser['courses'][course.id]['completedLectures'] = completedLectures
      return {
        ...state,
        courseUser: newCourseUser
      }
    }
    default:
      return state
  }
}
