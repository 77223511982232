import React from 'react'

export default function EventDatesScotland ({ onlyUpcoming = false }) {
  if (onlyUpcoming) {
    return (
      <React.Fragment>
        Join us:<br/>
        XTREME CHARACTER CHALLENGE<br/>
        WILDERNESS<br/>
        Scotland 20-23th April 2023
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      Join us:<br/>
      XTREME CHARACTER CHALLENGE<br/>
      WILDERNESS<br/>
      Scotland 20-23th April 2023
    </React.Fragment>
  )
}
