import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import YouWereNotBorn from '../assets/You-were-not-born-to.jpg'
import WalkingManInWoods from '../assets/Walking-man-in-woods-sun-red-top.jpg'

export default function Day73 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={YouWereNotBorn} className={classes.responsiveImage}
                   alt="You were not born to just go to work, pay bills and die"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  WHY ARE WE HERE?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  WHAT'S THE POINT?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={WalkingManInWoods} className={classes.responsiveImage}
                   alt="Walking man in woods"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullAlphaOnlineBackdrop}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4" style={{ lineHeight: '1.2em' }}>
                  NEVER<br/>
                  STOP<br/>
                  ASKING BIG<br/>
                  QUESTIONS
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <mark>
                    Experience for yourselves<br/>
                    the truth,<br/>
                    and the truth will free you.
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  <mark>
                    John 8:31, The Bible
                  </mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropGrey}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
