import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManSmilingWithMug from '../assets/man-smiling-with-mug.jpg'
import PanoramaLoch from '../assets/panorama-loch.jpg'

export default function Day48 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  ARE YOU A DO-GOODER?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  Do all the good you can<br/>
                  By all the means you can<br/>
                  In all the ways you can<br/>
                  In all the places you can<br/>
                  At all the times you can<br/>
                  To all the people you can<br/>
                  For as long as you can
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="right" variant="h5">
                  (John Wesley)
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManSmilingWithMug} className={classes.responsiveImage}
                   alt="Man smiling with mug"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <mark>Let's not get tired of doing what is good.</mark>
                </WhiteText>
              </Box>
              <Box mt={6}>
                <WhiteText align="left" variant="h5">
                  <mark>Galatians 6:9</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={PanoramaLoch} className={classes.responsiveImage}
                   alt="Panorama of loch"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Do something good for someone else
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
