import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManOnTopOfTheSnowMountain from '../assets/man-on-top-of-the-snowmountain.jpg'
import InnerDemons from '../assets/Inner-demons.jpg'

export default function Day16 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  If you have a brain then you have mental health<br/>
                  ... let's normalise talking about it.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h6">
                  Water encompassed me to the point of death. The great deep engulfed me; weeds were wrapped around my
                  head!
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h6">
                  But You have brought up my life from the pit, O Lord my God. While I was fainting away, I remembered
                  the Lord, And my prayer came to You.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h6">
                  Jonah 2:5-7
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <img src={InnerDemons} className={classes.responsiveImage}
                   alt="...face your inner demons"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  It's OK to cry when there's too much on your mind<br/>
                  It's okay to say you feel low.
                </WhiteText>
              </Box>
              <Box mt={8}>
                <WhiteText align="center" variant="h5">
                  What's not OK is to bottle it up and push it down.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundTakeCareOfYou}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  Finish this prayer:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  "God. Please help me..."
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <img src={ManOnTopOfTheSnowMountain} className={classes.responsiveImage}
                   alt="Man on top of the snow mountain"/>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
