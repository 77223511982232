import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import GreenMan from '../assets/green-man.jpg'
import QUOTEHumilityInBrotherhood from '../assets/QUOTE-Humility-in-brotherhood.jpg'
import QUOTEFollow from '../assets/QUOTE-Follow.jpg'

export default function Day32 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <i>
                    "Talking about mental health & anger were especially important for me. These are the kind of
                    conversations I would love to have more often but find very hard in normal life"
                  </i>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="right" variant="h5">
                  T.E.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={GreenMan} className={classes.responsiveImage}
                   alt="Man dressed in green"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={QUOTEHumilityInBrotherhood} className={classes.responsiveImage}
                   alt="This XCC showed me humility in brotherhood"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em', fontWeight: 'bold' }}>
                  FRIENDSHIP<br/>
                  IS<br/>
                  SO<br/>
                  IMPORTANT
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={QUOTEFollow} className={classes.responsiveImage}
                   alt="There is always someone to follow, and someone following you: we are never alone"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  WHO ARE YOUR MATES?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Call a bloke who you can talk to about your mental health and anger
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
