import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'

export default function Day2 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  CYNICAL or JOYFUL?
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h5">
                  ... you choose.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Life can be tough and cruel. How you react is a choice.
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Joy isn't the same as happiness, but <b>it is a choice</b> to hope and to not allow your circumstances
                  to dictate your mood
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundManAtNight}/>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  A joyful heart is a good medicine, but a crushed spirit dries up the bones
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Proverbs 17v22
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.backgroundLogoAndFlags}/>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box mb={4}>
                <WhiteText align="center" variant="h6">
                  TODAY'S CHALLENGE:
                </WhiteText>
              </Box>
              <Box mb={2}>
                <WhiteText align="center" variant="h6">
                  Do 50 squats -
                </WhiteText>
              </Box>
              <Box mb={6}>
                <WhiteText align="center">
                  all at once or 5 sets of 10 across the day<br/>
                  ... your choice
                </WhiteText>
              </Box>
              <Box>
                <WhiteText align="center" style={{ fontStyle: 'italic' }}>
                  As you squat down, think about something that might crush your spirit, as you push back up, think
                  about how you can find joy in that moment
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
