import { get } from 'lodash-es'
import React from 'react'

export default (courseUser, course, handleChange, handleBlur) => {
  return {
    // Custom <textarea> processing
    shouldProcessNode: function (node) {
      return get(node, 'name') === 'textarea'
    },
    processNode: function (node, children) {
      const name = node.attribs.name
      return (
        <textarea
          {...node.attribs}
          // value={get(courseUser, ['courses', course.id, 'inputs', name], '')}
          // It's re-rendering all the time so use of value doesn't make sense here see cursor jumping
          defaultValue={get(courseUser, ['courses', course.id, 'inputs', name], '')}
          onChange={handleChange} onBlur={handleBlur}
        />
      )
    }
  }
}
