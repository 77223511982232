import { get } from 'lodash-es'
import React from 'react'

export default (courseUser, course, handleChange) => {
  return {
    // Custom <input type="radio"> processing
    shouldProcessNode: function (node) {
      return get(node, 'name') === 'input' && get(node, 'attribs.type') === 'radio'
    },
    processNode: function (node, children) {
      const name = node.attribs.name
      const value = node.attribs.value
      return (
        <input
          {...node.attribs}
          checked={get(courseUser, ['courses', course.id, 'inputs', name]) === value}
          onChange={handleChange}
        />
      )
    }
  }
}
