import { gql } from 'apollo-boost'

export const GET_USER = uid => (gql`
  {
    appUser(id: "${uid}") {
      id
      uid
      email
      firstName
      lastName
      isTeamLeader
      isReelsAdmin
      courses {
        id
        uid
        startDateIso
        endDateIso
        master
        coverImage {
          id
          url
        }
        courseType {
          id
          type
          coverImage {
             id
             url
          }
        }
      }
    }
  }
`)

export const GET_COURSE = id => (gql`
  {
    course(id: "${id}") {
      id
      uid
      startDateIso
      endDateIso
      master
      coverImage {
        id
        url
      }
      courseType {
        id
        type
        coverImage {
          id
          url
        }
        courseSections {
          id
          title
          titleNotYetPublished
          publishAt
          sortValue
          courseLectures {
            id
            title
            sortValue
            content
          }
        }
      }
    }
  }
`)

export const GET_CHALLENGES = () => (gql`
  {
    challenges(where: {contentVersions: {contentVersion: "uk"}}, sort: "sortValue_en:asc") {
      id
      amount
      icon {
        id
        url
      }
      publish
      sortValue_en
      headline_en
      label_en
      contentVersions {
        contentVersion
      }
    }
  }
`)
