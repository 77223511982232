import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import ManWearingRedHelmet from '../assets/man-wearing-red-helmet.jpg'
import EnglandFlag from '../assets/england-flag.png'
import EventDatesEngland from './event-dates/EventDatesEngland'

export default function Day72 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true} className={classes.blackCard}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  THESE<br/>
                  ARE<br/>
                  TOUGH<br/>
                  TIMES
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={ManWearingRedHelmet} className={classes.responsiveImage}
                   alt="Man wearing red helmet"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h4">
                  BE BOLD
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em', color: '#323332' }}>
                  <mark style={{ backgroundColor: '#e0e0e0' }}>
                    Be strong and<br/>
                    courageous.<br/>
                    Do not be afraid;<br/>
                    do not be discouraged,<br/>
                    for the LORD your<br/>
                    God will be with you<br/>
                    wherever you go
                  </mark>
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em', color: '#323332' }}>
                  <mark style={{ backgroundColor: '#e0e0e0' }}>Joshua 1v9</mark>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={2}>
                <WhiteText align="center" variant="h5">
                  Go for a run.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  Just start running<br/>
                  & see where you end up
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box className={classes.verticalCenteredBox}>
                <img src={EnglandFlag} className={classes.responsiveImage} alt="England flag"/>
              </Box>
              <Box mt={6}>
                <WhiteText align="center">
                  <EventDatesEngland/>
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullWildernessBackdropGrey}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
