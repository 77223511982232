import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CourseContext } from '../providers/CourseProvider'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import RegularText from './Typography/RegularText'
import getGlobalStyles from './LectureContent/globalStyles'
import { logEvent } from '../analytics'

const useStyles = makeStyles((theme) => ({
  '@global': getGlobalStyles(theme),
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: '2em',
    height: '2em',
    fontSize: '6em',
  },
  bulletList: {
    fontSize: '1rem',
    '& li': {
      marginBottom: theme.spacing(3)
    }
  },
  video: {
    width: '100%'
  },
}))

export default function WelcomeToCourse () {
  const classes = useStyles()
  const { course } = useContext(CourseContext)

  const onVideoPlaying = () => {
    logEvent('select_content', {
      content_type: 'video',
      content_id: 'How-to-use-the-XCC-REMOTE-course'
    })
  }

  if (!course) {
    return <React.Fragment>Loading course content...</React.Fragment>
  }

  return (
    <Grid container>
      <Grid xs={12} item>
        <Box className={classes.avatarContainer}>
          <Avatar className={classes.avatar}
                  src={`https://xcc.tyny.app${course.master ? course.coverImage.url : course.courseType.coverImage.url}`}/>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" align="center">
            Welcome to {course.courseType.type}!
          </Typography>
        </Box>
        <Box mt={3}>
          <div className="panel">
            <div className="panel-heading">
              <h4 className="panel-title">
                Disclaimer
              </h4>
            </div>
            <div>
              <div className="panel-body">
                <Typography>
                  We fully appreciate the sensitive nature of the information you will be sharing on this event.
                  However, like anything online, we need to make you aware that your typed words can be seen by some
                  members of the XCC team involved in facilitating this event. We would still encourage you to write
                  openly and mostly to yourself for your benefit as this is how the course works best.
                </Typography>
              </div>
            </div>
          </div>
        </Box>
        {
          course.courseType.type === 'XCC REMOTE' &&
          <Box mt={3}>
            <video
              className={classes.video}
              width="405"
              height="720"
              preload="auto"
              poster="https://firebasestorage.googleapis.com/v0/b/xtremecharacterchallenge.appspot.com/o/videos%2FXCC-REMOTE-Cover.jpeg?alt=media&token=977a66eb-a5d7-4934-b0d1-847319e5403a"
              onPlaying={onVideoPlaying}
              crossOrigin="anonymous"
              controls>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/xtremecharacterchallenge.appspot.com/o/videos%2FXCC-REMOTE.mp4?alt=media&token=a3514f8b-728f-43e2-b21a-3e018c07f29b"
                type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </Box>
        }
        <Box mt={3}>
          <Typography>
            Before you start the adventure let us walk you through a few important things:
          </Typography>
          <ul className={classes.bulletList}>
            <li>You'll find the navigation bar on top of every page</li>
            <li>
              The "GO BACK" button (upper left corner) will bring you to previous pages all the way back until you
              reach this welcome page again
            </li>
            <li>
              The "CONTINUE" button (upper right corner) will bring you to the next page until you finish the
              adventure (even if you've finished you can always navigate forward and backward through the pages)
            </li>
            <li>The button in the middle will open the menu. Here you'll find the roadmap of your adventure!</li>
            <li>
              Keep in mind that some future content might still be hidden from you and will become accessible as you
              go. You'll find a note which tells you when this content is going to be available.
            </li>
            <li>
              You might exit the adventure at any given time via the menu by clicking on "Exit course" on the bottom of
              the menu. Your progress will be saved and you'll continue on the page where you left off the time you
              come back
            </li>
          </ul>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" align="center">
            Are you ready?!
          </Typography>
          <RegularText align="center">
            ...click the continue button at the top right of this page
          </RegularText>
        </Box>
      </Grid>
    </Grid>
  )
}
