import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
//import Avatar from '@material-ui/core/Avatar'
//import WalkIcon from '@material-ui/icons/DirectionsWalk'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { signIn } from '../state/actions/app'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import RegularText from './Typography/RegularText'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '1.5em',
    height: '1.5em',
    //backgroundColor: theme.palette.secondary.main,
    fontSize: '4em',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    margin: theme.spacing(0, 0, 2),
  },
}))

export default function SignIn () {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <React.Fragment>
      {/*<Box>*/}
      {/*  <Avatar className={classes.avatar}>*/}
      {/*    <WalkIcon fontSize="inherit"/>*/}
      {/*  </Avatar>*/}
      {/*</Box>*/}
      <Box mt={3}>
        <Typography variant="h5" align="center">
          Start the Adventure!
        </Typography>
      </Box>
      <Box mt={2}>
        <RegularText align="center">
          Please sign in to access this section of the App.
        </RegularText>
      </Box>
      <Box mt={2}>
        <form className={classes.form} onSubmit={e => dispatch(signIn(e, history, enqueueSnackbar))}
              noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox name="remember" value="remember" color="primary"/>}*/}
          {/*  label="Remember me"*/}
          {/*/>*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            I'm ready to go
          </Button>
          <Button
            fullWidth
            variant="outlined"
            component={NavLink}
            to="/sign-up"
            className={classes.button}
          >
            Create user account
          </Button>
          <Button
            fullWidth
            variant="outlined"
            component={NavLink}
            to="/forgot-password"
            className={classes.button}
          >
            Forgot password
          </Button>
        </form>
      </Box>
    </React.Fragment>
  )
}
