import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import TwoMenSmilingInCamera from '../assets/two-men-smiling-in-camera.jpg'

export default function Day46 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h4">
                  Dad<br/>
                  Papa<br/>
                  Father<br/>
                  Daddy<br/>
                  Pops<br/>
                  Who?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  Six decades of social science have established that the most efficient way to increase dysfunction is
                  to increase fatherlessness.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
              <img src={TwoMenSmilingInCamera} className={classes.responsiveImage}
                   alt="Two men smiling in camera"/>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  The absence of a father predicts higher rates of truancy, psychiatric problems, criminality,
                  promiscuity, drug use, rape, domestic violence, and other less-than-optimal outcomes.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  Fatherlessness leads to a search for father substitutes. And some of these daddy placeholders turn out
                  to be toxic.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  A high percentage of gang members come from father-absent homes...possibly resulting from a need for a
                  sense of belonging
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5">
                  This is deep and powerful stuff:
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h6">
                  Think about your role as a father (even if you don't have your own children; you are probably
                  someone's father substitute). Grow this role in a healthy way.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
