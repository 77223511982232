import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/joshua-earle--87JyMb9ZfU-unsplash.jpg"
import Img2 from "../assets/tobias-keller-73F4pKoUkM0-unsplash.jpg"
import Img3 from "../assets/colton-duke-QRU0i5AqEJA-unsplash.jpg"
import Img4 from "../assets/102.jpg"

export default function Day96() {
    const render = (classes) => {
        return (
            <React.Fragment>             
                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em', fontSize:'2.2em'}}>
                                    I lift up my eyes to the mountains - <br/>
                                    where does my help come from?
                                </WhiteText>
                              
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center'}}>                     
                            <Box mt={2}>
                                <img src={Img1} className={classes.responsiveImage}
                                    alt="Man standing on a mountain"/>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em', fontSize:'2.2em'}}>
                                    My help comes from <br/> 
                                    the Lord,<br/><br/>
                                    the Maker of heaven<br/>
                                    and earth.                            
                                </WhiteText>
                              
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>                     
                            <Box mt={2}>
                            <img src={Img2} className={classes.responsiveImage}
                                alt="Beautiful mountain and river"/>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>                     
                            <Box mt={2}>
                            <img src={Img3} className={classes.responsiveImage}
                                alt="On a mountain on a sunny day"/>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '100%'}}>
                            <Box mt={2} style={{width: '100%'}}>
                                <img src={Img4} className={classes.responsiveImage}
                                alt="Sunny day on the mountain"/>
                            </Box>
                           
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em', fontSize:'2.2em'}}>
                                He will not let your foot slip - <br/>  <br/> 
                                he who watches over you will not slumber<br/><br/>
                                </WhiteText>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em', fontSize:'2em'}}>
                                - Psalm 121:1-3 
                                </WhiteText>
                              
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
                </div>
            </React.Fragment>
        )
    }
    return {
        render
    }
}
