import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CourseContext } from '../providers/CourseProvider'
import { NavLink } from 'react-router-dom'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckedIcon from '@material-ui/icons/CheckCircle'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { compareValues } from '../Utils'
import { parseISO, parse, isAfter, format } from 'date-fns'
import useCompletedLectures from '../hooks/useCompletedLectures'
import { sanitizeCourseType } from './CourseNavigator'

const useStyles = makeStyles((theme) => ({
  selectedLecture: {
    background: 'rgba(199,22,30,0.2)',
  },
  section: {
    background: '#e8e8e8',
    color: theme.palette.primary.contrastText,
  },
  sectionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    flex: 1,
    fontWeight: 'bold',
  },
  sectionPublishDate: {
    fontSize: '0.9em',
  },
  lecturesList: {
    listStyle: 'none',
    padding: 0,
    background: '#f0f0f0',
  },
  lecturesItem: {
    color: theme.palette.primary.contrastText,
    borderStyle: 'solid',
    borderColor: 'rgba(199,22,30,0.2)',
    borderWidth: '1px 0 0 0',
  },
}))

export default function CourseCurriculum () {
  const classes = useStyles()
  const { course } = useContext(CourseContext)
  const completedLectures = useCompletedLectures()

  if (!course || completedLectures === null) {
    return <React.Fragment>Loading course curriculum...</React.Fragment>
  }

  const courseStart = parseISO(course.startDateIso)

  return (
    <React.Fragment>
      {course.courseType.courseSections.sort(compareValues('sortValue', 'asc')).map(section => {
        // Parse to ISO day of week (Monday (1) - Sunday(7)) NOT day of week (Sunday(0) - Saturday(6))
        const publishAt = parse(section.publishAt, 'iiii HH:mm:ss', courseStart)
        const sectionAvailable = course.master === true || isAfter(new Date(), publishAt)
        return (
          <Box key={section.id} mb={1}>
            <ListItem className={classes.section}>
              <ListItemText disableTypography={true} className={classes.sectionTitleContainer}>
                <Typography
                  className={classes.sectionTitle}>{sectionAvailable ? section.title : section.titleNotYetPublished}</Typography>
                {
                  !sectionAvailable &&
                  <Typography
                    className={classes.sectionPublishDate}
                  >
                    {`(Available ${format(publishAt, 'EEEE HH\'h\'mm')})`}
                  </Typography>
                }
              </ListItemText>
            </ListItem>
            {
              sectionAvailable &&
              <ul className={classes.lecturesList}>
                {section.courseLectures.sort(compareValues('sortValue', 'asc')).map(lecture => (
                  <React.Fragment key={lecture.id}>
                    {
                      course.master === true &&
                      <ListItem
                        className={classes.lecturesItem}
                        component={NavLink}
                        to={`/course/${course.id}/${sanitizeCourseType(course)}/lecture/${lecture.id}`}
                        activeClassName={classes.selectedLecture}
                        button
                      >
                        <ListItemIcon>
                          {
                            completedLectures.includes(lecture.id) &&
                            <CheckedIcon color="secondary"/>
                          }
                          {
                            !completedLectures.includes(lecture.id) &&
                            <UncheckedIcon color="secondary"/>
                          }
                        </ListItemIcon>
                        <ListItemText primary={lecture.title}/>
                      </ListItem>
                    }
                    {
                      course.master === false && completedLectures.includes(lecture.id) &&
                      <ListItem
                        className={classes.lecturesItem}
                        component={NavLink}
                        to={`/course/${course.id}/${sanitizeCourseType(course)}/lecture/${lecture.id}`}
                        activeClassName="Mui-selected"
                        button
                      >
                        <ListItemIcon>
                          <CheckedIcon color="secondary"/>
                        </ListItemIcon>
                        <ListItemText primary={lecture.title}/>
                      </ListItem>
                    }
                    {
                      course.master === false && !completedLectures.includes(lecture.id) &&
                      <ListItem className={classes.lecturesItem} disabled={true}>
                        <ListItemIcon>
                          <UncheckedIcon color="secondary"/>
                        </ListItemIcon>
                        <ListItemText primary={lecture.title}/>
                      </ListItem>
                    }
                  </React.Fragment>
                ))}
              </ul>
            }
          </Box>
        )
      })}
    </React.Fragment>
  )
}
