import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/XCC-C1-Gamer-SMedia-5-IN.jpg"
import Img2 from "../assets/P1030739.JPG"
import Img3 from "../assets/snowdon horseshoe.JPG"

export default function Day92() {
    const render = (classes) => {
        return (
            <React.Fragment>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img1} className={classes.responsiveImage}
                                 alt="Bring your skills outdoors"/>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    There’s only so much adventure you can have in lockdown right?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                    It’s time to..<br/><br/>
                                    Reboot<br/>
                                    Refresh<br/>
                                    Recharge<br/>
                                    Restart
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


              <div>
                <Card isWithinCardStack={true}>
                  <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                    <Box>
                      <WhiteText align="center" variant="h5">
                        <b>Not like this...</b>
                      </WhiteText>
                    </Box>
                    <Box mt={2}>
                      <img src={Img2} className={classes.responsiveImage}
                           alt="Bring your skills outdoors"/>
                    </Box>
                  </Box>
                </Card>
              </div>


              <div>
                <Card isWithinCardStack={true} className={classes.redCard}>
                  <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                    <Box>
                      <WhiteText align="center" variant="h5">
                        <b>More like this...</b>
                      </WhiteText>
                    </Box>
                    <Box mt={2}>
                      <img src={Img3} className={classes.responsiveImage}
                           alt="Bring your skills outdoors"/>
                    </Box>
                  </Box>
                </Card>
              </div>



              <div>
                <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
              </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                    <strong>Today’s Challenge:</strong><br/><br/>
                                    Ask some blokes to do an XCC with you.<br/><br/>
                                    Free to those that need it.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

            </React.Fragment>
        )
    }
    return {
        render
    }
}
