import React, { useState, useEffect, useCallback } from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { AuthProvider } from './providers/AuthProvider'
import PWAPrompt from 'react-ios-pwa-prompt'
import * as serviceWorker from './serviceWorker'
// import { useSnackbar } from 'notistack'
// import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
// import CloseIcon from '@material-ui/icons/Close'
// import IconButton from '@material-ui/core/IconButton'
import { InstallPromptProvider } from './components/InstallPrompt'
import { useSelector, useDispatch } from 'react-redux'
import { hideiOSInstallPrompt } from './state/actions/app'
import PrivateRoute from './components/PrivateRoute'
import VerifiedRoute from './components/VerifiedRoute'
// import { useIntercom } from 'react-use-intercom'
import { logEvent } from './analytics'
import packageJson from '../package.json'
// import useIsApp from './hooks/useIsApp'
// import { BrowserView, MobileView } from 'react-device-detect'
// import Box from '@material-ui/core/Box'
// import Typography from '@material-ui/core/Typography'
// import Backdrop from '@material-ui/core/Backdrop'

// Pages
import LandingPage from './pages/LandingPage'
import MyAdventuresPage from './pages/MyAdventuresPage'
import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'
import ForgotPasswordPage from './pages/ForgotPassword'
// import InstallPage from './pages/InstallPage'
import CoursePage from './pages/CoursePage'
import StaticContentPage from './pages/StaticContentPage'
import ReelsPage from './pages/ReelsPage'
import ReelsVaultPage from './pages/ReelsVaultPage'
import ReelsAdminPage from './pages/ReelsAdminPage'
import PodcastsPage from './pages/PodcastsPage'
import EmailVerificationPage from './pages/EmailVerificationPage'

function App () {
  const dispatch = useDispatch()
  // const isApp = useIsApp()
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [waitingWorker, setWaitingWorker] = useState({})
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)
  // const { boot, update } = useIntercom()
  const { showiOSInstallPrompt } = useSelector(state => state.app)
  // const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  // const showInstallPage = !isApp && !isLocalhost

  const onHashChanged = useCallback(() => {
    const hash = document.location.hash
    // Send page transitions to Intercom
    // update()
    // Send page transitions to GA
    logEvent('screen_view', { screen_name: hash.substring(1) })
  }, [/*update*/])

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({ onRegister: onServiceWorkerRegister, onUpdate: onServiceWorkerUpdate })

    // Boot Intercom
    // boot({
    //   customAttributes: {
    //     app_version: packageJson.version,
    //   },
    // })

    // Handle initial render
    onHashChanged()
    // ...and all subsequent changes
    window.addEventListener('hashchange', onHashChanged)
    return () => {
      window.removeEventListener('hashchange', onHashChanged, false)
    }
  }, [/*boot, */onHashChanged])

  useEffect(() => {
    // const refreshAction = (key) => {
    //   return (
    //     <React.Fragment>
    //       <Button
    //         className="snackbar-button"
    //         size="small"
    //         color="secondary"
    //         onClick={updateServiceWorker}
    //       >
    //         Update now
    //       </Button>
    //       <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
    //         <CloseIcon fontSize="small"/>
    //       </IconButton>
    //     </React.Fragment>
    //   )
    // }
    const updateServiceWorker = () => {
      waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
      setNewVersionAvailable(false)
      logEvent('select_content', {
        content_type: 'update',
        content_id: packageJson.version
      })
      window.location.reload()
    }

    if (newVersionAvailable) {
      // if (!isApp) {
      // Silent update
      updateServiceWorker()
      // } else {
      //   enqueueSnackbar('App update available', {
      //     persist: true,
      //     action: refreshAction,
      //   })
      // }
    }
  }, [waitingWorker, newVersionAvailable/*, enqueueSnackbar, closeSnackbar, isApp, showInstallPage*/])

  const onServiceWorkerUpdate = registration => {
    setWaitingWorker(registration && registration.waiting)
    setNewVersionAvailable(true)
  }

  const onServiceWorkerRegister = registration => {
    if (registration && registration.waiting) {
      setWaitingWorker(registration.waiting)
      setNewVersionAvailable(true)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline/>
      {/*{*/}
      {/*  showInstallPage &&*/}
      {/*  <InstallPromptProvider>*/}
      {/*    <PWAPrompt*/}
      {/*      debug={showiOSInstallPrompt}*/}
      {/*      timesToShow={0}*/}
      {/*      permanentlyHideOnDismiss={false}*/}
      {/*      onClose={() => dispatch(hideiOSInstallPrompt())}*/}
      {/*      copyBody={*/}
      {/*        <div>This website has App functionality. Add it to your home screen to use it in fullscreen and*/}
      {/*          while offline.<br/><br/><u>NOTE</u>: You might <b>NOT</b> see a 'Share' button if you've opened the link*/}
      {/*          from within another App. <br/>If that's the case look out for a '<b>Open in Safari</b>' option or open*/}
      {/*          up your Safari browser and navigate to https://app.xtremecharacterchallenge.com manually.<br/><br/>Get*/}
      {/*          in touch if you need further help.</div>*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <InstallPage/>*/}
      {/*  </InstallPromptProvider>*/}
      {/*}*/}
      {/*{*/}
      {/*  !showInstallPage &&*/}
      {/*<BrowserView>*/}
      {/*  <Backdrop open={true} transitionDuration={0} style={{ flexDirection: 'column', backgroundColor: '#333335' }}>*/}
      {/*    <Box align="center" pl={4} pr={4}>*/}
      {/*      <img src={XCCRedMountains} alt="XCC logo red mountains" width="30%"/>*/}
      {/*    </Box>*/}
      {/*    <Box align="center" pl={4} pr={4}>*/}
      {/*      <Typography variant="h5">*/}
      {/*        Unfortunately this App has not yet been optimized for big screens please open it on a mobile device such*/}
      {/*        as phone or tablet*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*  </Backdrop>*/}
      {/*</BrowserView>*/}
      {/*<MobileView>*/}
      <InstallPromptProvider>
        <PWAPrompt
          debug={showiOSInstallPrompt}
          timesToShow={0}
          permanentlyHideOnDismiss={false}
          onClose={() => dispatch(hideiOSInstallPrompt())}
          copyBody={
            <>This website has App functionality. Add it to your home screen to use it in fullscreen and
              while offline.<br/><br/><u>NOTE</u>: You might <b>NOT</b> see a 'Share' button if you've opened the link
              from within another App. <br/>If that's the case look out for a '<b>Open in Safari</b>' option or open
              up your Safari browser and navigate to https://app.xtremecharacterchallenge.com manually.<br/><br/>Get
              in touch if you need further help.</>
          }
        />
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={LandingPage}/>
              <Route exact path="/podcasts" component={PodcastsPage}/>
              <PrivateRoute exact path="/email-verification" component={EmailVerificationPage}/>
              <VerifiedRoute exact path="/reels/:id?" component={ReelsPage}/>
              <VerifiedRoute exact path="/reels-vault" component={ReelsVaultPage}/>
              <VerifiedRoute exact path="/reels-admin" component={ReelsAdminPage}/>
              <VerifiedRoute exact path="/my-adventures" component={MyAdventuresPage}/>
              <VerifiedRoute path="/course/:courseId/:courseType/(lecture)?/:lectureId?" component={CoursePage}/>
              <VerifiedRoute path="/page/:slug" component={StaticContentPage}/>
              <Route exact path="/sign-in" component={SignInPage}/>
              <Route exact path="/sign-up" component={SignUpPage}/>
              <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
              <Route path="*" component={() => <Redirect to="/"/>}/>
            </Switch>
          </Router>
        </AuthProvider>
      </InstallPromptProvider>
      {/*</MobileView>*/}
      {/*}*/}
    </React.Fragment>
  )
}

export default App
