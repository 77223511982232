import { has, isArray, get, set } from 'lodash-es'
import app from './initialize'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import { logEvent } from './analytics'
// import packageJson from '../package.json'
import { GET_CHALLENGES } from './graphql/queries'
import { client as ApolloClient } from './apollo'

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

firebase.firestore()
  .enablePersistence({
    synchronizeTabs: true
  })
  .catch(function (err) {
      if (err.code === 'failed-precondition') {
        logEvent('exception', { description: 'Firestore persistence: Multiple tabs open, persistence can only be enabled in one tab at a a time' })
      } else if (err.code === 'unimplemented') {
        logEvent('exception', { description: 'Firestore persistence: The current browser does not support all of the features required to enable persistence' })
      }
    }
  )

export function forgotPassword (email) {
  return app.auth().sendPasswordResetEmail(email)
}

export function signIn (email, password) {
  return app.auth().setPersistence('local')
    .then(() => {
      return app
        .auth()
        .signInWithEmailAndPassword(email, password)
    })
}

export async function signUp (userAttributes, history, enqueueSnackbar) {
  try {
    const { firstName, lastName, email, password } = userAttributes
    await app
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        return result.user.updateProfile({
          displayName: firstName
        })
      })
      .then(() => {
        const sendToAppSlack = app.functions().httpsCallable('sendToAppSlack')
        return sendToAppSlack({ message: `We have a new App Sign-up\n>*${firstName} ${lastName}*` })
          .catch(err => {
            logEvent('exception', {
              description: `Could not send to slack: ${err.message}`,
            })
          })
      })
      .then(() => logEvent('sign-up', { method: 'emailAndPassword' }))
      .then(() => {
        enqueueSnackbar('Thank you for signing up! Well done!')
        setTimeout(() => history.push('/email-verification'), 1000)
      })
  } catch (e) {
    logEvent('exception', { description: `Could not sign up: ${e.message}`, fatal: true })
    enqueueSnackbar(e.message, { variant: 'error' })
  }
}

export async function signOut (apolloClient/*, shutdown, boot*/) {
  try {
    await app
      .auth()
      .signOut()
      .then(() => apolloClient.clearStore()) // Wipe apollo persistent cache
      .then(() => {
        // Shutdown Intercom and create new session
        // shutdown()
        // boot({
        //   customAttributes: {
        //     app_version: packageJson.version,
        //   },
        // })
        logEvent('sign-out')
      })
  } catch (e) {
    logEvent('exception', { description: `Could not sign out: ${e.message}` })
  }
}

function _setUserDoc (userDoc) {
  app.firestore().collection('courseUsers').doc(userDoc.uid).set(userDoc, { merge: true }).catch(error => {
    logEvent('exception', {
      description: `Could not set user doc: ${error.message}`,
      fatal: false
    })
  })
}

export function updateUserDoc (uid, updates) {
  app.firestore().collection('courseUsers').doc(uid).update(updates).catch(error => {
    logEvent('exception', {
      description: `Could not update user doc: ${error.message}`,
      fatal: false
    })
  })
}

export async function ensureCourseUserDocument (appUser, course) {
  const lastContact = firebase.firestore.Timestamp.fromDate(new Date())
  const initialCourseDoc = {
    firstContact: lastContact,
    lastContact: lastContact,
    challenges: [],
    challengesProgress: '0.00',
    notifications: {
      started: false,
    },
    maps: {},
  }
  const initialUserDoc = {
    uid: appUser.uid,
    email: appUser.email,
    firstName: appUser.firstName,
    lastName: appUser.lastName,
    courseIds: [course.id],
    courses: {
      [course.id]: initialCourseDoc
    }
  }
  const courseUserRef = app.firestore().collection('courseUsers').doc(appUser.uid)
  const courseType = course.courseType.type

  /**
   * Init maps for particular course type
   */
  let maps = {}
  switch (courseType) {
    case 'XCC REMOTE':
      ['Walk', 'Run'].map(async (mapId) => {
        try {
          const downloadUrl = await app.storage().ref(`maps/${course.uid}/${appUser.firstName} ${appUser.lastName} ${mapId}.pdf`).getDownloadURL()
          if (downloadUrl) {
            maps[mapId] = downloadUrl
          }
        } catch (error) {
          logEvent('exception', {
            description: `Could not fetch map with id '${mapId}' for ${appUser.firstName} ${appUser.lastName}: ${error.message}`
          })
        }
      })
      break
    default:
  }

  let challenges = []
  if (courseType === 'XCC REMOTE') {
    /**
     * Load challenges only for XCC REMOTE atm
     */
    try {
      await ApolloClient
        .query({ query: GET_CHALLENGES(), fetchPolicy: isLocalhost ? 'network-only' : 'cache-first' })
        .then(result => {
          challenges = get(result, 'data.challenges', []).map(c => {
            c.done = false
            return c
          })
        })
    } catch (error) {
      logEvent('exception', {
        description: `Could not fetch challenges for ${appUser.firstName} ${appUser.lastName}: ${error.message}`
      })
    }
  }

  return courseUserRef.get().then(doc => {
    let userDoc

    if (doc.exists) {
      userDoc = doc.data()
      if (!isArray(userDoc.courseIds)) {
        userDoc.courseIds = []
      }
      if (!userDoc.courseIds.includes(course.id)) {
        userDoc.courseIds.push(course.id)
      }
      if (!has(userDoc, ['courses', course.id])) {
        userDoc['courses'][course.id] = initialCourseDoc
      }
      if (!has(userDoc, ['courses', course.id, 'challenges'])) {
        userDoc['courses'][course.id]['challenges'] = challenges
        userDoc['courses'][course.id]['challengesProgress'] = '0.00'
      }
      // Update last contact for that particular course
      userDoc['courses'][course.id]['lastContact'] = lastContact
      // Update map download urls
      userDoc['courses'][course.id].maps = maps
    } else {
      // Set challenges
      initialUserDoc['courses'][course.id].challenges = challenges
      // Set map download urls
      initialUserDoc['courses'][course.id].maps = maps
      // Set initial user document
      userDoc = initialUserDoc
    }

    Promise.resolve()
      .then(() => {
        if (get(userDoc, ['courses', course.id, 'notifications', 'started'], false) === false) {
          const sendToAppSlack = app.functions().httpsCallable('sendToAppSlack')
          return sendToAppSlack({ message: `${appUser.firstName} ${appUser.lastName} started ${course.courseType.type}` })
            .then(() => set(userDoc, ['courses', course.id, 'notifications', 'started'], true))
            .catch(err => {
              logEvent('exception', {
                description: `Could not send to slack: ${err.message}`,
              })
            })
        }
      })
      // Do set in background and return user document
      .then(() => _setUserDoc(userDoc))

    return userDoc
  }).catch(error => {
    logEvent('exception', {
      description: `Could not fetch course user: ${error.message}`,
      fatal: true
    })
    return initialUserDoc
  })
}

export default app
