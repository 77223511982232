import { get, isEmpty } from "lodash-es";
import React, { useCallback, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
//import Avatar from '@material-ui/core/Avatar'
//import MountainIcon from '@material-ui/icons/FilterHdr'
import Card from "../components/Card";
import { AuthContext } from "../providers/AuthProvider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Carousel from "nuka-carousel";
import { useHistory } from "react-router";
import CardLayout from "../layouts/CardLayout/CardLayout";
import RegularText from "../components/Typography/RegularText";
import Link from "@material-ui/core/Link";
import { sanitizeCourseType } from "../components/CourseNavigator";
import { add, isAfter, isBefore, parseISO } from "date-fns";
import { TIME_AFTER_COURSE_FINISHED } from "../components/CourseNavigator";
// import { PullToRefresh, PullDownContent, ReleaseContent, RefreshContent } from 'react-js-pull-to-refresh'
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "1.5em",
    height: "1.5em",
    //backgroundColor: theme.palette.secondary.main,
    fontSize: "4em",
  },
  carouselContainer: {
    width: "70%",
    //display: 'flex',
    //flex: 1,
  },
  coverImage: {
    width: "100%",
    height: "auto",
  },
  masterBadgeRoot: {
    display: "block",
  },
  masterBadge: {
    top: "20px",
    right: "60px",
    transform: "scale(1) translate(50%, -50%)",
    transformOrigin: "100% 0%",
    backgroundColor: "#fff",
    fontWeight: "bold",
  },
  button: {
    margin: theme.spacing(0, 0, 2),
  },
}));

export default function MyAdventuresPage() {
  const classes = useStyles();
  const { user, refreshUser } = useContext(AuthContext);
  const courses = get(user, "courses", []).filter((c) => {
    // Show only running courses
    return (
      isAfter(new Date(), parseISO(c.startDateIso)) &&
      // Master copies don't have end dates
      (isEmpty(c.endDateIso) || isBefore(new Date(), add(parseISO(c.endDateIso), TIME_AFTER_COURSE_FINISHED)))
    );
  });
  const carouselSettings = {
    cellSpacing: 40,
    renderBottomCenterControls: () => null,
  };
  const history = useHistory();

  if (courses.length <= 1) {
    // Hide previous/next button if only one course running
    carouselSettings["renderCenterLeftControls"] = () => null;
    carouselSettings["renderCenterRightControls"] = () => null;
  }

  const onCourseClick = (course) => {
    history.push(`/course/${course.id}/${sanitizeCourseType(course)}`);
  };

  const memoizedOnRefresh = useCallback(() => {
    // react-js-pull-to-refresh needs a returned promise here
    return Promise.resolve().then(() => refreshUser("network-only"));
  }, [refreshUser]);

  if (!user) {
    return (
      <Backdrop open={true} transitionDuration={0}>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  return (
    // <PullToRefresh
    //   pullDownContent={<PullDownContent/>}
    //   releaseContent={<ReleaseContent/>}
    //   refreshContent={<RefreshContent/>}
    //   pullDownThreshold={100}
    //   onRefresh={memoizedOnRefresh}
    //   triggerHeight={100}
    //   backgroundColor='white'
    //   startInvisible={true}
    // >
    <div>
      <CardLayout>
          <Card>
            {/*<Box>*/}
            {/*  <Avatar className={classes.avatar}>*/}
            {/*    <MountainIcon fontSize="inherit"/>*/}
            {/*  </Avatar>*/}
            {/*</Box>*/}
            <Box mt={2}>
              <Typography variant="h5" align="center">
                {`Welcome ${user.firstName}!`}
              </Typography>
            </Box>
            {Boolean(courses.length) && (
              <React.Fragment>
                <Box mt={2}>
                  <Typography variant="h5" align="center">
                    Are you ready for adventure?
                  </Typography>
                </Box>
                <Box mt={2} className={classes.carouselContainer}>
                  <Carousel {...carouselSettings}>
                    {courses.map((c) => (
                      <img
                        key={c.id}
                        src={`https://xcc.tyny.app${c.master ? c.coverImage.url : c.courseType.coverImage.url}`}
                        alt={c.courseType.type}
                        className={classes.coverImage}
                        onClick={() => onCourseClick(c)}
                      />
                    ))}
                  </Carousel>
                </Box>
                <Box mt={2} style={{ width: "100%" }}>
                  <Button variant="outlined" className={classes.button} onClick={memoizedOnRefresh} fullWidth>
                    Refresh
                  </Button>
                </Box>
              </React.Fragment>
            )}
            {!Boolean(courses.length) && (
              <React.Fragment>
                <Box mt={3}>
                  <RegularText align="center">There are no adventures available to you right now.</RegularText>
                </Box>
                <Box mt={3}>
                  <RegularText align="center">
                    Whether you haven't signed up for one or it hasn't started yet.
                  </RegularText>
                </Box>
                <Box mt={3}>
                  <RegularText align="center">
                    <Link
                      color="secondary"
                      href="https://www.xtremecharacterchallenge.com/shop/#tags=12115|12902"
                      target="_blank"
                      rel="noopener"
                    >
                      Please go and visit our web shop
                    </Link>{" "}
                    to sign up for one. If you did sign up please come back and check again later.
                  </RegularText>
                </Box>
                <Box mt={3} style={{ width: "100%" }}>
                  <Button fullWidth variant="outlined" className={classes.button} onClick={memoizedOnRefresh}>
                    Refresh
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Card>
      </CardLayout>
    </div>

    // </PullToRefresh>
  );
}
