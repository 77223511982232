import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img1 from "../assets/XCC-C1-Gamer-SMedia-3-FB.jpg"
import Img2 from "../assets/joshua-earle--87JyMb9ZfU-unsplash.jpg"

export default function Day90() {
    const render = (classes) => {
        return (
            <React.Fragment>



                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h3" style={{lineHeight: '1.2em'}}>
                                    BROTHERS...
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img1} className={classes.responsiveImage}
                                 alt="Join the recharge"/>
                        </Box>
                    </Card>
                </div>




                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                    You’ve spent lockdown recharging your phone, laptop, controllers, tablets, toothbrushes, clippers…
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.4em', fontWeight: 700}}>
                                    What about recharging<br/> YOU?
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em',fontWeight:700}}>
                                    Self-care<br/>
                                    is not<br/>
                                    selfish
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h3" style={{lineHeight: '1.2em'}}>
                                    <span style={{color:"red"}}>Lead</span> yourself<br/> before you<br/> can <span style={{color:"red"}}>lead</span><br/> others.
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>


              <div>
                <Card isWithinCardStack={true} className={classes.fullXCCLogoBackdrop}/>
              </div>

                <div>
                    <Card isWithinCardStack={true}>
                        <Box className={classes.verticalCenteredBox} style={{alignItems: 'center'}}>
                            <img src={Img2} className={classes.responsiveImage}
                                 alt="XCC"/>
                        </Box>
                    </Card>
                </div>


            </React.Fragment>
        )
    }
    return {
        render
    }
}
