import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'

export default function Day78 () {
  const render = (classes) => {
    return (
      <React.Fragment>
        <div>
          <Card isWithinCardStack={true}>
            <Box style={{ width: '90%' }}>
              <Box mt={8} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  What are your<br/>
                  plans for the<br/>
                  day?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true} className={classes.redCard}>
            <Box style={{ width: '90%' }}>
              <Box mt={8} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Who are they<br/>
                  serving?
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  How are they<br/>
                  different from<br/>
                  yesterdays?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox} style={{ width: '90%' }}>
              <Box mt={8} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  Commit to the<br/>
                  LORD whatever<br/>
                  you do, and he<br/>
                  will establish your<br/>
                  plans.
                </WhiteText>
              </Box>
              <Box mt={4} style={{ width: '100%' }}>
                <WhiteText align="left" variant="h4" style={{ lineHeight: '1.2em' }}>
                  - Proverbs 16:3
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Choose to <b>DO</b> something different today.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="center" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Choose to <b>LOVE</b> someone lavishly.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Let us not wait till a special occasion.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  What's special about that?
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Do it today.
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
        <div>
          <Card isWithinCardStack={true}>
            <Box className={classes.verticalCenteredBox}>
              <Box>
                <WhiteText align="left" variant="h5">
                  <b>TODAY'S CHALLENGE:</b>
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Scrap your plans, they weren't THAT important anyway.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  Make plans to show your appreciation for someone, unexpectedly.
                </WhiteText>
              </Box>
              <Box mt={4}>
                <WhiteText align="left" variant="h5" style={{ lineHeight: '1.2em' }}>
                  What would <b>THEY</b> appreciate?
                </WhiteText>
              </Box>
            </Box>
          </Card>
        </div>
      </React.Fragment>
    )
  }
  return {
    render
  }
}
