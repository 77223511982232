import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '../components/Card'
import WhiteText from '../components/Typography/WhiteText'
import Img3 from "../assets/cross.jpg"

export default function Day93() {
    const render = (classes) => {
        return (
            <React.Fragment>


                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h4" style={{lineHeight: '1.2em'}}>
                                   <b> What are you striving for today?   <br/><br/>
                                    What are you wanting to achieve in your life? </b>                          
                                </WhiteText>
                          
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="center" variant="h5" style={{lineHeight: '1.2em'}}>
                                     If you don’t achieve your goals, you’ll be pretty frustrated right? <br/> <br/>
                                     How frustrated will you be? <br/><br/>
                                     <u> <b>Are you too harsh on yourself?</b></u>
                                </WhiteText>
                                
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true}  className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.6em'}}>
                                “Perfectionism is religion (in its worst sense) by another name,
                                and it crushes our ability to receive or even understand God’s grace" <br/><br/> 
                                 - Will van der Hart
                                </WhiteText>
                            </Box>
                        </Box>
                    </Card>
                </div>

                <div>
                    <Card isWithinCardStack={true} >
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                <b><u>Thought:</u></b> <br/> <br/>
                                We want to press on,<br/> 
                                strive to improve,<br/>
                                lead, drive change...
                                <br/>yes. But...<br/> <br/>

                                We are not defined by our achievements...<br/> <br/>
                               <b> We are defined by what He has achieved for us </b>
                                
                                </WhiteText>
                                
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h5" style={{lineHeight: '1.2em'}}>
                                <strong><u>Today’s Challenge:</u></strong><br/><br/>
                                Take 60secs out to get perspective.<br/> <br/>                       
                                Remind yourself of His grace.<br/> <br/>
                                Picture yourself at the foot of the cross... 
                                
                                </WhiteText>
                                
                            </Box>
                        </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true}>
                    <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>
                        <Box>
                        <WhiteText align="center" variant="h3" style={{lineHeight: '1.2em'}}>
                            <b>IT IS <br/> FINISHED</b>
                        </WhiteText>
                        </Box>
                    
                    </Box>
                    </Card>
                </div>
                <div>
                    <Card isWithinCardStack={true} className={classes.redCard}>
                        <Box className={classes.verticalCenteredBox} style={{width: '90%'}}>
                            <Box style={{width: '100%'}}>
                                <WhiteText align="left" variant="h4" style={{lineHeight: '1.2em'}}>
                                Now go and serve <br/> 
                                freely, like <br/>
                                someone who <br/>
                                knows that the <br/> 
                                end result does <br/>
                                not define them 
                                </WhiteText>
                          
                            </Box>
                        </Box>
                    </Card>
                </div>



              <div>
                <Card isWithinCardStack={true}>
                  <Box className={classes.verticalCenteredBox} style={{ alignItems: 'center' }}>                     
                    <Box mt={2}>
                      <img src={Img3} className={classes.responsiveImage}
                           alt=" the cross"/>
                    </Box>
                  </Box>
                </Card>
              </div>



              

            </React.Fragment>
        )
    }
    return {
        render
    }
}
